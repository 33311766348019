import { AlphabetOnlyRegex, EmailRegex, PhoneRegex, ZipCodeRegex } from "utils/constants/constant";
import * as Yup from "yup";

export const CreateEmployeeSchema = Yup.object().shape({
  // title: Yup.string().required("Title is required").matches(
  //   AlphabetOnlyRegex,
  //   "Please enter only alphabets"
  // ),
  first_name: Yup.string().required("First name is required").matches(
    AlphabetOnlyRegex,
    "Please enter only alphabets"
  ),
  last_name: Yup.string().required("Last name is required").matches(
    AlphabetOnlyRegex,
    "Please enter only alphabets"
  ),
  phone: Yup.string()
    .required("Mobile number is required")
    .matches(PhoneRegex, "A valid Mobile number is required"),
  email: Yup.string()
    .required("Email address is required")
    .matches(
      EmailRegex,
      "A valid email address is required"
    )
    .email("A valid email address is required")
    .min(5, "A minimum of 2 characters is required")
    .max(100, "A maximum of 100 characters is allowed"),
  // regional_manager_id: Yup.object().required("Regional Manager must be selected"),
  region: Yup.object().required("Region must be selected"),
  jobsite: Yup.array()
      .required("Jobsite must be selected")
      .typeError("Jobsite must be selected")
      .min(1, "Select at least one jobsite"),
  // area: Yup.array()
  //   .required("Area must be selected")
  //   .typeError("Area must be selected")
  //   .min(1, "Select at least one Area"),
  city: Yup.object().required("City must be selected"),
  state: Yup.object().required("State must be selected"),
  zipcode: Yup.string().required("Zipcode is required").matches(ZipCodeRegex, "A valid Zipcode is required"),
  address: Yup.string().trim().required("Address is required"),
  birthdate: Yup.date()
    .required("Birthdate is required")
    .typeError("Birthdate is required"),
  uniform_size: Yup.object().required("Uniform size must be selected"),
  // department_name: Yup.string().required("Department name is required"),
  time_zone: Yup.object().required("Timezone must be selected"),
  pay: Yup.number()
    .required("Payment amount is required")
    .typeError("Payment amount is required")
    .positive("Amount must be a positive number"),
  pay_type: Yup.object().required("Payment type must be selected"),
  pay_cycle: Yup.object().required("Payment cycle must be selected"),
  start_date: Yup.date()
    .required("Start date is required")
    .typeError("Start date is required"),
  position: Yup.object().required("Position must be selected"),
});
