import React from "react";
import { Modal } from "react-bootstrap";
import { SuccessModalProps } from "types/modal";
import appImages from "utils/appImages";
import "assets/scss/modal.scss";

const SuccessModal: React.FC<SuccessModalProps> = ({
  title,
  description,
  show,
  handleClose,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-purple border-0 d-flex align-items-center justify-content-between" >
        <h2 className="mb-0 text-white fw-600"> {title}</h2>       
        <button
          type="button"
          className="btn-close p-0 m-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
        </button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4 className="fw-600">Congratulations</h4>
        <div className="related-image">
          <img src={appImages.SuccessIcon} alt="success" />
        </div>
        <p>{description}</p>        
        <button
          type="button"
          className="btn btn-primary"
          data-bs-dismiss="modal"
          aria-label="ok"
          onClick={handleClose}
        >
          Ok
        </button>
      </Modal.Body>
    </Modal>
  );
};
export default SuccessModal;
