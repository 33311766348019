import { request } from "services/index";
import { AxiosResponse } from "axios";

export const CreateDocumentAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-document",
    method: "POST",
    body,
  });
  return response;
};

export const EditDocumentAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-document",
    method: "POST",
    body,
  });
  return response;
};

export const DeleteDocumentAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-document",
    method: "POST",
    body,
  });
  return response;
};

export const GetDocumentListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/document-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetDocumentDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/document-detail/${id}`,
    method: "GET"
  });
  return response;
};

export const SentDocumentAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/send-document`,
    method: "POST",
    body
  });
  return response;
};

export const SentDocumentListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/shared-document-list`,
    method: "POST",
    body
  });
  return response;
};