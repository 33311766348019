import React, { ChangeEvent, RefObject } from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { TextBoxProps } from "types/form";


const FileInput: React.FC<TextBoxProps> = ({
  type = "text",
  control,
  name = "",
  id = "",
  className = "",
  placeholder = "",
  disabled = false,
  readonly = false,
  required = false,
  onChange,
  label = "",
  errorMessage = "",
  isRequired = false,
  accept = "image/*",
  fileInputRef,
}) => {
  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field }) => (
          <Form.Group className={`form-group`}>
            {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
            {isRequired && <span className="required-text fw-bold"></span>}
            <Form.Control
              type="file"
              name={name}
              id={id}
              className={`${className} ${errorMessage ? "" : ""}`}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              required={required}
              form="novalidatedform"
              accept={accept}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              ref={fileInputRef as RefObject<HTMLInputElement>}
            />
            {errorMessage && (
              <div className="invalid-feedback d-block">{errorMessage}</div>
            )}
          </Form.Group>
        )}
      />
    </>
  );
};

export default FileInput;

