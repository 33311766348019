import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Form, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import { NavLink, useNavigate } from "react-router-dom";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GlobalState } from "context/globalContext";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import { GetJobsiteAPI, JobsiteArchiveAPI } from "services/jobsite";
import ConfirmModal from "common/confirmModal/confirmModal";

const JobsiteList = () => {
  const { setBreadCrumb } = GlobalState();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [jobsiteData, setJobsiteData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<any>({});
  const [archiveStatus, setArchiveStatus] = useState<string>();

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "Manage Jobsite",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "create":
        data = {
          title: "Jobsite Create successfully",
          description: "Jobsite has been successfully created",
        };
        return data;
      case "edit":
        data = {
          title: "Jobsite Update successfully",
          description: "Jobsite has been successfully updated",
        };
        return data;
      case "archive":
        data = {
          title: "Jobsite Archived successfully",
          description: "Jobsite has been successfully archived",
        };
        return data;
      case "unArchive":
        data = {
          title: "Jobsite unArchived successfully",
          description: "Jobsite has been successfully unArchived",
        };
        return data;
      default:
        return null;
    }
  };

  const getJobsiteListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetJobsiteMutate(data);
  };

  useEffect(() => {
    getJobsiteListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const { isPending, mutate: GetJobsiteMutate } = useMutation({
    mutationFn: GetJobsiteAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setJobsiteData(data.data.result);
      return data;
    },
  });

  const { isPending: JobsiteArchivePending, mutate: JobsiteArchiveMutate } =
    useMutation({
      mutationFn: JobsiteArchiveAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        getJobsiteListData();
        isShowConfirmModal("");
        setIsShowSuccessModal(true);
      },
    });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const handleArchive = (archive?: string) => {
    setArchiveStatus(archive);
    setCurConfirmModal(archive==='1' ? "unArchive" : "archive");
    isShowConfirmModal(archive==='1' ? "unArchive" : "archive");
  };

  const handleActionClick = (
    action: string,
    status?: any,
    id?: number,
    archive?: string
  ) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "archive":
        return handleArchive(archive);
      case "edit":
        return navigate(`/jobsite-edit/${id}`);
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Building Owner",
      selector: (row: any) =>
        row?.building_owner ? row?.building_owner?.length > 25
          ? row?.building_owner.slice(0, 25) + "..."
          : row?.building_owner : "-",
      sortField: "building_owner",
      sortable: true,
    },
    {
      id: 3,
      name: "Jobsite",
      selector: (row: any) =>
        row?.jobsite_title ? row?.jobsite_title?.length > 25
          ? row?.jobsite_title.slice(0, 25) + "..."
          : row?.jobsite_title : "-",
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Location",
      selector: (row: any) =>
        row?.address ? row?.address?.length > 25
          ? row?.address.slice(0, 25) + "..."
          : row?.address : "-",
    },
    {
      id: 6,
      name: "Total Units",
      selector: (row: any) => row?.total_unit ?? "-",
      sortField: "total_unit",
      sortable: true,
      width: "10%",
    },
    {
      id: 9,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            status={row?.is_active === "1" ? true : false}
            archiveText={row?.is_archive === '1' ? 'UnArchive' : 'Archive'}
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id, row.is_archive)
            }
            isVisibles={["edit", "archive"]}
            onToggleChange={(e: boolean) => {}
              // isDropdownOpen(e)
            }
          />
        </>
      ),
      width: "7%",
    },
    {
      id: 11,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link as={NavLink} to={`/jobsite-detail/${row?.id}`}>
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Jobsite List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="text"
              name="sarch"
              id="search"
              value={search}
              placeholder="Search"
              // maxLength={20}
              autoComplete="off"
              onChange={(e: any) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
          <Button variant="primary" onClick={() => navigate('/create-jobsite')}>
            Add Jobsite
          </Button>
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={jobsiteData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={jobsiteData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getJobsiteListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {['unArchive', 'archive'].includes(showConfirmModal) && (
        <ConfirmModal
          show={['unArchive', 'archive'].includes(showConfirmModal)}
          onConfirm={() =>
            JobsiteArchiveMutate({
              id: currentRow,
              is_archive: archiveStatus == "1" ? "0" : "1",
            })
          }
          isLoading={JobsiteArchivePending}
          handleClose={handleCloseConfirmModal}
          message={`Do you really want to ${showConfirmModal ?? 'archive'} this Jobsite?`}
        />
      )}
    </>
  );
};

export default JobsiteList;
