import * as Yup from "yup";

export const CreateDocumentSchema = Yup.object().shape({
  document_title: Yup.string()
  .required("Document Title is required"),
  jobsite: Yup.object()
  .required("Jobsite is required")
  .typeError("Jobsite is required"),
  description: Yup.string()
  .required("Description is required"),
  date: Yup.date().required("Date is required").typeError("Date is required"),
  time: Yup.date().required("Time is required").typeError("Time is required"),
})