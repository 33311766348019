import ReactPaginate from "react-paginate";
import "assets/scss/pagination.scss";

export function ReactPagination({
  pageCount,
  currentPage,
  onPageChange,
}: {
  pageCount: number;
  currentPage?: number;
  onPageChange: (page: number) => void;
}) {
  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      breakLabel={"..."}
      onPageChange={(selectedItem: any) => {
        onPageChange(selectedItem.selected);
      }}
      forcePage={currentPage}
      containerClassName={"pagination justify-content-center"}
      activeClassName="active"
      previousLabel="<"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      nextLabel=">"
      breakClassName="page-item"
      breakLinkClassName="page-link"
    />
  );
}
