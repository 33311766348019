import { Navigate, useLocation, useRoutes } from "react-router-dom";
import PublicrouteList from "routes/publicRoutelist";
import PrivateRoutes from "routes/privateRoute";
import AuthLayout from "layout/authLayout";

export default function AppRoutes() {
  const location = useLocation();
  const redirectPath = `/?redirectTo=${location.pathname}`;
  return useRoutes([
    PublicrouteList,

    // {
    //     path: '/',
    //     element: localStorage.getItem("token") != null ? <Navigate to='/dashboard' /> : <AuthLayout />,
    //     children: authRouteList
    // },
    {
      path: "/",
      element:
        localStorage.getItem("token") == null ? (
          <AuthLayout />
        ) : (
          <Navigate to={`/signin`} />
        ),
      children: PrivateRoutes,
    },
  ]);
}
