import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetHolidayListAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/holiday-list",
      method: "POST",
      body,
    });
    return response;
  };
  export const DeleteHolidayAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/delete-holiday",
      method: "POST",
      body,
    });
    return response;
  };
  export const CreateHolidayAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/add-holiday",
      method: "POST",
      body,
    });
    return response;
  };
  export const EditHolidayAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/update-holiday",
      method: "POST",
      body,
    });
    return response;
  };