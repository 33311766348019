import React, { useEffect, useState } from "react";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import Textareabox from "common/form/textAreaBox";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import DragAndDropFileUpload from "common/dragFileUpload/dragFileUpload";
import { FileError } from "react-dropzone";
import { CreateTaskAPI } from "services/task";
import { JobsiteDropdownListAPI } from "services/common";
import appImages from "utils/appImages";
import RadioButton from "common/form/radioButton";
import { AddAnnouncementProps } from "types/announcement";
import { AddAnnouncementSchema } from "utils/schema/announcement";
import Textbox from "common/form/textBox";
import {
  AnnouncementCreateAPI,
  GetAnnounceGroupListAPI,
  GetJobsiteEmpClientListAPI,
} from "services/announcement";
import { GetRegionTitleAPI } from "services/title";

const AddAnnouncement: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [imgUploadErr, setImgUploadErr] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
  } = useForm<AddAnnouncementProps>({
    resolver: yupResolver(AddAnnouncementSchema),
  });

  const { isPending: isGrouptListPending, mutate: GetGroupList } = useMutation({
    mutationFn: GetAnnounceGroupListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, announceList: data.data.result });
    },
  });

  const { isPending: isRegionPending, mutate: GetRegionList } = useMutation({
    mutationFn: GetRegionTitleAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, regionList: data.data.result });
    },
  });

  const { isPending: isPendingJobsiteList, mutate: GetJobsiteList } =
    useMutation({
      mutationFn: JobsiteDropdownListAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setFormData({ ...formData, jobsiteList: data.data.result });
      },
    });

  const { isPending: isEmpClientListPending, mutate: GetEmpClient } =
    useMutation({
      mutationFn: GetJobsiteEmpClientListAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setFormData({ ...formData, jobsiteEmpClientList: data.data.result });
      },
    });

  useEffect(() => {
    let body = {
      pagination: 0,
    };
    GetRegionList(body);
    GetGroupList();
    GetJobsiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isPending, mutate: CreateAnnounceMutate } = useMutation({
    mutationFn: AnnouncementCreateAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "createAnnounce");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("announcement_title", submitFormData?.announce_title);
    formData.append(
      "announcement_description",
      submitFormData?.announce_detail
    );
    if (submitFormData?.group_wide === "1") {
      formData.append("jobsite_ids[]", submitFormData?.jobsite?.map((el:any) => el?.id));
      submitFormData?.employee && formData.append("employee_ids[]", submitFormData?.employee?.map((el:any) => el?.employee_info?.id));
      submitFormData?.client && formData.append("client_ids[]", submitFormData?.client?.map((el:any) => el?.client_info?.id));
    }
    if (submitFormData?.group_wide === "2") {
      formData.append("group_ids[]", submitFormData?.group?.map((el:any) => el?.id));
    }
    if (submitFormData?.group_wide === "3") {
      formData.append("is_company", "1");
    }
    if (submitFormData?.group_wide === "4") {
      formData.append("region_ids[]", submitFormData?.region?.map((el:any) => el?.id));
    }
    if (selectedFiles?.length > 0) {
      formData.append("image", selectedFiles[0]);
    }
    CreateAnnounceMutate(formData);
  };

  const handleUploadFiles = (files: any) => {
    setSelectedFiles(files);
  };

  const handleUploadValidate = (file: File): FileError | FileError[] | null => {
    if (!file.type.startsWith("image/")) {
      setImgUploadErr("Only Support image Files only");
      return null;
    }
    setImgUploadErr("");
    return null;
  };

  const removeSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row></Row>
          <Row>
            <Col md={12}>
              <Textbox
                control={control}
                type="text"
                id="announce_title"
                name="announce_title"
                value=""
                placeholder={"Announcement Title"}
                autoComplete="off"
                max={50}
                errorMessage={
                  errors.announce_title && errors.announce_title?.message
                }
              />
            </Col>
            <Col md={12}>
              <Textareabox
                control={control}
                type="textarea"
                id="announce_detail"
                name="announce_detail"
                value=""
                placeholder={"Announcement Detail"}
                errorMessage={
                  errors.announce_detail && errors.announce_detail?.message
                }
              />
            </Col>
            <Col md={12}>
              <RadioButton
                control={control}
                type="radio"
                id="group_wide"
                name="group_wide"
                className="form-checkbox-radio"
                options={[
                  { label: "Jobsite", value: "1" },
                  { label: "Group Wide", value: "2" },
                  { label: "Company Wide", value: "3" },
                  { label: "Region Wide", value: "4" },
                ]}
                onRadioChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("group_wide", event.target.value);
                }}
                errorMessage={errors.group_wide && errors.group_wide?.message}
              />
            </Col>
            {watch("group_wide") === "1" && (
              <>
                <Col md={12}>
                  <Controller
                    name="jobsite"
                    control={control}
                    render={({ field }) => (
                      <div className="form-group">
                        <Select
                          isLoading={isPendingJobsiteList}
                          {...field}
                          getOptionLabel={(item: any) => item.jobsite_title}
                          getOptionValue={(item: any) => item.id}
                          options={formData?.jobsiteList ?? []}
                          isSearchable
                          isMulti
                          onChange={(selectedOption: any) => {
                            field.onChange(selectedOption);
                            if (selectedOption?.length) {
                              GetEmpClient({
                                jobsite_ids: selectedOption?.map(
                                  (item: any) => item.id
                                ),
                              });
                            }
                            setValue("employee", []);
                            setValue("client", []);
                          }}
                          placeholder={"Jobsite"}
                          classNamePrefix="react-select"
                          className="react-select-container"
                          menuPlacement="bottom"
                        />
                        {errors.jobsite && (
                          <div className="invalid-feedback d-block">
                            {errors.jobsite.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Col>
                <Col md={12}>
                  <Controller
                    name="employee"
                    control={control}
                    render={({ field }) => (
                      <div className="form-group">
                        <Select
                          isLoading={isEmpClientListPending}
                          {...field}
                          getOptionLabel={(item: any) =>
                            item.employee_info.full_name
                          }
                          getOptionValue={(item: any) => item.employee_info.id}
                          options={
                            formData?.jobsiteEmpClientList?.jobsite_employee ??
                            []
                          }
                          isSearchable
                          isMulti
                          isClearable
                          onChange={(selectedOption) => {
                            console.log('selectedOption---', selectedOption)
                            field.onChange(selectedOption);
                          }}
                          placeholder={"Employee"}
                          classNamePrefix="react-select"
                          className="react-select-container"
                          menuPlacement="bottom"
                        />
                        {errors.employee && (
                          <div className="invalid-feedback d-block">
                            {errors.employee.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Col>
                <Col md={12}>
                  <Controller
                    name="client"
                    control={control}
                    render={({ field }) => (
                      <div className="form-group">
                        <Select
                          isLoading={isEmpClientListPending}
                          {...field}
                          getOptionLabel={(item: any) =>
                            item.client_info.full_name
                          }
                          getOptionValue={(item: any) => item.client_info.id}
                          options={
                            formData?.jobsiteEmpClientList?.jobsite_client ?? []
                          }
                          isSearchable
                          isMulti
                          isClearable
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                          }}
                          placeholder={"Client"}
                          classNamePrefix="react-select"
                          className="react-select-container"
                          menuPlacement="bottom"
                        />
                        {errors.client && (
                          <div className="invalid-feedback d-block">
                            {errors.client.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Col>
              </>
            )}
            {watch("group_wide") === "2" && (
              <Col md={12}>
                <Controller
                  name="group"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isGrouptListPending}
                        {...field}
                        getOptionLabel={(item: any) => item.group_name}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.announceList ?? []}
                        isSearchable
                        isMulti
                        isClearable
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Select Group"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.group && (
                        <div className="invalid-feedback d-block">
                          {errors.group.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
            )}
            {watch("group_wide") === "4" && (
              <Col md={12}>
                <Controller
                  name="region"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isRegionPending}
                        {...field}
                        getOptionLabel={(item: any) => item.region_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.regionList ?? []}
                        isSearchable
                        isMulti
                        isClearable
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Select Region"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.region && (
                        <div className="invalid-feedback d-block">
                          {errors.region.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
            )}
            <Col md={12}>
              <div className="d-flex flex-column custom-select-wrapper mb-5">
              <div className="d-block">
                        {`* You can upload 1 image only *`}
                      </div>
                <div className="d-flex selected-image-wrapper horizontal-scrollbar gap-2">
                  {selectedFiles?.map((file: any, index: number) => {
                    let imageURL = URL.createObjectURL(file);
                    return (
                      <div key={index} className="d-flex flex-column">
                        <div
                          className="selected-image d-flex align-items-center justify-content-center"
                          style={{ backgroundImage: `url(${imageURL})` }}
                        >
                          <div
                            className="remove-image d-flex align-items-center justify-content-center cursor-pointer"
                            onClick={() => removeSelectedImage(index)}
                          >
                            <Image
                              src={appImages.CloseImage}
                              alt="More"
                              width={10}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <DragAndDropFileUpload
                  handleValidate={handleUploadValidate}
                  uploadFiles={handleUploadFiles}
                  maxAllows={1}
                />
                <div className="invalid-feedback d-block">{imgUploadErr}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending}>
                {isPending && <SubmitLoader />}
                Create
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default AddAnnouncement;
