import { ClipLoader, ScaleLoader } from 'react-spinners'

const SpinerLoader = () => {
  return (
  <><ClipLoader color="#8C73C8" /></>
  )
}

const ScalingLoader = () => {
  return (
  <><ScaleLoader color="#8C73C8" speedMultiplier={1.5} /></>
  )
}

const SubmitLoader = () => {
  return (
    <span
      className="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
  );
};

export { SpinerLoader, ScalingLoader, SubmitLoader}