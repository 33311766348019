import { useMutation, useQuery } from "@tanstack/react-query";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import React, { useState } from "react";
import {
  Button,
  Form,
  Image,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { AddGroupMemberAPI, GetEmpClientListAPI } from "services/announcement";
import { MultiSelectDocumentModalProps } from "types/modal";

const AnnounceGroupValetListModal: React.FC<MultiSelectDocumentModalProps> = ({
  title,
  show,
  handleClose,
  callback,
  setShowSuccessModal,
  data
}) => {
  const [listingData, setLisingData] = useState<any>({});
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const { fetchStatus } = useQuery({
    queryKey: ["clientAndEmpData"],
    queryFn: async () => {
      try {
        const data = await GetEmpClientListAPI();
        setLisingData(data?.data?.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: addMemberPending, mutate: AddMemberMutate } =
    useMutation({
      mutationFn: AddGroupMemberAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setShowSuccessModal && setShowSuccessModal(true, "createAnnounceGroup");
        handleClose();
        callback && callback();
      },
    });

  const handleSubmit = () => {
    const body = {
      group_id: data && data?.id,
      user_id: selectedIds,
    };
    AddMemberMutate(body);
  };

  const toggleUserSelection = (userId: any) => {
    setSelectedIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  return (
    <CommonModal
      title={title}
      show={show}
      handleClose={handleClose}
      dynamicCLass={"multi-select-modal"}
      modalSize={"modal-default"}
    >
      {fetchStatus === "fetching" ? (
        <div className="d-flex align-items-center justify-content-center p-3">
          <SpinerLoader />
        </div>
      ) : (
        <div className="multi-select-modal-body">
          <Form.Group controlId="formMultiSelect">
            <Tabs
              defaultActiveKey="employee"
              id="uncontrolled-tab-example"
              className="horizontal-scrollbar"
            >
              <Tab eventKey="employee" title={"Employee"}>
                {listingData?.employee_info?.map((user: any) => {
                  const isChecked = selectedIds.includes(user.id);
                  return (
                  <Form.Check
                    key={user.id}
                    type="checkbox"
                    className="d-flex align-items-center"
                    id={`user-checkbox-${user.id}`}
                    checked={isChecked}
                    label={
                      <>
                        <Image
                          loading="lazy"
                          className="profile-img rounded-circle me-2"
                          src={user?.profile_image_url}
                          alt="profile"
                        />
                        {user?.full_name && user?.full_name?.length > 25
                          ? user?.full_name?.slice(0, 25) + "..."
                          : user?.full_name}
                      </>
                    }
                    onChange={() => toggleUserSelection(user.id)}
                  ></Form.Check>
                )})}
              </Tab>
              <Tab eventKey="client" title={"Client"}>
                {listingData?.client_info?.map((user: any) => {
                  const isChecked = selectedIds.includes(user.id);
                  return (
                  <Form.Check
                    key={user.id}
                    type="checkbox"
                    className="d-flex align-items-center"
                    id={`user-checkbox-${user.id}`}
                    checked={isChecked}
                    label={
                      <>
                        <Image
                          loading="lazy"
                          className="profile-img rounded-circle me-2"
                          src={user?.profile_image_url}
                          alt="profile"
                        />
                        {user?.full_name && user?.full_name?.length > 25
                          ? user?.full_name?.slice(0, 25) + "..."
                          : user?.full_name}
                      </>
                    }
                    onChange={() => toggleUserSelection(user.id)}
                  ></Form.Check>
                );
                })}
              </Tab>
            </Tabs>
          </Form.Group>
          <Modal.Footer>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={addMemberPending || selectedIds?.length < 1}
                onClick={handleSubmit}
              >
                {addMemberPending && <SubmitLoader />}
                Create
              </Button>
            </div>
          </Modal.Footer>
        </div>
      )}
    </CommonModal>
  );
};

export default AnnounceGroupValetListModal;
