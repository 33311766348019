import { Image, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import appImages from "utils/appImages";
import "components/signIn/signIn.scss";
import { useState } from "react";
import Password from "common/form/password";
import { useMutation } from "@tanstack/react-query";
import { ResetPassAPI } from "services/auth";
import { ResetPassSchema } from "utils/schema/auth";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitLoader } from "common/loader/loader";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { email, token } = useParams();
  const [newPassType, setNewPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResetPassSchema),
  });

  const { isPending, mutate: ResetPassMutate } = useMutation({
    mutationFn: ResetPassAPI,
    onError: (error: any) => {
      toast.error(error.data.message)
    },
    onSuccess: (data) => {
    toast.success(data.data.message)
      navigate("/signin")
    },
    // onSettled: (data, error, variables, context) => {},
  })


  const handleToggleNewPassword = () => {
    setNewPassType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };
  const handleToggleConfirmPassword = () => {
    setConfirmPassType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const onSubmit = async (data: any) => {
    const body = {
        new_password: data?.new_password,
        confirm_password: data?.confirm_password,
        token: token,
        email: email
    }
    await ResetPassMutate(body);
  };

  return (
    <>
      <div className="signin-page w-100 d-flex align-items-center justify-content-center">
        <div className="signin-logo">
          <Image src={appImages.LoginLogo} />
        </div>
        <div className="signin-form bg-white">
          <h1 className="fw-600">Reset Password</h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Password
              control={control}
              type="password"
              id="new_password"
              name="new_password"
              value=""
              autoComplete="off"
              className="form control"
              placeholder="Enter New Password"
              label="New Password"
              errorMessage={errors.new_password && errors.new_password?.message}
              enableShowPassword={true}
              isRequired={true}
              passwordType={newPassType}
              max={64}
              handleTogglePassword={handleToggleNewPassword}
            />
            <Password
              control={control}
              type="password"
              id="confirm_password"
              name="confirm_password"
              value=""
              autoComplete="off"
              className="form control"
              placeholder="Enter Confirm Password"
              label="Confirm Password"
              errorMessage={errors.confirm_password && errors.confirm_password?.message}
              enableShowPassword={true}
              isRequired={true}
              passwordType={confirmPassType}
              max={64}
              handleTogglePassword={handleToggleConfirmPassword}
            />
            <Button
              variant="primary"
              type="submit"
              className="text-center d-block"
              disabled={isPending}
            >
              {isPending && <SubmitLoader />}Submit
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
