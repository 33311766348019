import { useMutation, useQuery } from "@tanstack/react-query";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import ConfirmModal from "common/confirmModal/confirmModal";
import Textbox from "common/form/textBox";
import ConstantGoogleMap from "common/googleMap/constantMap";
import { ScalingLoader } from "common/loader/loader";
import SuccessModal from "common/modal/successModal/successModal";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SortOrder, TableColumn } from "react-data-table-component";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    AreaArchiveAPI,
    AreaDetailAPI,
  GetJobsiteAreaListAPI,
  GetJobsiteJobsiteDetailAPI,
  JobsiteArchiveAPI,
} from "services/jobsite";
import appImages from "utils/appImages";

const AreaDetail = () => {
  const navigate = useNavigate();
  const { id: areaId } = useParams();
  const { state } = useLocation();
  const [copied, setCopied] = useState(false);
  const [polygonData, setPolygonData] = useState<any[]>([]);
  const [jobsiteData, setJobsiteData] = useState<any[]>([]);
  const [markerData, setMarkerData] = useState<any>();
  const [archiveStatus, setArchiveStatus] = useState<string>();
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);

  const { setBreadCrumb } = GlobalState();

  const breadcrumbList = [
    {
      title: "Jobsite Detail",
      slug: `/jobsite-detail/${state.jobsite_id}`,
    },
    {
      title: "Area Detail",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "archive":
        data = {
          title: "Area Archived successfully",
          description: "Area has been successfully archived",
        };
        return data;
      case "unArchive":
        data = {
          title: "Area unArchived successfully",
          description: "Area has been successfully unArchived",
        };
        return data;
      default:
        return null;
    }
  };

  const { control, setValue, watch } = useForm({});

  const { fetchStatus, refetch: refetchAreaDetail } = useQuery({
    queryKey: ["areaDetailData"],
    queryFn: async () => {
      try {
        const data = areaId && (await AreaDetailAPI(areaId));
        const result = data && data?.data?.result;
        if (result) {
          const areaPolygonData = result?.area_polygon_info?.map((item: any) => {
            return { lat: +item?.latitude, lng: +item?.longitude };
          });
          const polygonData = result?.jobsite_info?.jobsite_polygon_info?.map((item: any) => {
            return { lat: +item?.latitude, lng: +item?.longitude };
          });

          setValue("id", result?.id);
          setValue("area_name", result?.area_name);
          setValue("jobsite", result?.jobsite_info?.jobsite_title);
          setValue("jobsite_id", result?.jobsite_info?.id);
          setValue("archiveStatus", result?.is_archive);
          setValue("location", result?.jobsite_info?.address);
          setValue("total_unit", result?.total_unit);
          setMarkerData({ lat: +result?.latitude, lng: +result?.longitude });
          setPolygonData([polygonData, areaPolygonData]);
          setJobsiteData(polygonData);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: AreaArchivePending, mutate: AreaArchiveMutate } =
    useMutation({
      mutationFn: AreaArchiveAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: () => {
        // navigate(`/jobsite-detail/${state.jobsite_id}`);
        refetchAreaDetail();
        isShowConfirmModal("");
        setIsShowSuccessModal(true);
      },
    });

  const handleArchive = (archive?: string) => {
    setArchiveStatus(archive);
    setCurConfirmModal(archive==='1' ? "unArchive" : "archive");
    isShowConfirmModal(archive==='1' ? "unArchive" : "archive");
  };

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  return (
    <>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          Area details
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="detail-form d-sm-flex d-block">
              <Form>
                <div className="position-relative form-group-icon">
                  <Textbox
                    control={control}
                    type="text"
                    id="id"
                    name="id"
                    value=""
                    label={"ID"}
                    placeholder={"ID"}
                    autoComplete="off"
                    disabled
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                  >
                    <Image
                      src={appImages.CopyIcon}
                      alt="copy"
                      className="cursor-pointer"
                      onClick={() => {
                        copy(watch("id"));
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    />
                  </OverlayTrigger>
                </div>
                <Textbox
                  control={control}
                  type="text"
                  id="area_name"
                  name="area_name"
                  value=""
                  label={"Area name"}
                  placeholder={"Area name"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="jobsite"
                  name="jobsite"
                  value=""
                  label={"Jobsite"}
                  placeholder={"Jobsite"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="location"
                  name="location"
                  value=""
                  label={"Location"}
                  placeholder={"Location"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="total_unit"
                  name="total_unit"
                  value=""
                  label={"Total Unit"}
                  placeholder="Total Unit"
                  autoComplete="off"
                  disabled
                />
              </Form>
            </div>
          )}
        </Card.Body>
      </Card>
      {(fetchStatus !== "fetching" && polygonData?.length) && (
        <ConstantGoogleMap polygonData={polygonData} markerData={markerData} />
      )}
          {fetchStatus !== "fetching" &&
      <div className="area-btn my-3 gap-3 d-flex justify-content-center">
        <Button
          onClick={() => handleArchive(watch('archiveStatus'))}
          variant="danger"
        >
          {watch('archiveStatus')==='1'? 'UnArchive' : "Archive"} Area
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            navigate(`/edit-jobsite-area/${areaId}`, {state: { id: watch('jobsite_id'), jobsiteData: jobsiteData }})}
        >
          Edit Area
        </Button>
      </div>
      }

      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {['unArchive', 'archive'].includes(showConfirmModal) && (
        <ConfirmModal
          show={['unArchive', 'archive'].includes(showConfirmModal)}
          onConfirm={() =>
            AreaArchiveMutate({
              id: areaId,
              is_archive: watch('archiveStatus') == "1" ? "0" : "1",
            })
          }
          isLoading={AreaArchivePending}
          handleClose={handleCloseConfirmModal}
          message={`Do you really want to ${showConfirmModal ?? 'archive'} this Area?`}
        />
      )}
    </>
  );
};

export default AreaDetail;
