import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetTaskAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/task-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetTaskDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/task-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const CreateInstantTaskAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/add-instant-task`,
    method: "POST",
    body,
  });
  return response;
};

export const GetInstantTaskListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/instant-task-title-list`,
    method: "POST",
    body
  });
  return response;
};

export const CreateTaskAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/add-task`,
    method: "POST",
    body,
  });
  return response;
};

export const GetTaskListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/task-title-list`,
    method: "POST",
    body
  });
  return response;
};

export const UpdateTaskAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/update-task-status`,
    method: "POST",
    body
  });
  return response;
};

export const GetLiveLocationAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/get-valet-location-task`,
    method: "POST",
    body
  });
  return response;
};