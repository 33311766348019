import axios, { AxiosResponse, ResponseType } from "axios";
import { getAccesToken } from "utils/helpers/localStorage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  timeout: 0,
});

axiosInstance.interceptors.request.use(
  function (config) {
    let authToken = getAccesToken() ?? "";

    if (authToken) {
      config.headers["Authorization"] = "Bearer " + authToken;
    }

    // Other headers
    config.headers["accept-language"] = "en";
    config.headers["Accept"] = "application/json";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {    
    // if (error.response.status === 401) {
    //   window.location.href = "/";
    // }
    // if(error.response.status === 403){
    //   window.location.href = "/access-denied";
    // }
    // if (error.response.status === 500) {
    //   window.location.href = "/500";
    // }
    if (error.response.status === 404) {
      window.location.href = "/404";
    }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
    // const message =
    // error.response?.status === 404
    //   ? "API not found."
    //   : error?.response?.data?.message || "Something went wrong";
      // return error;
    return Promise.reject(error.response);
  }
);

export const request = async ({
  url,
  method = "GET",
  params,
  body,
  headers,
  responseType,
}: {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  params?: any;
  body?: any;
  headers?: any;
  responseType?: ResponseType;
}) => {
  const res: AxiosResponse = await axiosInstance.request({
    url: url,
    method,
    params,
    data: body,
    headers,
    responseType,
  });

  return res;
};
