import React from "react";
import { Image } from "react-bootstrap";
import "common/dashboardCard/dashboardCard.scss";
import { DashboardCardProps } from "types/dashboardCard";
import { useNavigate } from "react-router-dom";

const DashboardCard: React.FC<DashboardCardProps> = ({
  cardHeading,
  count = 0,
  navRoute,
  img,
  navState={}
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="dashboard-card d-flex align-items-end justify-content-between cursor-pointer" onClick={() => navigate(navRoute ?? '#', {state: navState})}>
        <div className="dashboard-card-left">
          <span>{cardHeading}</span>
          <h1>{count}</h1>
        </div>
        <div className="dashboard-card-right">
          <Image src={img} alt="" />
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
