import { SpinerLoader } from "common/loader/loader";
import React from "react";
import { Chart } from "react-google-charts";
import { ChartProps } from "types/Chart";

export const ReactChart:React.FC<ChartProps> = ({data, options, chartType}) => {

  return (
    <Chart
      chartType={chartType}
      // legendToggle
      // loader={
      //   <SpinerLoader />
      // }
      width="100%"
      height="284px"
      data={data}
      options={options}
      // onSuccess={handleFullyLoaded}
    />
  );
}
