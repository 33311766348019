import {request} from "services/index";
import { AxiosResponse } from "axios";

export const SignInAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/login",
      method: "POST",
      body,
    });
    return response;
  };

export const ResetPassAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/reset-password",
      method: "POST",
      body,
    });
    return response;
  };

export const ForgotPassAPI = async (body: any) => {
    const response: AxiosResponse<any> = await request({
      url: "/forgot-password",
      method: "POST",
      body,
    });
    return response;
  };