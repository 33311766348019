import { createContext, useContext, useMemo, useState } from "react";

type BreadcrumbProps = {
    title: string;
    slug: string;
  }[];
type ProfileProps = {
  profile_image_url: string;
  full_name: string;
  };

const UserIntialValue: BreadcrumbProps = [{title: '', slug: ''}]
const ProfileInitialValue: ProfileProps = {profile_image_url: '', full_name: ''}

const breadCrumbInitialState = {
  breadCrumb: UserIntialValue,
  setBreadCrumb: (UserGlobalModel: BreadcrumbProps) => {},
};
const profileInitialState = {
  profileData: ProfileInitialValue,
  setProfileData: (UserGlobalModel: ProfileProps) => {},
};

export const GlobalContext = createContext({...breadCrumbInitialState, ...profileInitialState});

export function GlobalContextProvider({ children }: any) {

  const [breadCrumb, setBreadCrumb] = useState(breadCrumbInitialState.breadCrumb);
  const [profileData, setProfileData] = useState(profileInitialState.profileData);

  const contextValue = useMemo(() => {
    return { breadCrumb, setBreadCrumb, profileData, setProfileData };
  }, [breadCrumb, profileData]);

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}

export const GlobalState = () => useContext(GlobalContext);
