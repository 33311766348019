import * as Yup from "yup";

export const CreateInstanceTaskSchema = Yup.object().shape({
  task_name: Yup.object()
  .required("Task is required")
  .typeError("Task is required"),
  jobsite: Yup.object()
  .required("Jobsite is required")
  .typeError("Jobsite is required"),
  // area: Yup.object()
  // .required("Area is required")
  // .typeError("Area is required"),
  // unit: Yup.number().required("Unit is required").typeError("Unit is required").positive("Unit must be a positive number"),
  // unit: Yup.number()
  // .positive("Unit must be a positive number")
  // .typeError("Unit must be a number"),
  
  start_date: Yup.date().required("Start Date is required").typeError("Start Date is required"),
  start_time: Yup.date().required("Start Time is required").typeError("Start Time is required"),
  end_date: Yup.date().nullable().required("End Date is required").typeError("End Date is required"),
  end_time: Yup.date().required("End Time is required").typeError("End Time is required"),
})

export const CreateTaskSchema = Yup.object().shape({
  task_name: Yup.object()
  .required("Task is required")
  .typeError("Task is required"),
  jobsite: Yup.object()
  .required("Jobsite is required")
  .typeError("Jobsite is required"),
  // area: Yup.object()
  // .required("Area is required")
  // .typeError("Area is required"),
  // unit: Yup.number().required("Unit is required").typeError("Unit is required").positive("Unit must be a positive number"),
  // rate: Yup.number().required("Rate is required").typeError("Rate is required").positive("Rate must be a positive number"),
  start_date: Yup.date().required("Start Date is required").typeError("Start Date is required"),
  start_time: Yup.date().required("Start Time is required").typeError("Start Time is required"),
  end_date: Yup.date().nullable().required("End Date is required").typeError("End Date is required"),
  end_time: Yup.date().required("End Time is required").typeError("End Time is required"),
});

export const AssignWorkSchema = Yup.object().shape({
  employee: Yup.object()
  .required("Employee is required")
  .typeError("Employee is required"),
  start_date: Yup.date().required("Start Date is required").typeError("Start Date is required"),
  end_date: Yup.date().required("End Date is required").typeError("End Date is required"),
  start_time: Yup.date().required("Start Time is required").typeError("Start Time is required"),
  end_time: Yup.date().required("End Time is required").typeError("End Time is required"),
})