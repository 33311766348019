import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";

const libraries = ["places", "drawing"];

const containerStyle = {
  width: "100%",
  height: "400px",
};

const autocompleteStyle = {
  boxSizing: "border-box",
  border: "1px solid transparent",
  width: "240px",
  height: "38px",
  padding: "0 12px",
  borderRadius: "3px",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
  fontSize: "14px",
  outline: "none",
  textOverflow: "ellipses",
  position: "absolute",
  right: "8%",
  top: "11px",
  marginLeft: "-120px",
};

const polygonNewOptions = {
  fillOpacity: 0.2,
  fillColor: "#7349D3",
  strokeColor: "#7349D3",
  strokeWeight: 3,
};

const ReactGoogleMapComponent = ({
  resetPolygon,
  handlePolygonData,
  handleMarkerData,
  polygonData = [],
  activePolygonData = [],
  markerData = {},
  handleAddressData,
}) => {
  const [address, setAddress] = useState(null);
  const [polygons, setPolygons] = useState([polygonData]);

  const [markerPosition, setMarkerPosition] = useState(markerData);
  const [path, setPath] = useState(activePolygonData);
  const [isLoadedMap, setIsLoadedMap] = useState(false);

  const defaultCenter = {
    lat: 37.09024,
    lng: -95.712891,
  };
  const [center, setCenter] = useState(defaultCenter);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: ["polygon", "marker"],
    },
    polygonOptions: {
      clickable: true,
      editable: true,
      zIndex: 1,
    },
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  useEffect(() => {
    setIsLoadedMap(false);

    const timeout = setTimeout(() => {
      setIsLoadedMap(true);
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, loadError]);

  const mapRef = useRef();
  const autocompleteRef = useRef(null);
  const polygonRef = useRef(null);
  const listenerRef = useRef([]);

  // const onLoadMap = (map) => {
  //   mapRef.current = map;
  //   let bounds = new window.google.maps.LatLngBounds(polygons);
  //   map.fitBounds(bounds);
  // };

  const onLoadMap = (map) => {
    mapRef.current = map;

    if (polygons?.length > 0 && polygons?.[0]?.length > 0) {
      const points = polygons[0].map(
        (point) => new window.google.maps.LatLng(point.lat, point.lng)
      );
      const bounds = new window.google.maps.LatLngBounds();
      points.forEach((point) => bounds.extend(point));
      map.fitBounds(bounds);
    } else if (activePolygonData?.length > 0) {
      const points = activePolygonData.map(
        (point) => new window.google.maps.LatLng(point.lat, point.lng)
      );
      const bounds = new window.google.maps.LatLngBounds();
      points.forEach((point) => bounds.extend(point));
      map.fitBounds(bounds);
    }
  };

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef?.current?.getPlace();

    if (!place || !place.geometry) {
      console.error("No valid place or geometry found.");
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    if (mapRef?.current) {
      mapRef.current.fitBounds(bounds);
    } else {
      console.error("Map reference is not initialized.");
    }
  };

  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(poly) {
      const polyArray = poly.getPath().getArray();
      let paths = [];

      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });

      // Check if polygons has data
      if (polygons?.length > 0 && polygons?.[0]?.length > 0) {
        // Check if every vertex of the new polygon is inside the first polygon
        let isInside = true;
        for (let i = 0; i < polyArray.length; i++) {
          if (
            !window.google.maps.geometry.poly.containsLocation(
              polyArray[i],
              new window.google.maps.Polygon({ paths: polygons[0] })
            )
          ) {
            isInside = false;
            break;
          }
        }

        if (isInside) {
          setPath(paths);
          handlePolygonData(paths);
          // point(paths);
          poly.setMap(null);
        } else {
          alert("The new polygon is not completely inside the first polygon.");
          poly.setMap(null);
        }
      } else {
        // Save the polygon data when polygons is empty
        setPath(paths);
        handlePolygonData(paths);
        // point(paths);
        poly.setMap(null);
      }
    },
    [polygons]
  );

  const onEdit = useCallback(() => {
    if (polygonRef.current && window.google && window.google.maps) {
      const editedPath = polygonRef?.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });

      if (polygons.length > 0 && polygons[0].length > 0) {
        // Ensure you have a copy of the original path or vertices before editing
        const originalPath = polygons[0].map((latlng) => {
          return { lat: latlng.lat, lng: latlng.lng };
        });

        let allInside = true;

        // Check each vertex of the edited polygon
        editedPath.forEach((vertex, index) => {
          if (
            !window.google.maps.geometry.poly.containsLocation(
              vertex,
              new window.google.maps.Polygon({ paths: polygons[0] })
            )
          ) {
            // If vertex is outside, revert to the original path vertex at the same index
            const lastValidVertex = new window.google.maps.LatLng(
              originalPath[index].lat,
              originalPath[index].lng
            );
            polygonRef.current.getPath().setAt(index, lastValidVertex);
            allInside = false;
          }
        });

        if (allInside) {
          setPath(editedPath);
          handlePolygonData(editedPath);
          // polygonRef.current = null;
        } else {
          alert(
            "The edited polygon moved outside the first polygon. Changes discarded."
          );
        }
      } else {
        console.log("editedPath--", editedPath);
        setPath(editedPath);
        handlePolygonData(editedPath);
        // polygonRef.current = null;
      }
    }
  }, [polygonRef, setPath]);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      // setPath(path);
      // setActivePolygons([]);
      listenerRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenerRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onMarkerComplete = (marker) => {
    const position = marker.getPosition();
    const lat = position.lat();
    const lng = position.lng();
    marker.setMap(null);
    handleMarkerData({ lat, lng });
    setMarkerPosition({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const formattedAddress = results?.[0].formatted_address;
          handleAddressData(formattedAddress);
          setAddress(formattedAddress);
        } else {
          console.log("No results found, Address detail");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });

    // Call Google Places API for nearby restaurants
    // axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    //   params: {
    //     latlng: `${lat},${lng}`,
    //     types: 'point of interest',
    //     key: process.env.REACT_APP_GOOGLE_API_KEY,
    //   }
    // })
    // .then(response => {
    //   console.log('Place Details:', response.data.results?.[0]?.formatted_address);
    //   // Handle the response data as needed (e.g., display in your UI)
    // })
    // .catch(error => {
    //   console.error('Error fetching place details:', error);
    //   // Handle errors gracefully
    // });
  };

  useEffect(() => {
    if (resetPolygon !== 0) {
      setPath([]);
      handlePolygonData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPolygon]);

  return isLoaded && isLoadedMap ? (
    <>
      <div className="map-container">
        <GoogleMap
          zoom={14}
          center={center}
          onLoad={onLoadMap}
          mapContainerStyle={containerStyle}
          onTilesLoaded={() => setCenter(null)}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          <DrawingManager
            options={options}
            onPolygonComplete={onPolygonComplete}
            onMarkerComplete={onMarkerComplete}
            editable
          />

          <Polygon
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={polygonNewOptions}
            path={path}
            editable
          />

          {polygons.map((iterator, index) => (
            <>
              <Polygon
                key={index + "polygon"}
                options={polygonNewOptions}
                paths={iterator}
              />
            </>
          ))}

          {markerPosition && (
            <Marker position={markerPosition} onClick={() => {}} />
          )}
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Search Location"
              style={autocompleteStyle}
            />
          </Autocomplete>
        </GoogleMap>
      </div>
    </>
  ) : null;
};

export default ReactGoogleMapComponent;
