import { useMutation, useQuery } from "@tanstack/react-query";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import ConfirmModal from "common/confirmModal/confirmModal";
import Textbox from "common/form/textBox";
import ConstantGoogleMap from "common/googleMap/constantMap";
import { ScalingLoader } from "common/loader/loader";
import SuccessModal from "common/modal/successModal/successModal";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SortOrder, TableColumn } from "react-data-table-component";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetJobsiteAreaListAPI,
  GetJobsiteJobsiteDetailAPI,
  JobsiteArchiveAPI,
} from "services/jobsite";
import appImages from "utils/appImages";

const JobsiteDetail = () => {
  // react data table state
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<any>({});
  const [areaListData, setAreaListData] = useState<any>();
  // react data table state
  const { id: jobsiteId } = useParams();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [copied, setCopied] = useState(false);
  const [polygonData, setPolygonData] = useState<any[]>([]);
  const [jobsiteData, setJobsiteData] = useState<any[]>([]);
  const [markerData, setMarkerData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [archiveStatus, setArchiveStatus] = useState<string>();
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);

  const { setBreadCrumb } = GlobalState();

  const breadcrumbList = [
    {
      title: "Jobsite",
      slug: "/jobsite",
    },
    {
      title: "Jobsite Detail",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "archive":
        data = {
          title: "Jobsite Archived successfully",
          description: "Jobsite has been successfully archived",
        };
        return data;
      case "unArchive":
        data = {
          title: "Jobsite unArchived successfully",
          description: "Jobsite has been successfully unArchived",
        };
        return data;
      default:
        return null;
    }
  };

  const { control, setValue, watch } = useForm({});

  const { fetchStatus, refetch: refetchJobsiteDetail } = useQuery({
    queryKey: ["jobsiteDetailData"],
    queryFn: async () => {
      try {
        const data = jobsiteId && (await GetJobsiteJobsiteDetailAPI({ id: jobsiteId }));
        const result = data && data?.data?.result;
        if (result) {
          const polygonData = result?.jobsite_polygon_info?.map((item: any) => {
            return { lat: +item?.latitude, lng: +item?.longitude };
          });

          const areaPolygonData: any[] = [];

          result?.areaInfo.forEach((item: any) => {
            let polyArray: any[] = [];
            item.area_polygon_info.forEach((info: any) => {
              const lat = +info.latitude;
              const lng = +info.longitude;
              polyArray.push({ lat, lng });
            });
            areaPolygonData.push(polyArray);
          });

          setValue("id", result?.id);
          setValue("building_owner", result?.building_owner ?? '-');
          setValue("jobsite", result?.jobsite_title);
          setValue("location", result?.address);
          setValue("total_unit", result?.total_unit);
          setValue("archiveStatus", result?.is_archive);
          setMarkerData({ lat: +result?.latitude, lng: +result?.longitude });
          setPolygonData([polygonData, ...areaPolygonData]);
          setJobsiteData(polygonData);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const getAreaListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
      jobsite_id: jobsiteId && jobsiteId,
    };
    GetAreaMutate(data);
  };

  useEffect(() => {
    getAreaListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    fromDate,
  ]);

  const { isPending, mutate: GetAreaMutate } = useMutation({
    mutationFn: GetJobsiteAreaListAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setAreaListData(data.data.result);
      return data;
    },
  });

  const { isPending: JobsiteArchivePending, mutate: JobsiteArchiveMutate } =
    useMutation({
      mutationFn: JobsiteArchiveAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: () => {
        refetchJobsiteDetail();
        isShowConfirmModal("");
        setIsShowSuccessModal(true);
      },
    });

  const handleArchiveJobsite = (archive?: string) => {
    setArchiveStatus(archive);
    setCurConfirmModal(archive==='1' ? "unArchive" : "archive");
    isShowConfirmModal(archive==='1' ? "unArchive" : "archive");
  };

  const handleActionClick = (
    action: string,
    status?: any,
    id?: number,
    archive?: string
  ) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "edit":
        return navigate(`/edit-jobsite-area/${id}`, {state: { id: jobsiteId, jobsiteData: jobsiteData }});
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Area",
      selector: (row: any) => row?.area_name ?? "-",
      sortField: "area_name",
      sortable: true,
    },
    {
      id: 3,
      name: "Unit",
      selector: (row: any) => row?.total_unit ?? "-",
      sortField: "total_unit",
      sortable: true,
    },
    {
      id: 4,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            status={row?.is_active === "1" ? true : false}
            archiveText={row?.is_archived === "1" ? "UnArchive" : "Archive"}
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id, row.is_archived)
            }
            isVisibles={["edit"]}
            onToggleChange={(e: boolean) => isDropdownOpen(e)}
          />
        </>
      ),
      width: "7%",
    },
    {
      id: 5,
      name: "More",
      cell: (row: any) => (
        <Image
          className="cursor-pointer"
          onClick={() => navigate(`/jobsite-area-detail/${row?.id}`, {state: {jobsite_id: jobsiteId}})}
          src={appImages.RightDetailIcon}
          alt="edit"
          width={14}
          height={14}
        />
      ),
      width: "80px",
    },
  ];

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? sortDirection : undefined,
    });
  };

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  return (
    <>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          Jobsite details
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="detail-form d-sm-flex d-block">
              <Form>
                <div className="position-relative form-group-icon">
                  <Textbox
                    control={control}
                    type="text"
                    id="id"
                    name="id"
                    value=""
                    label={"ID"}
                    placeholder={"ID"}
                    autoComplete="off"
                    disabled
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                  >
                    <Image
                      src={appImages.CopyIcon}
                      alt="copy"
                      className="cursor-pointer"
                      onClick={() => {
                        copy(watch("id"));
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    />
                  </OverlayTrigger>
                </div>
                <Textbox
                  control={control}
                  type="text"
                  id="building_owner"
                  name="building_owner"
                  value=""
                  label={"Building Owner"}
                  placeholder={"Building Owner"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="jobsite"
                  name="jobsite"
                  value=""
                  label={"Jobsite"}
                  placeholder={"Jobsite"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="location"
                  name="location"
                  value=""
                  label={"Location"}
                  placeholder={"Location"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="total_unit"
                  name="total_unit"
                  value=""
                  label={"Total Unit"}
                  placeholder="Total Unit"
                  autoComplete="off"
                  disabled
                />
              </Form>
            </div>
          )}
        </Card.Body>
      </Card>
      {(fetchStatus !== "fetching" && polygonData?.length) && (
        <ConstantGoogleMap polygonData={polygonData} markerData={markerData} />
      )}
      {fetchStatus !== "fetching" &&
      <div className="area-btn my-3 gap-3 d-flex justify-content-center">
        <Button
          onClick={() => handleArchiveJobsite(watch('archiveStatus'))}
          variant="danger"
        >
          {watch('archiveStatus')==='1'? 'UnArchive' : "Archive"} Jobsite
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            navigate("/create-jobsite-area", {
              state: { id: jobsiteId, jobsiteData: jobsiteData },
            })
          }
        >
          Add Area
        </Button>
      </div>
      }

      <ReactDataTable
        columns={columns}
        tableData={areaListData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={areaListData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getAreaListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {['unArchive', 'archive'].includes(showConfirmModal) && (
        <ConfirmModal
          show={['unArchive', 'archive'].includes(showConfirmModal)}
          onConfirm={() =>
            JobsiteArchiveMutate({
              id: jobsiteId,
              is_archive: watch('archiveStatus') == "1" ? "0" : "1",
            })
          }
          isLoading={JobsiteArchivePending}
          handleClose={handleCloseConfirmModal}
          message={`Do you really want to ${showConfirmModal ?? 'archive'} this Jobsite?`}
        />
      )}
    </>
  );
};

export default JobsiteDetail;
