import React, { useEffect } from "react";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Textbox from "common/form/textBox";
import { CreateTitle } from "utils/schema/titles";
import { CreateRegionTitleAPI, EditRegionTitleAPI } from "services/title";

const CreateRegionTitle: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  isCreateModal,
  data
}) => {

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(CreateTitle),
  });

  useEffect(() => {
    if(!isCreateModal){
      setValue('title_name', data?.region_title)
    }
  }, [reset, setValue])

  const { isPending, mutate: CreateTitleMutate } = useMutation({
    mutationFn: CreateRegionTitleAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });
  const { isPending: isPendingEdit, mutate: EditTitleMutate } = useMutation({
    mutationFn: EditRegionTitleAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "edit");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("region_title", submitFormData?.title_name);
    if(!isCreateModal && data?.id){
      formData.append("id", data?.id);
    }

    isCreateModal ? CreateTitleMutate(formData) :
    EditTitleMutate(formData)
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col md={12}>
              <Textbox
                control={control}
                type="text"
                id="title_name"
                name="title_name"
                placeholder={"Region Title Name"}
                autoComplete="off"
                max={100}
                errorMessage={errors.title_name && errors.title_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending || isPendingEdit}>
                {(isPending || isPendingEdit) && <SubmitLoader />}
                {isCreateModal ? 'Create' : 'Save'}
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default CreateRegionTitle;
