import { useQuery } from "@tanstack/react-query";
import { SpinerLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import moment from "moment";
import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { EmpJobsiteDetailListAPI } from "services/common";
import { ModalProps } from "types/modal";
import ImagesColumn from "utils/helpers/imageColumn";

const JobSiteTable: React.FC<ModalProps> = ({
  title,
  show,
  handleClose,
  id,
  taskId,
}) => {
  const [jobsiteData, setJobsiteData] = useState<any>();
  
  const { fetchStatus } = useQuery({
    queryKey: ["empJobsiteDetailList"],
    queryFn: async () => {
      try {
        const data = await EmpJobsiteDetailListAPI({ jobsite_id: id, task_id: taskId });
        return setJobsiteData(data?.data?.result);
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Date",
      selector: (row: any) =>
        row.start_datetime
          ? moment(row.start_datetime).format("D MMM, YYYY | hh:mm A")
          : "",
    },
    {
      id: 2,
      name: "Area",
      cell: (row: any) => row?.area_name ?? "-"
    },
    {
      id: 3,
      name: "Worker Name",
      selector: (row: any) => row.valet_name ?? "-",
    },
    {
      id: 4,
      name: "Area check in/out",
      selector: (row: any) =>
        (row.punch_in ? `${moment(row.area_punch_in).format("hh:mm A")}` : "-") +
        " to " +
        (row.punch_out ? `${moment(row.area_punch_out).format("hh:mm A")}` : "-"),
    },
    // { 
    //   id: 5,
    //   name: "Images",
    //   cell: (row: any) => (
    //     <span className="jobsite-images">
    //       <ImagesColumn
    //         row={row?.task_images?.slice(0, 3)?.map((el: any) => el?.task_image_url) ?? []}
    //       />
    //     </span>
    //   ),
    //   width: "180px",
    // },
    {
      id: 6,
      name: "Observation",
      selector: (row: any) => row.observation_count ?? "-",
    },
    {
      id: 7,
      name: "Qr Code Scan Time",
      selector: (row: any) => row.scan_time ?? "-",
    },
    {
      id: 8,
      name: "Qr Code Scan",
      selector: (row: any) => row.qr_code_scan_count ?? "-",
    },
    {
      id: 9,
      name: "Photos",
      selector: (row: any) =>
        row?.issue_image_url ? (
          <span className="jobsite-images">
            <ImagesColumn row={[row?.issue_image_url]} />
          </span>
        ) : '-',
    },
  ];

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={"full-modal"}
      >
        {false ? (
          <SpinerLoader />
        ) : (
          <>
            <ReactDataTable
              columns={columns}
              tableData={jobsiteData}
              rowsPerPage={10}
              totalRecord={100}
              inProgress={fetchStatus === "fetching"}
              isPagination={false}
            />
          </>
        )}
      </CommonModal>
    </>
  );
};

export default JobSiteTable;
