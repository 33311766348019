import React, { useEffect, useState } from "react";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { JobsiteDropdownListAPI } from "services/common";
import Textbox from "common/form/textBox";
import { GenerateQrSchema } from "utils/schema/qr";
import { CreateQrAPI, EditQrAPI, GetQRDetailAPI } from "services/qr";

const GenerateQR: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  currentRow,
  isCreateModal,
}) => {
  const [formData, setFormData] = useState<any>({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(GenerateQrSchema),
  });

  const { isPending: isPendingJobsiteList, mutate: GetJobsiteList } =
    useMutation({
      mutationFn: JobsiteDropdownListAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setFormData({ ...formData, jobsiteList: data.data.result });
      },
    });

  useEffect(() => {
    GetJobsiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fetchStatus } = useQuery({
    queryKey: ["qrDetail"],
    enabled: !isCreateModal,
    queryFn: async () => {
      try {
        const data =
          currentRow && (await GetQRDetailAPI(currentRow.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("qr_name", result?.qr_code_name);
          setValue("jobsite", result?.job_site_info);
          setValue("start_date", new Date(result?.start_date));
          setValue("end_date", new Date(result?.end_date));
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending, mutate: CreateQrMutate } = useMutation({
    mutationFn: CreateQrAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });

  const { isPending: pendingEditQR, mutate: EditQrMutate } = useMutation({
    mutationFn: EditQrAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "update");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("qr_code_name", submitFormData?.qr_name);
    formData.append("jobsite_id", submitFormData?.jobsite?.id);
    formData.append(
      "start_date",
      moment(submitFormData?.start_date).format("YYYY-MM-DD")
    );
    formData.append(
      "end_date",
      moment(submitFormData?.end_date).format("YYYY-MM-DD")
    );
    if(!isCreateModal){
      currentRow && formData.append("id", currentRow.toString());
    }

    isCreateModal
      ? CreateQrMutate(formData)
      : currentRow && EditQrMutate(formData);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        {!isCreateModal && fetchStatus === "fetching" ? (
          <SpinerLoader />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Row>
              <Col md={12}>
                <Textbox
                  control={control}
                  type="text"
                  id="qr_name"
                  name="qr_name"
                  placeholder={"QR Code Name"}
                  autoComplete="off"
                  max={100}
                  errorMessage={errors.qr_name && errors.qr_name?.message}
                />
              </Col>
              <Col md={12}>
                <Controller
                  name="jobsite"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isPendingJobsiteList}
                        {...field}
                        getOptionLabel={(item: any) => item.jobsite_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.jobsiteList ?? []}
                        isSearchable
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Jobsite"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.jobsite && (
                        <div className="invalid-feedback d-block">
                          {errors.jobsite.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <DatePickerReact
                  startDate={watch("start_date")}
                  control={control}
                  id="start_date"
                  name="start_date"
                  minDate={new Date()}
                  value=""
                  className="form control"
                  placeholder="Start Date"
                  autoComplete="off"
                  errorMessage={errors.start_date && errors.start_date.message}
                  onChange={(start_date) => {
                    setValue("end_date", null as unknown as Date);
                    if (start_date) {
                      setValue("start_date", start_date);
                      trigger("start_date");
                    }
                  }}
                />
              </Col>
              <Col md={12}>
                <DatePickerReact
                  startDate={watch("end_date")}
                  control={control}
                  minDate={watch("start_date")}
                  id="end_date"
                  name="end_date"
                  value=""
                  className="form control"
                  placeholder="End Date"
                  autoComplete="off"
                  errorMessage={errors.end_date && errors.end_date.message}
                  onChange={(end_date) => {
                    if (end_date) {
                      setValue("end_date", end_date);
                      trigger("end_date");
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-center gap-4">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isPending || pendingEditQR}>
                  {(isPending || pendingEditQR) && <SubmitLoader />}
                  Save
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </CommonModal>
    </>
  );
};

export default GenerateQR;
