import { request } from "services/index";
import { AxiosResponse } from "axios";

export const CreateEmployeeAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-employee",
    method: "POST",
    body,
  });
  return response;
};

export const GetEmployeeAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/employee-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetValetAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/employee-list-rm",
    method: "POST",
    body,
  });
  return response;
};

export const ArchiveEmployeeAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/employee-archive",
    method: "POST",
    body,
  });
  return response;
};

export const UpdateEmployeeAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/update-employee`,
    method: "POST",
    body,
  });
  return response;
};

export const StatusUpdateEmpAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-employee-status",
    method: "POST",
    body,
  });
  return response;
};

export const DeleteEmployeeAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/employee-delete",
    method: "POST",
    body,
  });
  return response;
};

export const GetEmployeeDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/employee-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const GetEmpWorkDetailListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/employee-detail-work-report`,
    method: "POST",
    body
  });
  return response;
};

export const GetEmployeeFormDataAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: `/get-employee-form-data`,
    method: "GET",
  });
  return response;
};
