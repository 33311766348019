import { useMutation, useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import {
  ScalingLoader,
  SpinerLoader,
  SubmitLoader,
} from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetLiveLocationAPI,
  GetTaskDetailAPI,
  UpdateTaskAPI,
} from "services/task";
import appImages from "utils/appImages";
import SuccessModal from "common/modal/successModal/successModal";
import CreateInstantTask from "components/manageTask/createInstantTask";
import "components/manageTask/manageTask.scss";
import CommonModal from "common/modal/commonModal";
import LiveTrackingMap from "common/googleMap/liveTrackingMap";

const TaskDetail = () => {
  const { id } = useParams();
  const { setBreadCrumb } = GlobalState();
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [createInstantTask, setCreateInstantTask] = useState<boolean>(false);
  const [showLiveLocation, setShowLiveLocation] = useState<boolean>(false);
  const [polygonData, setPolygonData] = useState<any[]>([]);
  const [markerData, setMarkerData] = useState<any>({});
  const [copied, setCopied] = useState(false);
  // const [successModal, showSuccessModal] = useState<boolean>(false);

  const breadcrumbList = [
    {
      title: "Manage Task",
      slug: "/task",
    },
    {
      title: "Task Details",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, setValue, watch } = useForm({});

    const { mutate: GetLiveLocationMutate } = useMutation({
      mutationFn: GetLiveLocationAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        const result = data && data?.data?.result;
        setMarkerData({ lat: +result?.employee_task_info?.emp_cur_latitude, lng: +result?.employee_task_info?.emp_cur_longitude});
      },
    });

  useEffect(() => {
    let intervalId: any;

    if (showLiveLocation) {
      GetLiveLocationMutate({ task_id: id });
      intervalId = setInterval(() => {
        GetLiveLocationMutate({ task_id: id });
      }, 15000); // 15 seconds
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showLiveLocation]);

  const { fetchStatus, refetch: refetchTaskData } = useQuery({
    queryKey: ["taskData"],
    queryFn: async () => {
      try {
        const data = id && (await GetTaskDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("instant_task_info", result?.instant_task_info);
          setValue("task_title", result?.task_title);
          setValue("task_status", result?.task_status);
          setValue("task_description", result?.description);
          setValue("task_images", result?.task_images);
          setValue("task_id", result?.task_title_id);
          setValue(
            "date_range",
            `${moment.utc(result?.start_date).format("DD MMM yyyy")} To ${moment
              .utc(result?.end_date)
              .format("DD MMM yyyy")}`
          );
          setValue(
            "time_range",
            `${result?.start_time} To ${result?.end_time}`
          );
          setValue("jobsite", result?.job_site_info?.jobsite_title);
          setValue(
            "area",
            result?.area_list.length > 0
              ? result?.area_list
                  ?.map((el: any) => el?.area_name)
                  .join(", ")
              : "-"
          );
          setValue("jobsite_info", result?.job_site_info);
          setValue("area_info", result?.area_info);
          setValue("unit_no", result?.unit ?? "-");
          setValue("employee_task_info", result?.employee_task_info);
          setValue(
            "date_requested",
            moment
              .utc(result?.employee_task_info?.assign_date)
              .format("DD MMM YYYY") ?? "-"
          );
          setValue("date_fulfilled", "-");

          setValue(
            "profile_image",
            result?.employee_task_info?.user_info?.profile_image_url
          );
          setValue("id", result?.employee_task_info?.user_info?.id);
          setValue(
            "user_name",
            result?.employee_task_info?.user_info?.full_name
          );
          setValue(
            "title",
            result?.employee_task_info?.user_info?.title ?? "-"
          );
          setValue("email", result?.employee_task_info?.user_info?.email);
          setValue("mobile", result?.employee_task_info?.user_info?.phone);
          setValue("address", result?.employee_task_info?.user_info?.address);
          setValue(
            "valet_jobsite",
            result?.employee_task_info?.employee_info?.job_site_info
              .map((item: any) => item?.jobsite_title)
              .join(", ")
          );
          setValue(
            "valet_area",
            result?.employee_task_info?.employee_info?.area_info
              .map((item: any) => item?.area_name)
              .join(", ")
          );
          setValue("zipcode", result?.employee_task_info?.user_info?.zipcode);
          setValue(
            "state",
            result?.employee_task_info?.user_info?.state_info?.state_name
          );
          setValue(
            "city",
            result?.employee_task_info?.user_info?.city_info?.city_name
          );
          setValue(
            "time_zone",
            result?.employee_task_info?.user_info?.time_zone_info.label
          );
          const polygonData = result?.job_site_info?.jobsite_polygon_info?.map((item: any) => ({ lat: +item?.latitude, lng: +item?.longitude }));
          const areaPolygonData = result?.area_info?.area_polygon_info?.map((item: any) => ({ lat: +item?.latitude, lng: +item?.longitude }));
          setPolygonData([polygonData, areaPolygonData]);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: isPendingUpdateTask, mutate: UpdateTaskMutate } =
    useMutation({
      mutationFn: UpdateTaskAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setCurConfirmModal("completeTask");
        refetchTaskData();
        setIsShowSuccessModal(true);
      },
    });

  const handleTaskStatus = (key: string) => {
    switch (key) {
      case "0":
        return "Pending";
      case "1":
        return "Assigned";
      case "2":
        return "Completed";
      case "3":
        return "Ongoing";
    }
  };

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "createInstant":
        data = {
          title: "Instant Task created successfully",
          description: "Instant Task has been successfully created",
        };
        return data;
      case "completeTask":
        data = {
          title: "Task complete successfully",
          description: "Task has been successfully completed",
        };
        return data;
    }
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Task Details</h2>
        {watch("task_status") !== "2" && fetchStatus !== "fetching" && (
          <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
            <Button
              variant="primary"
              onClick={() => setCreateInstantTask(true)}
            >
              Create Instant task
            </Button>
          </div>
        )}
      </div>
      <Card className="mb-30">
        <Card.Body>
          <div className="d-flex media">
            <div className="flex-shrink-0 media-left">
              <Image
                src={appImages.TaskDetailIcon}
                alt=""
                width={24}
                height={24}
              />
            </div>
            <div className="flex-grow-1 card-title d-block media-right">
              <div className="d-flex justify-content-between mb-2">
                <h2 className="mb-0">{watch("task_title")}</h2>
                <div
                  className={
                    handleTaskStatus(watch("task_status"))?.toLowerCase() +
                    " status-tooltip"
                  }
                >
                  {handleTaskStatus(watch("task_status")?.toString())}
                </div>
              </div>
              <p className="mb-0 mb-sm-2">{watch("task_description")}</p>
            </div>
          </div>

          <div className="observation-detail card-title d-block">
            {fetchStatus === "fetching" ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : (
              <div className="detail-form d-sm-flex d-block">
                <div className="detail-form-images mb-sm-0 mb-3">
                  {watch("task_images")?.map((img: any, index: number) => {
                    return (
                      <Image
                        tabIndex={index}
                        src={img?.task_image_url}
                        alt=""
                      />
                    );
                  })}
                </div>
                <div className="d-flex flex-column w-100">
                  <Form>
                    <Textbox
                      control={control}
                      type="text"
                      id="task_id"
                      name="task_id"
                      value=""
                      label={"Id"}
                      placeholder={"Id"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="date_range"
                      name="date_range"
                      value=""
                      label={"Date Range"}
                      placeholder={"Date Range"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="time_range"
                      name="time_range"
                      value=""
                      label={"Time Range"}
                      placeholder={"Time Range"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="jobsite"
                      name="jobsite"
                      value=""
                      label={"Jobsite"}
                      placeholder={"Jobsite"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="area"
                      name="area"
                      value=""
                      label="Area (Building)"
                      placeholder="Area"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="unit_no"
                      name="unit_no"
                      value=""
                      label={"Unit No"}
                      placeholder={"Unit No"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="date_requested"
                      name="date_requested"
                      value=""
                      label={"Date Requested"}
                      placeholder={"Date Requested"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="date_fulfilled"
                      name="date_fulfilled"
                      value=""
                      label={"Date Fulfilled"}
                      placeholder={"Date Fulfilled"}
                      autoComplete="off"
                      disabled
                    />
                  </Form>
                </div>
              </div>
            )}
          </div>
          {(watch("task_status") === "4" && fetchStatus !== "fetching") && (
            <div className="d-flex justify-content-center">
              <Button
                disabled={isPendingUpdateTask}
                variant="primary"
                onClick={() => UpdateTaskMutate({ id: id, task_status: "2" })}
              >
                {isPendingUpdateTask && <SubmitLoader />} Complete Task
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>

      {watch("instant_task_info")?.length > 0 && (
        <>
          <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
            <h2>Instant Task Details</h2>
          </div>
          {watch("instant_task_info")?.map((item: any, index: number) => {
            return (
              <Card key={index} className="mb-30">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex media">
                      <div className="flex-shrink-0 media-left">
                        <Image
                          src={appImages.TaskDetailIcon}
                          alt=""
                          width={24}
                          height={24}
                        />
                      </div>
                      <div className="flex-grow-1 card-title d-block media-right">
                        <h2>
                          {item?.instant_task_title_info?.instant_task_title}
                        </h2>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="observation-detail card-title d-block">
                    <div className="detail-form d-sm-flex d-block">
                      <div className="detail-form-images mb-sm-0 mb-3">
                        {item?.instant_task_images?.map(
                          (img: any, index: number) => {
                            return (
                              <Image
                                tabIndex={index}
                                src={img?.instant_task_image_url}
                                alt=""
                              />
                            );
                          }
                        )}
                      </div>
                      <div className="d-flex flex-column w-100">
                        <Form>
                          <Textbox
                            control={control}
                            type="text"
                            id="inst_task_id"
                            name="inst_task_id"
                            value={item?.id}
                            label={"Id"}
                            placeholder={"Id"}
                            autoComplete="off"
                            disabled
                          />
                          <Textbox
                            control={control}
                            type="text"
                            id="inst_date"
                            name="inst_date"
                            value={
                              moment(item?.start_date).format("DD MMM YYYY") +
                              " To " +
                              moment(item?.end_date).format("DD MMM YYYY")
                            }
                            label={"Date Range"}
                            placeholder={"Date Range"}
                            autoComplete="off"
                            disabled
                          />
                          <Textbox
                            control={control}
                            type="text"
                            id="inst_time_range"
                            name="inst_time_range"
                            value={item?.start_time + " To " + item?.end_time}
                            label={"Time Range"}
                            placeholder={"Time Range"}
                            autoComplete="off"
                            disabled
                          />
                          <Textbox
                            control={control}
                            type="text"
                            id="inst_jobsite"
                            name="inst_jobsite"
                            value={item?.job_site_info?.jobsite_title}
                            label={"Jobsite"}
                            placeholder={"Jobsite"}
                            autoComplete="off"
                            disabled
                          />
                          <Textbox
                            control={control}
                            type="text"
                            id="inst_area"
                            name="inst_area"
                            value={item?.area_info?.area_name}
                            label="Area (Building)"
                            placeholder="Area"
                            autoComplete="off"
                            disabled
                          />
                          <Textbox
                            control={control}
                            type="text"
                            id="inst_unit_no"
                            name="inst_unit_no"
                            value={item?.unit ?? "-"}
                            label={"Unit No"}
                            placeholder={"Unit No"}
                            autoComplete="off"
                            disabled
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </>
      )}

      {watch("employee_task_info") && (
        <Card className="mb-30">
          <CardHeader className="border-0 bg-purple text-white fw-600">
            Assigned Valet details
          </CardHeader>
          <Card.Body>
            {fetchStatus === "fetching" ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : (
              <div className="detail-form">
                <div className="d-sm-flex d-block">
                  <div className="detail-form-user mb-sm-0 mb-4">
                    <Image src={watch("profile_image")} alt="" />
                  </div>
                  <Form>
                    <div className="position-relative form-group-icon">
                      <Textbox
                        control={control}
                        type="text"
                        id="id"
                        name="id"
                        value=""
                        label={"ID"}
                        placeholder={"ID"}
                        autoComplete="off"
                        disabled
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>
                        }
                      >
                        <Image
                          src={appImages.CopyIcon}
                          alt="copy"
                          className="cursor-pointer"
                          onClick={() => {
                            copy(watch("id"));
                            setCopied(true);
                            setTimeout(() => setCopied(false), 1000);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                    <Textbox
                      control={control}
                      type="text"
                      id="title"
                      name="title"
                      value=""
                      label={"Title"}
                      placeholder={"Title"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="user_name"
                      name="user_name"
                      value=""
                      label={"User Name"}
                      placeholder={"User Name"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="email"
                      name="email"
                      value=""
                      label={"Email"}
                      placeholder={"Email"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="mobile"
                      name="mobile"
                      value=""
                      label={"Mobile Number"}
                      placeholder={"Mobile Number"}
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="valet_jobsite"
                      name="valet_jobsite"
                      value=""
                      label="Jobsite"
                      placeholder="Jobsite"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="valet_area"
                      name="valet_area"
                      value=""
                      label="Area"
                      placeholder="Area"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="address"
                      name="address"
                      value=""
                      label="Address"
                      placeholder="Address"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="number"
                      id="zipcode"
                      name="zipcode"
                      value=""
                      label="Zipcode"
                      placeholder="Zipcode"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="state"
                      name="state"
                      value=""
                      label="State"
                      placeholder="State"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="city"
                      name="city"
                      value=""
                      label="City"
                      placeholder="City"
                      autoComplete="off"
                      disabled
                    />
                    <Textbox
                      control={control}
                      type="text"
                      id="time_zone"
                      name="time_zone"
                      value=""
                      label="Time Zone"
                      placeholder="Time Zone"
                      autoComplete="off"
                      disabled
                    />
                  </Form>
                </div>
                {watch("task_status") === "4" && (
                  <div className="area-btn my-3 gap-3 d-flex justify-content-center">
                    <Button
                      onClick={() => setShowLiveLocation(true)}
                      variant="primary"
                    >
                      Live Tracking
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      )}

      {/* <Card className="mb-30">
        <Card.Body>
          <div className="media">
            <div className="flex-grow-1 card-title d-block media-right ms-0 mb-0">
              <div className="d-flex justify-content-between">
                <h2>Comment*</h2>
                <p className="mb-0 comment-reply-date">DD/MM/YYYY, 09:45 AM</p>
              </div>
              <p className="mb-0">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-30">
        <Card.Body>
          <div className="media">
            <div className="flex-grow-1 card-title d-block media-right ms-0 mb-0">
              <div className="d-flex justify-content-between">
                <h2>Reply*</h2>
                <p className="mb-0 comment-reply-date">DD/MM/YYYY, 09:45 AM</p>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>
          <div className="reply-attached-images card-title d-block mb-0">
            <h2 className="mb-md-3 mb-2">Attached Images Details</h2>
            <div className="d-flex gap-2">
              <Image tabIndex={1} src={appImages.UserIcon} alt="" width={96} height={96}/>
              <Image tabIndex={1} src={appImages.UserIcon} alt="" width={96} height={96} />
              <Image tabIndex={1} src={appImages.UserIcon} alt="" width={96} height={96} />
              <Image tabIndex={1} src={appImages.UserIcon} alt="" width={96} height={96} />
            </div>
          </div>
        </Card.Body>
      </Card> */}

      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {createInstantTask && (
        <CreateInstantTask
          title={"Create Instant Task"}
          setShowSuccessModal={handleShowSuccessModal}
          callback={refetchTaskData}
          show={createInstantTask}
          handleClose={() => setCreateInstantTask(false)}
          data={{ jobsite: watch("jobsite_info"), area: watch("area_info") }}
        />
      )}
      {showLiveLocation && (
        <CommonModal
          title={"Live Tracking"}
          show={showLiveLocation}
          handleClose={() => setShowLiveLocation(false)}
          dynamicCLass={"full-modal"}
        >
            <LiveTrackingMap
              polygonData={polygonData}
              markerData={markerData}
            />
        </CommonModal>
      )}
    </>
  );
};

export default TaskDetail;
