import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Form, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import { NavLink } from "react-router-dom";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GlobalState } from "context/globalContext";
import moment from "moment";
import CreateConcern from "components/manageConcern/createConcern";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import { DeleteConcernAPI, GetConcernListAPI } from "services/concern";
import ConfirmModal from "common/confirmModal/confirmModal";

const ConcernList = () => {
  const { setBreadCrumb } = GlobalState();
  const [isCreateConcern, setIsCreateConcern] = useState(false);
  const [isEditConcern, setIsEditConcern] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<any>({});

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "Manage Concern",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "create":
        data = {
          title: "Concern Create successfully",
          description: "Concern has been successfully created",
        };
        return data;
      case "edit":
        data = {
          title: "Concern update successfully",
          description: "Concern has been successfully updated",
        };
        return data;
      case "delete":
        data = {
          title: "Concern delete successfully",
          description: "Concern has been successfully deleted",
        };
        return data;
      default:
        return null;
    }
  };

  const getConcernListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search_term: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetConcernMutate(data);
  };

  useEffect(() => {
    getConcernListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const { isPending, mutate: GetConcernMutate } = useMutation({
    mutationFn: GetConcernListAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setTaskData(data.data.result);
      return data;
    },
  });

  const { isPending: DeletePending, mutate: DeleteMutate } = useMutation({
    mutationFn: DeleteConcernAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getConcernListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const handleConcernStatus = (key: number) => {
    switch (key) {
      case 0:
        return "Pending";
      case 1:
        return "Completed";
    }
  };

  const handleDelete = () => {
    setCurConfirmModal("delete");
    isShowConfirmModal("delete");
  };

  const handleActionClick = (
    action: string,
    status?: any,
    id?: number,
  ) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "edit":
        return setIsEditConcern(true);
      case "delete":
        return handleDelete();
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Task Name",
      selector: (row: any) =>
        row?.task_title && row?.task_title?.length > 25
          ? row?.task_title.slice(0, 25) + "..."
          : row?.task_title,
    //   sortField: "task_title",
    //   sortable: true,
    },
    {
      id: 3,
      name: "Concern Title",
      selector: (row: any) => row?.concern_title,
      sortField: "concern_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Jobsite",
      selector: (row: any) => row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 5,
      name: "Area",
      selector: (row: any) => row?.area_name ?? '-',
      sortField: "area_name",
      sortable: true,
    },
    {
      id: 6,
      name: "Unit",
      selector: (row: any) => row?.unit ?? '-',
      sortField: "unit",
      sortable: true,
      width: "80px",
    },
    {
        id: 7,
        name: "Date",
        selector: (row: any) =>
            `${moment(row?.date_submitted).format("DD MMM YYYY")}`,
    },
    {
        id: 8,
        name: "Description",
        selector: (row: any) =>
        row?.description ? row?.description.length > 25
            ? row?.description.slice(0, 25) + "..."
            : row?.description : '-',
    },
    {
      id: 9,
      name: "Status",
      cell: (row: any) => (
        <>
          <div
          className={
            handleConcernStatus(row?.concern_status)?.toLocaleLowerCase() + " status-tooltip"
          }
        >
          {handleConcernStatus(row?.concern_status)}
        </div>
        </>
      ),
      width: "130px",
    },
    {
        id: 10,
        name: "Action",
        cell: (row: any) => (
          <>
            <ActionDropdown
              status={row?.is_active === "1" ? true : false}
              actionClick={(action: string, status?: any) =>
                handleActionClick(action, status, row?.id)
              }
              isVisibles={["edit", "delete"]}
              onToggleChange={(e: boolean) => isDropdownOpen(e)}
            />
          </>
        ),
        width: "90px",
      },
    {
      id: 11,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link as={NavLink} to={`/concern-detail/${row?.id}`}>
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  const handleCloseCreateModal = () => {
    setIsCreateConcern(false);
    setIsEditConcern(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Manage Concern</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="text"
              name="sarch"
              id="search"
              value={search}
              placeholder="Search"
              // maxLength={20}
              autoComplete="off"
              onChange={(e: any) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
          <Button variant="primary" onClick={() => setIsCreateConcern(true)}>
            Add Concern
          </Button>
        </div>
      </div>
      <ReactDataTable
        dynamicClass={dropdownOpen ? "dropdown-open" : ""}
        columns={columns}
        tableData={taskData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={taskData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getConcernListData(page+1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {(isCreateConcern || isEditConcern) && (
        <CreateConcern
          title={
            isCreateConcern
              ? "Add Concern"
              : "Edit Concern"
          }
          setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateConcern}
          callback={getConcernListData}
          show={isCreateConcern || isEditConcern}
          handleClose={handleCloseCreateModal}
          currentRow={currentRow}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {showConfirmModal === "delete" && (
        <ConfirmModal
          show={showConfirmModal === "delete"}
          isLoading={DeletePending}
          onConfirm={() => DeleteMutate({ id: currentRow })}
          handleClose={handleCloseConfirmModal}
          type="delete"
          message={
            "Do you really want to delete this record? This process can not be undone."
          }
        />
      )}
    </>
  );
};

export default ConcernList;
