import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetReportsAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/employee-work-report",
    method: "POST",
    body,
  });
  return response;
};