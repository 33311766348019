import React from "react";
import ReactSwitch from "react-switch";
import { SwitchInputProps } from "types/form";

export const Switch: React.FC<SwitchInputProps> = ({
  handleToggleSwitch,
  checked,
  loader,
}) => {
  return (
    <>
      <ReactSwitch
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={(checked: boolean) => handleToggleSwitch?.(checked)}
        checked={checked ?? false}
        disabled={loader}
        offColor="#707070"
        onColor="#34C759"
        handleDiameter={14}
        height={20}
        width={36}
        className="react-switch"
        id="small-radius-switch"
      />
    </>
  );
};
