import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Image } from "react-bootstrap";
import appImages from "utils/appImages";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GlobalState } from "context/globalContext";
import moment from "moment";
import { SentDocumentListAPI } from "services/document";
import ImagePreviewModal from "./imagePreviewModal";

const SentDocumentList = () => {
  const { setBreadCrumb } = GlobalState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [documentData, setDocumentData] = useState<any>();
  const [sortColumn, setSortColumn] = useState<any>({});
  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  const [imgPreviewData, setImgPreviewData] = useState<any>({});

  const breadcrumbList = [
    {
      title: "Document",
      slug: "/document",
    },
    {
      title: "Sent",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sentDocumentListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    SentDocumentListMutate(data);
  };

  useEffect(() => {
    sentDocumentListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, sortColumn?.column_name, sortColumn?.sort_dir]);

  const { isPending, mutate: SentDocumentListMutate } = useMutation({
    mutationFn: SentDocumentListAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setDocumentData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const handleShowImg = (imgs: any[]) => {
    setImgPreviewData({img_url: imgs?.[0]?.document_image_url});
    setShowImgPreview(true);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
    },
    {
      id: 2,
      name: "Date",
      selector: (row: any) => moment(row?.created_at).format("DD MMM, YYYY"),
      sortField: "created_at",
      sortable: true,
    },
    {
      id: 3,
      name: "Time",
      selector: (row: any) => moment(row?.created_at).format("hh:mm A"),
    },
    {
      id: 4,
      name: "Document Name",
      selector: (row: any) =>
        row?.document_title && row?.document_title?.length > 25
          ? row?.document_title.slice(0, 25) + "..."
          : row?.document_title,
      sortField: "document_title",
      sortable: true,
    },
    {
      id: 5,
      name: "Jobsite",
      selector: (row: any) =>
        row?.jobsite_title && row?.jobsite_title?.length > 25
          ? row?.jobsite_title.slice(0, 25) + "..."
          : row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 6,
      name: "To",
      selector: (row: any) =>
        row?.to_user_name && row?.to_user_name?.length > 25
          ? row?.to_user_name.slice(0, 25) + "..."
          : row?.to_user_name,
      sortField: "to_user_name",
      sortable: true,
    },
    {
      id: 7,
      name: "From",
      selector: (row: any) =>
        row?.from_user_name && row?.from_user_name?.length > 25
          ? row?.from_user_name.slice(0, 25) + "..."
          : row?.from_user_name,
      sortField: "from_user_name",
      sortable: true,
    },
    {
      id: 8,
      name: "Document Preview",
      cell: (row: any) => (
        <Image
          className="valet-location cursor-pointer"
          src={appImages.EyeIcon}
          onClick={() => handleShowImg(row?.document_images)}
        />
      ),
    },
  ];

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Sent Document List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={documentData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={documentData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          sentDocumentListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {showImgPreview && (
        <ImagePreviewModal
          data={imgPreviewData}
          show={showImgPreview}
          handleClose={() => setShowImgPreview(false)}
        />
      )}
    </>
  );
};

export default SentDocumentList;
