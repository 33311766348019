import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetObservationAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/observation-list",
    method: "POST",
    body,
  });
  return response;
};

export const ObservationApproveAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/approve-observation",
    method: "POST",
    body,
  });
  return response;
};

export const UpdateObservationAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-observation",
    method: "POST",
    body,
  });
  return response;
};

export const GetObservationDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/observation-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const GetObservationFormDataAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: `/get-observation-form-data`,
    method: "GET",
  });
  return response;
};

export const SendObservationApi = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/send-observation`,
    method: "POST",
    body,
  });
  return response;
};

export const WalkthroughObservationApi = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/update-observation-to-walkthrough`,
    method: "POST",
    body,
  });
  return response;
};