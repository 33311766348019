import { useEffect, useState } from "react";

export const useDebouncedSearch = (initialSearch: string, delay: number) => {
  const [search, setSearch] = useState<string>(initialSearch);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(initialSearch);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [initialSearch, delay]);

  return search;
};
