import { useMutation, useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import { ScalingLoader, SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateTaskAPI } from "services/task";
import appImages from "utils/appImages";
import SuccessModal from "common/modal/successModal/successModal";
import { AddConcernFeedbackAPI, GetConcernDetailAPI } from "services/concern";
// import DragAndDropFileUpload from "common/dragFileUpload/dragFileUpload";
import { FileError } from "react-dropzone";
import Textareabox from "common/form/textAreaBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitFeedbackSchema } from "utils/schema/concern";
import { SubmitFeedbackProps } from "types/concern";

const ConcernDetail = () => {
  const { id } = useParams();
  const { setBreadCrumb } = GlobalState();
  const [copied, setCopied] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [imgUploadErr, setImgUploadErr] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  // const [successModal, showSuccessModal] = useState<boolean>(false);

  const breadcrumbList = [
    {
      title: "Manage Concern",
      slug: "/concern",
    },
    {
      title: "Concern Details",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<SubmitFeedbackProps>({
    resolver: yupResolver(SubmitFeedbackSchema),
  });

  const { fetchStatus, refetch: refetchTaskData } = useQuery({
    queryKey: ["ConcernData"],
    queryFn: async () => {
      try {
        const data = id && (await GetConcernDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setUploadedFiles(
            result?.concern_images?.map((item: any) => item?.concern_image_url)
          );
          setValue("id", result?.id);
          setValue("concern_title", result?.concern_title);
          setValue("task_title", result?.task_title);
          setValue("concern_status", result?.concern_status);
          setValue("description", result?.description);

          setValue("task_id", result?.task_title_id);
          setValue(
            "date_submitted",
            `${moment.utc(result?.date_submitted).format("DD MMM yyyy")}`
          );
          setValue("time_submitted", `${result?.time_submitted}`);
          setValue("jobsite", result?.jobsite_title);
          setValue("area", result?.area_name ?? '-');
          setValue("unit", result?.unit ?? '-');

          setValue("concern_images", result?.concern_images);
          setValue("feedbacks", result?.concern_feedback_info);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: isPendingSubmitFeedback, mutate: submitFeedbackMutate } =
    useMutation({
      mutationFn: AddConcernFeedbackAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setCurConfirmModal("addFeeedback");
        refetchTaskData();
        setIsShowSuccessModal(true);
        setValue("write_answer", "");
      },
    });

  const handleConcernStatus = (key: number | string) => {
    switch (key) {
      case 0:
        return "Pending";
      case 1:
        return "Completed";
    }
  };

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "addFeeedback":
        data = {
          title: "Feedback added successfully",
          description: "Feedback has been successfully added",
        };
        return data;
    }
  };

  // const handleUploadFiles = (files: any[]) => {
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  // };

  // const handleUploadValidate = (file: File): FileError | FileError[] | null => {
  //   // Example validation logic
  //   if (!file) {
  //     console.log("no file uploaded!");
  //   }
  //   if (!file.type.startsWith("image/")) {
  //     setImgUploadErr("Only Support image Files only");
  //     console.warn("File type is not an image");
  //     return null;
  //   }
  //   setImgUploadErr("");
  //   return null;
  // };

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    id && formData.append("concern_id", id);
    formData.append("comment", submitFormData?.write_answer);
    // if (selectedFiles && Array.isArray(selectedFiles)) {
    //   selectedFiles.forEach((image) => {
    //     formData.append("images[]", image);
    //   });
    // }
    submitFeedbackMutate(formData);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Concern Details</h2>
      </div>
      <Card className="mb-30">
        <Card.Body>
          <div className="d-flex media">
            <div className="flex-shrink-0 media-left">
              <Image
                src={appImages.ConcernDetailIcon}
                alt=""
                width={24}
                height={24}
              />
            </div>
            <div className="flex-grow-1 card-title d-block media-right">
              <div className="d-flex justify-content-between mb-2">
                <h2 className="mb-0">{watch("concern_title")}</h2>
                <div
                  className={
                    handleConcernStatus(
                      watch("concern_status") ?? ""
                    )?.toLowerCase() + " status-tooltip"
                  }
                >
                  {handleConcernStatus(watch("concern_status") ?? "")}
                </div>
              </div>
              <p className="mb-0 mb-sm-2">{watch("description")}</p>
            </div>
          </div>

          <div className="observation-detail card-title d-block">
            {fetchStatus === "fetching" ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : (
              <div className="detail-form d-sm-flex d-block">
                {/* <div className="detail-form-user mb-sm-0 mb-4">
                <Image src={watch("profile_image")} alt="" />
              </div> */}
                <Form>
                  <div className="position-relative form-group-icon">
                    <Textbox
                      control={control}
                      type="text"
                      id="id"
                      name="id"
                      value=""
                      label={"ID"}
                      placeholder={"ID"}
                      autoComplete="off"
                      disabled
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                    >
                      <Image
                        src={appImages.CopyIcon}
                        alt="copy"
                        className="cursor-pointer"
                        onClick={() => {
                          copy(watch("id") ?? "");
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      />
                    </OverlayTrigger>
                    {/* <Image className="cursor-pointer" src={appImages.CopyIcon} alt="copy" onClick={() => copy(watch("id"))} /> */}
                  </div>
                  <Textbox
                    control={control}
                    type="text"
                    id="date_submitted"
                    name="date_submitted"
                    value=""
                    label={"Date"}
                    placeholder={"Date"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="time_submitted"
                    name="time_submitted"
                    value=""
                    label={"Time"}
                    placeholder={"Time"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="jobsite"
                    name="jobsite"
                    value=""
                    label={"Jobsite"}
                    placeholder={"Jobsite"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="area"
                    name="area"
                    value=""
                    label={"Area (Building)"}
                    placeholder={"Area (Building)"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="unit"
                    name="unit"
                    value=""
                    label="Unit No"
                    placeholder="Unit No"
                    autoComplete="off"
                    disabled
                  />
                </Form>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {uploadedFiles.length > 0 && (
          <Card className="mb-30">
            <CardHeader className="border-0 bg-purple text-white fw-600">
              Images
            </CardHeader>
            <Card.Body>
              {fetchStatus === "fetching" ? (
                <div className="d-flex justify-content-center">
                  <ScalingLoader />
                </div>
              ) : (
                <div className="observation-img-uploader">
                  <div className="d-flex flex-wrap custom-select-wrapper">
                    <div className="d-block">
                      {/* {`* You can upload Maximum ${
                      6 - uploadedFiles.length - selectedFiles?.length
                    } image *`} */}
                      <div className="d-flex selected-image-wrapper">
                        {uploadedFiles?.map((image: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="selected-image d-flex align-items-center justify-content-center mt-0"
                              style={{
                                backgroundImage: `url(${image})`,
                              }}
                            ></div>
                          );
                        })}
                        {selectedFiles?.map((file: any) => {
                          let imageURL = URL.createObjectURL(file);
                          return (
                            <div
                              className="selected-image d-flex align-items-center justify-content-center"
                              style={{ backgroundImage: `url(${imageURL})` }}
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                    {/* <DragAndDropFileUpload
                    handleValidate={handleUploadValidate}
                    uploadFiles={handleUploadFiles}
                    totalItems={uploadedFiles.length + selectedFiles?.length}
                    maxAllows={6 - uploadedFiles.length - selectedFiles?.length}
                  /> */}
                  </div>
                  <div className="invalid-feedback d-block">{imgUploadErr}</div>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
        {Array.isArray(watch("feedbacks")) &&
          (watch("feedbacks")?.length ?? 0) > 0 && (
            <Card className="mb-30">
              <Card.Body>
                <div className="media">
                  <div className="flex-grow-1 card-title d-block media-right ms-0 mb-0">
                    <h2>Feedback</h2>
                    {watch("feedbacks")?.map((item) => {
                      return (
                        <div className="mt-3">
                          <p className="mb-0 comment-reply-date">
                            {moment(item?.created_at).format(
                              "DD/MM/YYYY, hh:mm A"
                            ) + ' - ' + item?.user_info?.full_name}
                          </p>
                          <p className="mb-0">{item?.comment}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Card.Body>
            </Card>
          )}
        <div className="submit-feedback">
          <h6>Write Answer</h6>
          <div className="feedback-field">
            <Textareabox
              control={control}
              type="textarea"
              id="write_answer"
              name="write_answer"
              value=""
              placeholder={"Write Answer..."}
              style={{ height: "130px", resize: "none" }}
              errorMessage={errors.write_answer && errors.write_answer?.message}
            />
          </div>
        </div>
        <div className="text-center mb-4">
          <Button
            type="submit"
            variant="primary"
            disabled={isPendingSubmitFeedback}
          >
            {isPendingSubmitFeedback && <SubmitLoader />} Save
          </Button>
        </div>
      </Form>

      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
    </>
  );
};

export default ConcernDetail;
