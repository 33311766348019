import React, { useEffect } from "react";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Textbox from "common/form/textBox";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import { CreateHoliday } from "utils/schema/holiday";
import { CreateHolidayAPI, EditHolidayAPI } from "services/holiday";
import moment from "moment";

const CreateHolidayModal: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  isCreateModal,
  data
}) => {

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    trigger,
    reset
  } = useForm({
    resolver: yupResolver(CreateHoliday),
  });
  console.log('errors---', errors)

  useEffect(() => {
    if(!isCreateModal){
      setValue('holiday_title', data?.holiday_name)
      setValue('holiday_date', data?.holiday_date)
    }
  }, [reset, setValue])

  const { isPending, mutate: CreateHolidayMutate } = useMutation({
    mutationFn: CreateHolidayAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });
  const { isPending:isPendingEdit, mutate: EditHolidayMutate } = useMutation({
    mutationFn: EditHolidayAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "edit");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("holiday_name", submitFormData?.holiday_title);
    formData.append("holiday_date", moment(submitFormData?.holiday_date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
      "YYYY-MM-DD"
    ));
    if(!isCreateModal && data?.id){
      formData.append("id", data?.id);
    }

    isCreateModal ? CreateHolidayMutate(formData) :
    EditHolidayMutate(formData)
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col md={12}>
              <Textbox
                control={control}
                type="text"
                id="holiday_title"
                name="holiday_title"
                placeholder={"Holiday Title"}
                autoComplete="off"
                max={100}
                errorMessage={errors.holiday_title && errors.holiday_title?.message}
              />
            </Col>
            <Col md={12}>
              <DatePickerReact
                startDate={watch("holiday_date")}
                control={control}
                id="holiday_date"
                name="holiday_date"
                value=""
                className="form control"
                placeholder="Start Date"
                autoComplete="off"
                errorMessage={errors.holiday_date && errors.holiday_date.message}
                onChange={(holiday_date) => {
                  if (holiday_date) {
                    setValue("holiday_date", holiday_date);
                    trigger("holiday_date");
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending || isPendingEdit}>
                {(isPending || isPendingEdit) && <SubmitLoader />}
                {isCreateModal ? 'Create' : 'Save'}
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default CreateHolidayModal;
