import { AlphabetOnlyRegex, EmailRegex, PhoneRegex, ZipCodeRegex } from "utils/constants/constant";
import * as Yup from "yup";

export const CreateClientSchema = Yup.object().shape(
  {
    company_title_id: Yup.object().required("Company must be selected"),
    // title: Yup.string().required("Title is required").matches(
    //   AlphabetOnlyRegex,
    //   "Please enter only alphabets"
    // ),
    first_name: Yup.string().required("First name is required").matches(
      AlphabetOnlyRegex,
      "Please enter only alphabets"
    ),
    last_name: Yup.string().required("Last name is required").matches(
      AlphabetOnlyRegex,
      "Please enter only alphabets"
    ),
    phone: Yup.string()
      .required("Primary number is required")
      .matches(PhoneRegex, "A valid Primary number is required"),
    email: Yup.string()
      .required("Email address is required")
      .matches(EmailRegex, "A valid email address is required")
      .email("A valid email address is required")
      .min(5, "A minimum of 2 characters is required")
      .max(100, "A maximum of 100 characters is allowed"),
    regional_manager_id: Yup.object().required(
      "Regional Manager must be selected"
    ),
    secondary_phone: Yup.string()
      .nullable()
      .when("secondary_phone", {
        is: (value: string) => value?.length,
        then: () =>
          Yup.string()
            .typeError("Secondary number is required")
            .matches(PhoneRegex, "A valid Secondary number is required"),
      }),

    state: Yup.object().required("State must be selected"),
    city: Yup.object().required("City must be selected"),
    address: Yup.string().trim().required("Address is required"),
    zipcode: Yup.string().required("Zipcode is required").matches(ZipCodeRegex, "A valid Zipcode is required"),
    position: Yup.object().required("Position must be selected"),
    jobsite: Yup.array()
      .required("Jobsite must be selected")
      .typeError("Jobsite must be selected")
      .min(1, "Select at least one jobsite"),
    // area: Yup.array()
    //   .required("Area must be selected")
    //   .min(1, "Select at least one Area"),
    // unit: Yup.number()
    //   .required("Unit is required")
    //   .typeError("Unit is required")
    //   .positive("Unit must be a positive number"),
    time_zone: Yup.object().required("Timezone must be selected"),
  },
  [["secondary_phone", "secondary_phone"]]
);
