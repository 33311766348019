import React, { useEffect, useRef, useState } from "react";
import FileInput from "common/form/fileInput";
import Textbox from "common/form/textBox";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Image, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import appImages from "utils/appImages";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetCityListAPI } from "services/common";
import { EditProfileSchema } from "utils/schema/profile";
import {
  GetSysAdminProfileDataAPI,
  UpdateProfileSysAdminAPI,
} from "services/profile";
import { saveProfileDataStorage } from "utils/helpers/localStorage";
import { GlobalState } from "context/globalContext";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import moment from "moment";
import { AllowRoles } from "utils/helpers/manageRole";
import { EMP_RM_ROLE } from "utils/constants/constant";

const EditSysAdminProfile: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  currentRow,
  callback,
  data,
}) => {
  const { setProfileData: setGlobalProfile } = GlobalState();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profileData, setProfileData] = useState<any>();
  const [imageURL, setImageURL] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isCustomError, setIsCustomError] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [cityData, setCityData] = useState<any[]>([]);
  const [errorMsg, setErrMsg] = useState<string>("Please choose profile image");

  const {
    control,

    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(EditProfileSchema),
  });

  useEffect(() => {
    if (data) {
      GetCityMutate(data?.state_id);
      setProfileData(data?.profile_image_url);
      setValue("profile_image", data?.profile_image_url);
      setValue("title", data?.title);
      setValue("first_name", data?.first_name);
      setValue("last_name", data?.last_name);
      setValue("email", data?.email);
      setValue("phone", data?.phone);
      setValue("time_zone", data?.time_zone_info);
      setValue("state", data?.state_info);
      setValue("city_id", data?.city_info);
      setValue("zipcode", data?.zipcode);
      setValue("address", data?.address);
      if (data?.employee_info?.birthdate) {
        setValue("birthdate", new Date(data?.employee_info?.birthdate));
      }
      if (data?.employee_info?.uniform_size_info) {
        setValue("uniform_size", data?.employee_info?.uniform_size_info);
      }
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    const allowedExtensions = ["png", "jpeg", "jpg", "svg"];

    const extension: any = file && file?.name?.split(".")?.pop()?.toLowerCase();

    if (!allowedExtensions.includes(extension)) {
      // Invalid file type
      if (fileInputRef.current !== null) {
        fileInputRef.current.value = "";
      }
      setImageURL("");
      setValue("profile_image", "");
      setErrMsg(
        "Unsupported file format. Please select a PNG, JPEG, JPG, or SVG file."
      );
      setIsCustomError(true);
      return;
    }

    file && setSelectedFile(file);
    setIsCustomError(false);
    setValue("profile_image", "");
    // Check if a file is selected
    if (file) {
      // Create an image element to get dimensions
      const img = new window.Image();
      img.onload = () => {
        // Check image width and height
        // if (img.width >= 5000 || img.height >= 5000) {
        //   if (fileInputRef.current !== null) {
        //     fileInputRef.current.value = "";
        //   }
        //   setImageURL("");
        //   setValue("profile_image", "");
        //   setIsCustomError(true);
        //   return;
        // } else {
        //   // Set the selected image in state or perform other actions
        //   let imageURL = URL.createObjectURL(file);
        //   setImageURL(imageURL);
        // }
        let imageURL = URL.createObjectURL(file);
        setImageURL(imageURL);
      };
      // Set the source of the image element to the selected file
      img.src = URL.createObjectURL(file);
    }
  };

  const { isPending: isPendingFormData } = useQuery({
    queryKey: ["profileFormData"],
    queryFn: async () => {
      try {
        const data = await GetSysAdminProfileDataAPI();
        setFormData(data.data.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: getCityPending, mutate: GetCityMutate } = useMutation({
    mutationFn: GetCityListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setCityData(data?.data?.result);
    },
  });

  const { isPending: isPendingUpdate, mutate: UpdateProfileMutate } =
    useMutation({
      mutationFn: UpdateProfileSysAdminAPI,
      onError: (error: any) => {
        if (typeof error?.data?.message === "string") {
          toast.error(error?.data?.message);
        } else {
          error?.data?.message?.forEach((item: any) => {
            Object.keys(item).forEach((fieldName: any) => {
              setError(fieldName, {
                type: "custom",
                message: item[fieldName][0],
              });
            });
          });
        }
      },
      onSuccess: (data) => {
        const result = data?.data?.result;
        saveProfileDataStorage(result);
        setGlobalProfile({
          profile_image_url: result?.profile_image_url,
          full_name: result?.full_name,
        });
        callback && callback();
        setShowSuccessModal && setShowSuccessModal(true, "update");
        handleClose();
      },
    });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    if (selectedFile && !isCustomError) {
      formData.append("profile_image", selectedFile);
    }
    formData.append("title", submitFormData?.title);
    formData.append("first_name", submitFormData?.first_name);
    formData.append("last_name", submitFormData?.last_name);
    formData.append("email", submitFormData?.email);
    formData.append("phone", submitFormData?.phone);
    formData.append("state_id", submitFormData?.state?.id);
    formData.append("city_id", submitFormData?.city_id?.id);
    formData.append("time_zone_id", submitFormData?.time_zone?.id);
    formData.append("zipcode", submitFormData?.zipcode);
    formData.append("address", submitFormData?.address);
    if (submitFormData?.birthdate) {
      formData.append(
        "birthdate",
        moment(submitFormData?.birthdate, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
          "YYYY-MM-DD"
        )
      );
    }
    if (submitFormData?.uniform_size) {
      formData.append("uniform_size_id", submitFormData?.uniform_size?.id);
    }
    data?.id && formData.append("id", data?.id);

    UpdateProfileMutate(formData);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FileInput
            control={control}
            type={"file"}
            name={"profile_image"}
            id={"profile_image"}
            className="d-none"
            onChange={handleInputChange}
            fileInputRef={fileInputRef}
            value={selectedFile}
          />
          <div className="user-profile-image text-center">
            <label
              htmlFor={"profile_image"}
              className="d-flex align-items-center justify-content-center m-auto overflow-hidden"
            >
              {!imageURL ? (
                <>
                  {profileData ? (
                    <>
                      <Image src={profileData} alt="" className="w-100" />
                    </>
                  ) : (
                    <>
                      <Image src={appImages.ProfileImage} alt="profile" />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Image src={imageURL} alt="" className="w-100" />
                </>
              )}
            </label>
            <span>Upload Profile</span>
            {isCustomError ? (
              <div className="invalid-feedback d-block">{errorMsg}</div>
            ) : (
              ""
            )}
          </div>
          <Row>
            <Col md={4}>
              <Textbox
                control={control}
                type="text"
                id="title"
                name="title"
                value=""
                placeholder={"Title"}
                autoComplete="off"
                max={50}
                errorMessage={errors.title && errors.title?.message}
              />
            </Col>
            <Col md={4}>
              <Textbox
                control={control}
                type="text"
                id="first_name"
                name="first_name"
                value=""
                placeholder={"First Name"}
                autoComplete="off"
                max={50}
                errorMessage={errors.first_name && errors.first_name?.message}
              />
            </Col>
            <Col md={4}>
              <Textbox
                control={control}
                type="text"
                id="last_name"
                name="last_name"
                value=""
                placeholder={"Last Name"}
                autoComplete="off"
                max={50}
                errorMessage={errors.last_name && errors.last_name?.message}
              />
            </Col>
            <Col md={4}>
              <Textbox
                control={control}
                type="text"
                id="email"
                name="email"
                value=""
                placeholder={"Email"}
                autoComplete="off"
                max={50}
                errorMessage={errors.email && errors.email?.message}
              />
            </Col>
            <Col md={4}>
              <Textbox
                control={control}
                type="number"
                id="phone"
                name="phone"
                value=""
                max={20}
                onKeyDown={(e: any) => inputNumberHandleKeyDown(e)}
                placeholder="mobile Number"
                autoComplete="off"
                errorMessage={errors.phone && errors.phone.message}
              />
            </Col>
            <Col md={4}>
              <Controller
                name="time_zone"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isDisabled={isPendingFormData}
                      {...field}
                      getOptionLabel={(item: any) => item.label}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.timezone ?? []}
                      isSearchable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Time Zone"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.time_zone && (
                      <div className="invalid-feedback d-block">
                        {errors.time_zone.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isDisabled={isPendingFormData}
                      {...field}
                      getOptionLabel={(item: any) => item.state_name}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.state ?? []}
                      isSearchable
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption);
                        if (selectedOption) {
                          GetCityMutate(selectedOption?.id);
                          setValue("city_id", {});
                        }
                      }}
                      placeholder={"State"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.state && (
                      <div className="invalid-feedback d-block">
                        {errors.state.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                name="city_id"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isLoading={getCityPending}
                      {...field}
                      getOptionLabel={(item: any) => item.city_name}
                      getOptionValue={(item: any) => item.id}
                      options={cityData}
                      isSearchable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"City"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.city_id && (
                      <div className="invalid-feedback d-block">
                        {errors.city_id.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={4}>
              <Textbox
                control={control}
                type="number"
                id="zipcode"
                name="zipcode"
                value=""
                onKeyDown={(e: any) => inputNumberHandleKeyDown(e)}
                placeholder={"Zip code"}
                autoComplete="off"
                max={20}
                errorMessage={errors.zipcode && errors.zipcode?.message}
              />
            </Col>
            {AllowRoles([EMP_RM_ROLE]) && (
              <Col md={4}>
                <DatePickerReact
                  startDate={watch("birthdate")}
                  control={control}
                  id="birthdate"
                  name="birthdate"
                  value=""
                  maxDate={moment().subtract(16, "years").toDate()}
                  className="form control"
                  placeholder="Birthdate"
                  autoComplete="off"
                  errorMessage={errors.birthdate && errors.birthdate.message}
                  onChange={(date) => {
                    if (date) {
                      setValue("birthdate", date);
                      trigger("birthdate");
                    }
                  }}
                />
              </Col>
            )}
            {AllowRoles([EMP_RM_ROLE]) && (
              <Col md={4}>
                <Controller
                  name="uniform_size"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isDisabled={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.uniform_size_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.uniformSizes ?? []}
                        isSearchable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Uniform Size"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.uniform_size && (
                        <div className="invalid-feedback d-block">
                          {errors.uniform_size.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
            )}
            <Col md={12}>
              <Textbox
                control={control}
                type="text"
                id="address"
                name="address"
                value=""
                placeholder={"Address"}
                autoComplete="off"
                max={250}
                errorMessage={errors.address && errors.address?.message}
              />
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPendingUpdate}>
                {isPendingUpdate && <SubmitLoader />} Update
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default EditSysAdminProfile;
