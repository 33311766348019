import { request } from "services/index";
import { AxiosResponse } from "axios";

export const CreateQrAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-qrcode",
    method: "POST",
    body,
  });
  return response;
};

export const EditQrAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-qrcode",
    method: "POST",
    body,
  });
  return response;
};

export const DeleteQrAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-qrcode",
    method: "POST",
    body,
  });
  return response;
};

export const GetQRAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/qrcode-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetQRDetailAPI = async (id: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/qrcode-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const QrStatusAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/manage-qrcode-status`,
    method: "POST",
    body
  });
  return response;
};

export const DownloadQrAPI = async (id: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/download-qrcode/${id}`,
    method: "GET",
  });
  return response;
};