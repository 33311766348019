import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import CommonModal from "common/modal/commonModal";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CreateAnnounceGroupAPI } from "services/announcement";
import { FormModalProps } from "types/modal";
import { CreateAnnounceGroupSchema } from "utils/schema/announcement";
import { SubmitLoader } from "common/loader/loader";

const CreateAnnounceGroup: React.FC<FormModalProps> = ({ title, show, handleClose, callbackState }) => {

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAnnounceGroupSchema),
  });

  const { isPending: pendingCreateNameMutate, mutate: CreateAnnounceNameMutate } =
    useMutation({
      mutationFn: CreateAnnounceGroupAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        callbackState && callbackState(data?.data?.result);
        handleClose();
      },
    });


  const onSubmit = (submitFormData: any) => {
    // callbackState && callbackState(submitFormData?.group_name)
    CreateAnnounceNameMutate({group_name: submitFormData?.group_name})
  };

  return (
    <>
    <CommonModal
      title={title}
      show={show}
      handleClose={handleClose}
      modalSize={"modal-default"}
    >
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Row>
          <Col md={12}>
            <Textbox
              control={control}
              type="text"
              id="group_name"
              name="group_name"
              value=""
              placeholder={"Group Name"}
              autoComplete="off"
              max={30}
              errorMessage={errors.group_name && errors.group_name?.message}
            />
          </Col>
        </Row>
        <Modal.Footer>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
              >
                {pendingCreateNameMutate && <SubmitLoader/>}
                Create
              </Button>
            </div>
          </Modal.Footer>
      </Form>
    </CommonModal>
    </>
  );
};

export default CreateAnnounceGroup;
