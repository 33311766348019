import { Container, Image } from "react-bootstrap";
import appImages from "utils/appImages";
import "common/404/404.scss";
import { Link } from "react-router-dom";


const FourZeroFour = () => {

  return (
    <>
      <Container>
        <div className="notfound-page w-100 d-flex align-items-center justify-content-center text-center">
          <Image src={appImages.notfoundImage} />
          <h1>Page Not Found</h1>
          <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
          <Link to={process.env.REACT_APP_HOME_URL+'/dashboard'} className="btn btn-sm btn-primary">Go To Homepage</Link>
        </div>
      </Container>
    </>
  );
};

export default FourZeroFour;
