import React, { useEffect, useState } from "react";
import Textbox from "common/form/textBox";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import Textareabox from "common/form/textAreaBox";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import DragAndDropFileUpload from "common/dragFileUpload/dragFileUpload";
import { CreateTaskSchema } from "utils/schema/task";
import ReactDatePicker from "react-datepicker";
import { FileError } from "react-dropzone";
import { CreateTaskAPI, GetTaskListAPI } from "services/task";
import { GetAreaListAPI, JobsiteDropdownListAPI } from "services/common";
import appImages from "utils/appImages";
import { CreateTaskProps } from "types/task";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";

const CreateTask: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  callbackState,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [imgUploadErr, setImgUploadErr] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
  } = useForm<CreateTaskProps>({
    resolver: yupResolver(CreateTaskSchema),
  });

  const { isPending: isPendingTaskList, mutate: GetTaskList } = useMutation({
    mutationFn: GetTaskListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, taskTitle: data?.data?.result });
    },
  });

  const { isPending: isPendingJobsiteList, mutate: GetJobsiteList } = useMutation({
    mutationFn: JobsiteDropdownListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, jobsiteList: data?.data?.result });
    },
  });

  const { isPending: isPendingAreaList, mutate: GetAreaList } = useMutation({
    mutationFn: GetAreaListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, areaList: data?.data?.result });
    },
  });

  useEffect(() => {
    const body = {
      pagination: 0,
    };
    GetTaskList(body);
    GetJobsiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isPending, mutate: CreateTaskMutate } = useMutation({
    mutationFn: CreateTaskAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data:any) => {
      callbackState && callbackState(data?.data?.result?.id);
      callback && callback();
      // setShowSuccessModal && setShowSuccessModal(true, "createTask");
      toast.success(data?.data?.message);
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("task_title_id", submitFormData?.task_name?.id);
    formData.append("jobsite_id", submitFormData?.jobsite?.id);
    if(submitFormData?.area?.id){
      formData.append("area_id", submitFormData?.area?.id);
    }
    formData.append(
      "start_date",
      moment(submitFormData?.start_date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append(
      "end_date",
      moment(submitFormData?.end_date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append("start_time", moment(submitFormData?.start_time).format("hh:mm A"));
    formData.append("end_time", moment(submitFormData?.end_time).format("hh:mm A"));
    if(submitFormData?.unit){
      formData.append("unit", submitFormData?.unit);
      }
    if(submitFormData?.description){
      formData.append("description", submitFormData?.description);
      }
    if(submitFormData?.rate){
      formData.append("rate", submitFormData?.rate);
    }
    selectedFiles.forEach((image) => {
      formData.append("task_images[]", image);
    });
    CreateTaskMutate(formData);
  };

  const handleUploadFiles = (files: any[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleUploadValidate = (file: File): FileError | FileError[] | null => {
    if (!file.type.startsWith("image/")) {
      setImgUploadErr("Only Support image Files only");
      return null;
    }
    setImgUploadErr("");
    return null;
  };

  const removeSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
      modalSize={"modal-default"}
        // modalSize={"modal-lg"}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col md={12}>
              <Controller
                name="task_name"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isLoading={isPendingTaskList}
                      {...field}
                      getOptionLabel={(item: any) => item.task_title}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.taskTitle ?? []}
                      isSearchable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Task Name"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.task_name && (
                      <div className="invalid-feedback d-block">
                        {errors.task_name.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <Controller
                name="jobsite"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isLoading={isPendingJobsiteList}
                      {...field}
                      getOptionLabel={(item: any) => item.jobsite_title}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.jobsiteList ?? []}
                      isSearchable
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption);
                        setValue("area", null);
                        if (selectedOption) {
                          GetAreaList({jobsite_id: [selectedOption?.id]});
                        }
                      }}
                      placeholder={"Jobsite"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.jobsite && (
                      <div className="invalid-feedback d-block">
                        {errors.jobsite.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                    isLoading={isPendingAreaList}
                      {...field}
                      getOptionLabel={(item: any) => item.area_name}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.areaList ?? []}
                      isSearchable
                      isClearable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Area (Building)"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.area && (
                      <div className="invalid-feedback d-block">
                        {errors.area.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={6}>
              <Textbox
                control={control}
                type="number"
                id="unit"
                name="unit"
                value=""
                onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                onPaste={(e) => e.preventDefault()}
                placeholder={"Unit"}
                autoComplete="off"
                max={10}
                errorMessage={errors.unit && errors.unit?.message}
              />
            </Col>
            <Col md={6}>
              <DatePickerReact
                startDate={watch("start_date")}
                control={control}
                id="start_date"
                minDate={new Date()}
                // holidays={[ { date: new Date(), holidayName: "India's Independence Day" }]}
                name="start_date"
                value=""
                className="form control"
                placeholder="Start Date"
                autoComplete="off"
                errorMessage={errors.start_date && errors.start_date.message}
                onChange={(date) => {
                    setValue("end_date", null as unknown as Date);
                    if (date) {
                    setValue("start_date", date);
                    trigger("start_date");
                  }
                }}
              />
            </Col>
            <Col md={6}>
              <DatePickerReact
                startDate={watch("end_date")}
                control={control}
                id="end_date"
                name="end_date"
                minDate={watch("start_date")}
                value=""
                className="form control"
                placeholder="End Date"
                autoComplete="off"
                errorMessage={errors.end_date && errors.end_date.message}
                onChange={(date) => {
                    setValue("end_time", null as unknown as Date);
                    if (date) {
                    setValue("end_date", date);
                    trigger("end_date");
                  }
                }}
              />
            </Col>
            <Col md={6}>
              <Controller
                name="start_time"
                control={control}
                render={({ field }) => (
                  <div className="form-group react-timepicker">
                    <ReactDatePicker
                      selected={watch("start_time")}
                      onChange={(start_time: any) => {
                        setValue("end_time", null as unknown as Date);
                        field.onChange(start_time);
                      }}
                      placeholderText="Start Time"
                      className="form-control"
                      autoComplete="off"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    {errors.start_time && (
                      <div className="invalid-feedback d-block">
                        {errors.start_time.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                name="end_time"
                control={control}
                render={({ field }) => (
                  <div className="form-group react-timepicker">
                    <ReactDatePicker
                      selected={watch("end_time")}
                      onChange={(end_time: any) => {
                        field.onChange(end_time);
                      }}
                      placeholderText="End Time"
                      minTime={(moment(watch('start_date')).format("YYYY-MM-DD")===moment(watch('end_date')).format("YYYY-MM-DD")) ? new Date(watch("start_time")) : moment().set({ hour: 0, minute: 5 }).toDate()}
                      maxTime={moment().set({ hour: 23, minute: 59 }).toDate()}
                      className="form-control"
                      autoComplete="off"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    {errors.end_time && (
                      <div className="invalid-feedback d-block">
                        {errors.end_time.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <Textbox
                control={control}
                type="number"
                id="rate"
                name="rate"
                value=""
                onPaste={(e) => e.preventDefault()}
                onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                placeholder={"Rate($)"}
                autoComplete="off"
                max={10}
                errorMessage={errors.rate && errors.rate?.message}
              />
              </Col>
            <Col md={12}>
              <Textareabox
                control={control}
                type="textarea"
                id="description"
                name="description"
                value=""
                placeholder={"Description"}
              />
            </Col>
            <Col md={12}>
              <div className="d-flex flex-column custom-select-wrapper mb-5">
                <div className="d-block">
                  {`* You can upload Maximum ${
                    6 - selectedFiles.length
                  } image *`}
                </div>
                <div className="d-flex selected-image-wrapper horizontal-scrollbar gap-2">
                  {selectedFiles?.map((file: any, index) => {
                    let imageURL = URL.createObjectURL(file);
                    return (
                      <div key={index} className="d-flex flex-column">
                      <div 
                        key={index}
                        className="selected-image d-flex align-items-center justify-content-center"
                        style={{ backgroundImage: `url(${imageURL})` }}
                      >
                         <div className="remove-image d-flex align-items-center justify-content-center cursor-pointer" onClick={() => removeSelectedImage(index)}>
                          <Image src={appImages.CloseImage} alt="More" width={10} />
                         </div>
                      </div>
                     
                      </div>
                    );
                  })}
                </div>
                <DragAndDropFileUpload
                  handleValidate={handleUploadValidate}
                  uploadFiles={handleUploadFiles}
                  totalItems={selectedFiles?.length}
                  maxAllows={6 - selectedFiles.length}
                />
                <div className="invalid-feedback d-block">
                  {imgUploadErr}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending}>
                {isPending && <SubmitLoader />}
                Create
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default CreateTask;
