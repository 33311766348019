import React from 'react'
import { Form} from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { TextareaBoxProps } from 'types/form';


const Textareabox: React.FC<TextareaBoxProps> = ({
	type = 'textarea',
	control,
	name = '',
	id = '',
	className = '',
	value = '',
	placeholder = '',
	minLength =undefined,
	maxLength =undefined,
	rows =undefined,
	cols =undefined,
	disabled = false,
	readonly = false,
	required = false,
	autoFocus = false,
	autoComplete = 'on',
	onChange,
	onPaste,
	onKeyDown,
	onBlur,
	label = '',
	errorMessage = '',
	isRequired=false,
	style={ height: '80px', resize: 'none' }
}) => {
	return (
		<>
			<Controller
				control={control}
				defaultValue={value}
				name={name}
				render={({ field }) => (
					<Form.Group className="form-group">
						{label && <Form.Label htmlFor={id}>{label}</Form.Label>}{ isRequired && <span className='required-text fw-bold'>*</span>}
						<Form.Control
							as="textarea"
							style={style}
							type={type}
							name={name}
							id={id}
							className={`${className} ${errorMessage ? '' : ''}`}
							value={field.value}
							placeholder={placeholder}
							minLength={minLength}
							maxLength={maxLength}
							rows={rows}
							cols={cols}
							disabled={disabled}
							readOnly={readonly}
							required={required}
							autoFocus={autoFocus}
							autoComplete={autoComplete}
							onChange={field.onChange}
							onPaste={onPaste}
							onKeyDown={onKeyDown}
							onBlur={onBlur}
						/>
				{errorMessage && (<div className="invalid-feedback d-block">{errorMessage}</div >)}
				</Form.Group>
				)}
			/>
		</>
	);
}

export default Textareabox;