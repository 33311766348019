import React from "react";
import { Navigate } from "react-router-dom";
import { getAccesToken } from "utils/helpers/localStorage";
// import { Navigate } from "react-router-dom";

interface componentType {
  component: React.FunctionComponent;
  permission?: string;
  setting?: boolean;
}

export const ProtectedRoute = ({
  component,
  permission,
  setting = false,
}: componentType) => {
  const token = getAccesToken();

  if (!token) {
    return (
      <>
        <Navigate to={{ pathname: "/signin" }} />
      </>
    );
  }

  const reactComponent = React.createElement(component);

  return <>{reactComponent}</>;
};
