import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Textareabox from "common/form/textAreaBox";
import Textbox from "common/form/textBox";
import JobSiteTable from "common/jobsiteTable/jobSiteTable";
import { ReactChart } from "common/reactChart/reactChart";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import { SortOrder, TableColumn } from "react-data-table-component";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import appImages from "utils/appImages";
import DatePicker from "react-datepicker";
import { PerPageOptions } from "utils/constants/constant";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetEmpWorkDetailListAPI,
  GetEmployeeDetailAPI,
} from "services/employee";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { ScalingLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";

const EmployeeDetail = () => {
  const location = useLocation();
  // react dataTable state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<any>({});
  const [empWorkList, setEmpWorkList] = useState<any>();
  // react dataTable state
  const [copied, setCopied] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowTaskId, setSelectedRowTaskId] = useState(null);
  const [showJobSite, setShowJobSite] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const { id } = useParams();
  const { setBreadCrumb } = GlobalState();
  const debouncedSearch = useDebouncedSearch(search, 600);

  const data = [
    ["Month", "Hours"],
    ["Jan", 0],
    // ["Fab", 11],
    // ["Mar", 25],
    // ["Apr", 80],
    // ["May", 40],
    // ["Jun", 55],
    // ["Jul", 74],
    // ["Aug", 62],
    // ["Sep", 34],
    // ["Oct", 56],
    // ["Nov", 20],
    // ["Dec", 63],
  ];
  const options = {
    chart: {
      // title: "Company Performance",
      // subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };

  const breadcrumbList = [
    {
      title: `${location?.pathname?.split('/').map((el) => el.includes('-') ? el.split('-') : el )?.flat(Infinity)?.includes('valet') ? 'Valet' : 'Employee'} Profile`,
      slug: `${location?.pathname?.split('/').map((el) => el.includes('-') ? el.split('-') : el )?.flat(Infinity)?.includes('valet') ? '/valet' : '/employee'}`,
    },
    {
      title: "Employee Profile Detail",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fetchStatus } = useQuery({
    queryKey: ["editEmployeeData"],
    queryFn: async () => {
      try {
        const data = id && (await GetEmployeeDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("id", result?.id);
          setValue("profile_image", result?.profile_image_url);
          setValue("title", result?.title ?? '-');
          setValue("full_name", result?.full_name);
          setValue("email", result?.email);
          setValue("phone", result?.phone);
          setValue(
            "regional_manager_id",
            result?.employee_info?.regional_manager_info?.full_name
          );
          setValue(
            "jobsite",
            result?.employee_info?.job_site_info
              ?.map((el: any) => el.jobsite_title)
              .join(", ")
          );
          setValue(
            "area",
            result?.employee_info?.area_info?.length>0 ?
            result?.employee_info?.area_info?.map((el: any) => el.area_name)
              .join(", ") : '-'
          );
          setValue("region", result?.employee_info?.region_info?.region_title);
          setValue("state", result?.state_info?.state_name);
          setValue("city", result?.city_info?.city_name);
          setValue("zipcode", result?.zipcode);
          setValue("address", result?.address);
          setValue(
            "birthdate",
            moment.utc(result?.employee_info?.birthdate).format("DD MMM YYYY")
          );
          setValue(
            "uniform_size",
            result?.employee_info?.uniform_size_info?.uniform_size_title
          );
          setValue("department_name", result?.employee_info?.department_name);
          setValue("time_zone", result?.time_zone_info.label);
          setValue("pay", result?.employee_info?.pay);
          setValue(
            "pay_type",
            result?.employee_info?.pay_type_info?.pay_type_title
          );
          setValue(
            "pay_cycle",
            result?.employee_info?.pay_cycle_info?.pay_cycle_title
          );
          setValue("date_of_hire", moment(result?.employee_info?.join_date).format("DD MMM, YYYY"));
          // setValue(
          //   "date_of_hire",
          //   moment
          //     .utc(result?.address?.employee_info?.join_date)
          //     .format("D MMM YYYY")
          // );
          setValue(
            "position",
            result?.employee_info?.position_info?.position_title
          );
          setValue("description", result?.employee_info?.description);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const getEmpWorkListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search_term: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
      user_id: id && id,
    };
    GetEmpWorkDetailListMutate(data);
  };

  useEffect(() => {
    getEmpWorkListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const {
    isPending: fetchEmpWorkDetailList,
    mutate: GetEmpWorkDetailListMutate,
  } = useMutation({
    mutationFn: GetEmpWorkDetailListAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setEmpWorkList(data.data.result);
      return data;
    },
  });

  const { control, setValue, watch } = useForm({});

  const handleDetailClick = (id: any, taskId: any) => {
    setSelectedRow(id);
    setSelectedRowTaskId(taskId);
    setShowJobSite(true);
  };

  const handleTaskStatus = (key: string) => {
    switch (key) {
      case "0":
        return "Pending";
      case "1":
        return "Assigned";
      case "2":
        return "Completed";
      case "3":
        return "Ongoing";
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row.id,
      width: "60px",
    },
    {
      id: 2,
      name: "Task",
      selector: (row: any) => row.task_title,
      sortField: "task_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Date",
      selector: (row: any) =>
        row.start_datetime
          ? `${moment(row.start_datetime).format("D MMM, YYYY")}`
          : "-",
      sortField: "start_datetime",
      sortable: true,
    },
    {
      id: 5,
      name: "Total Hours",
      selector: (row: any) => row.hours ?? "-",
    },
    {
      id: 6,
      name: "Official clock in/out",
      selector: (row: any) =>
        (row.punch_in ? `${moment(row.punch_in).format("hh:mm A")}` : "-") +
        " to " +
        (row.punch_out ? `${moment(row.punch_out).format("hh:mm A")}` : "-"),
    },
    {
      id: 7,
      name: "Jobsite",
      selector: (row: any) => row.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 8,
      name: "Jobsite Clock in/out time",
      selector: (row: any) =>
        (row.start_time ?? "-") + " to " + (row.end_time ?? "-"),
    },
    {
      id: 8,
      name: "Jobsite Punch in/out time",
      selector: (row: any) =>
        (row.jobsite_punch_in ?? "-") + " to " + (row.jobsite_punch_out ?? "-"),
    },
    {
      id: 9,
      name: "Status",
      cell: (row: any) => (
        <div
          className={
            handleTaskStatus(row?.task_status)?.toLocaleLowerCase() +
            " status-tooltip"
          }
        >
          {handleTaskStatus(row.task_status)}
        </div>
      ),
    },
    {
      id: 10,
      name: "More",
      cell: (row: any) => (
        <Image
          onClick={() => handleDetailClick(row?.jobsite_id, row?.id)}
          style={{ cursor: "pointer" }}
          src={appImages.RightDetailIcon}
          alt="detail"
          width={14}
          height={14}
        />
      ),
      width: "80px",
    },
  ];

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? sortDirection : undefined,
    });
  };

  return (
    <>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          Employee profile details
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="detail-form d-sm-flex d-block">
              <div className="detail-form-user mb-sm-0 mb-4">
                <Image src={watch("profile_image")} alt="" />
              </div>
              <div className="d-flex flex-column w-100">
                <Form>
                  <div className="position-relative form-group-icon">
                    <Textbox
                      control={control}
                      type="text"
                      id="id"
                      name="id"
                      value=""
                      label={"ID"}
                      placeholder={"ID"}
                      autoComplete="off"
                      disabled
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                    >
                      <Image
                        src={appImages.CopyIcon}
                        alt="copy"
                        className="cursor-pointer"
                        onClick={() => {
                          copy(watch("id"));
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      />
                    </OverlayTrigger>
                    {/* <Image className="cursor-pointer" src={appImages.CopyIcon} alt="copy" onClick={() => copy(watch("id"))} /> */}
                  </div>
                  <Textbox
                    control={control}
                    type="text"
                    id="full_name"
                    name="full_name"
                    value=""
                    label={"Employee Name"}
                    placeholder={"Employee Name"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="time_zone"
                    name="time_zone"
                    value=""
                    label={"Time Zone"}
                    placeholder={"Time Zone"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="title"
                    name="title"
                    value=""
                    label={"Title"}
                    placeholder={"Title"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="email"
                    name="email"
                    value=""
                    label={"Email"}
                    placeholder={"Email"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="number"
                    id="phone"
                    name="phone"
                    value=""
                    label={"Mobile Number"}
                    placeholder="Mobile Number"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="jobsite"
                    name="jobsite"
                    value=""
                    label="Jobsite"
                    placeholder="Jobsite"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="area"
                    name="area"
                    value=""
                    label="Area (Building)"
                    placeholder="Area"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="state"
                    name="state"
                    value=""
                    label="State"
                    placeholder="State"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="address"
                    name="address"
                    value=""
                    label="Address"
                    placeholder="Address"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="number"
                    id="zipcode"
                    name="zipcode"
                    value=""
                    label="Zipcode"
                    placeholder="Zipcode"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="city"
                    name="city"
                    value=""
                    label="City"
                    placeholder="City"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="birthdate"
                    name="birthdate"
                    value=""
                    label="Birthdate"
                    placeholder="Birthdate"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="region"
                    name="region"
                    value=""
                    label="Region"
                    placeholder="Region"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="uniform_size"
                    name="uniform_size"
                    value=""
                    label="Uniform Size"
                    placeholder="Uniform Size"
                    autoComplete="off"
                    disabled
                  />
                  {watch("department_name") && (
                    <Textbox
                      control={control}
                      type="text"
                      id="department_name"
                      name="department_name"
                      value=""
                      label="Department Name"
                      placeholder="Department Name"
                      autoComplete="off"
                      disabled
                    />
                  )}
                  <Textbox
                    control={control}
                    type="text"
                    id="pay"
                    name="pay"
                    value=""
                    label="Pay"
                    placeholder="Pay"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="pay_cycle"
                    name="pay_cycle"
                    value=""
                    label="Pay Cycle"
                    placeholder="Pay Cycle"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="date_of_hire"
                    name="date_of_hire"
                    value=""
                    label="Date of Hire"
                    placeholder="Date of Hire"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="position"
                    name="position"
                    value=""
                    label="Position"
                    placeholder="Position"
                    autoComplete="off"
                    disabled
                  />
                  {watch("regional_manager_id") && (
                    <Textbox
                      control={control}
                      type="text"
                      id="regional_manager_id"
                      name="regional_manager_id"
                      value=""
                      label="Regional Manager"
                      placeholder="Regional Manager"
                      autoComplete="off"
                      disabled
                    />
                  )}
                </Form>
                {watch("description") && (
                  <div className="description-column">
                    <Textareabox
                      control={control}
                      type="textarea"
                      id="description"
                      name="description"
                      value=""
                      label="Description"
                      placeholder={"Description"}
                      disabled
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-30">
        <CardBody>
          <Card.Title>
            <h2>Total Working Time</h2>
            <Form.Group className={`form-group`}>
              <DatePicker
                dateFormat={"MMM yyyy"}
                selected={fromDate}
                showMonthYearPicker
                placeholderText="Start Date"
                onChange={(date: any) => setFromDate(date)}
                className="form-control"
                dropdownMode="select"
              />
            </Form.Group>
          </Card.Title>
          <ReactChart chartType="Bar" data={data} options={options} />
        </CardBody>
      </Card>
      <div className="main-title d-md-flex d-block align-items-center justify-content-between">
        <h2>Employee work details</h2>
        <div className="d-flex align-items-center gap-3 mt-mb-0 mt-2 ">
          <Select
            isDisabled={false}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
              //   field.onChange(selectedOption);
              //   setSiteData([]);
              //   setValue("site_name", []);
              //   // handleAreaSelectChange(selectedOption);
              //   if (selectedOption?.length) {
              //     getSiteData({ area_id: selectedOption?.map(el => el.id) });
              //   }
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Textbox
            control={control}
            type="text"
            id="search"
            name="search"
            value=""
            placeholder={"Search"}
            autoComplete="off"
            onChange={(e: any) => {
              setCurrentPage(1);
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={empWorkList?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={empWorkList?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getEmpWorkListData(page + 1);
        }}
        inProgress={fetchEmpWorkDetailList}
        handleSort={handleSort}
      />
      {showJobSite && (
        <JobSiteTable
          show={showJobSite}
          title="Jobsite"
          handleClose={() => setShowJobSite(false)}
          id={selectedRow}
          taskId={selectedRowTaskId}
        />
      )}
    </>
  );
};

export default EmployeeDetail;
