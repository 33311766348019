import * as Yup from "yup";

export const AddAnnouncementSchema = Yup.object().shape({
    announce_title: Yup.string()
    .required("Announcement title is required"),
    announce_detail: Yup.string().required("Announcement Detail is required").min(2, "Minimum 2 Character is required"),
    group_wide: Yup.string().required("Please select any one option"),
    // jobsite: Yup.object().required("Jobsite is required").typeError("Jobsite is required"),
    jobsite: Yup.array()
      .nullable()
      .when('group_wide', {
        is: (value: string) => value === '1',
        then: () =>
          Yup.array().required("Jobsite is required")
        .typeError("Jobsite is required").min(1, "Select at least one jobsite")
      }),
      group: Yup.array()
      .nullable()
      .when('group_wide', {
        is: (value: string) => value === '2',
        then: () =>
          Yup.array().required("Group is required")
        .typeError("Group is required").min(1, "Select at least one Group")
      }),
      region: Yup.array()
      .nullable()
      .when('group_wide', {
        is: (value: string) => value === '4',
        then: () =>
          Yup.array().required("Region is required")
        .typeError("Region is required").min(1, "Select at least one Region")
      }),
  },
  [['jobsite', 'jobsite'], ['group', 'group'], ['region', 'region']]
);

export const CreateAnnounceGroupSchema = Yup.object().shape({
    group_name: Yup.string().trim()
    .required("Group Name is required")
  });