import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import appImages from "utils/appImages";
import "assets/scss/actionDropdown.scss";
import { Switch } from "common/form/switch";
import { DropdownProps } from "types/dropDown";

const ActionDropdown: React.FC<DropdownProps> = ({onToggleChange, isVisibles, actionClick, status, archiveText}) => {
  
  return (
    <>
      <Dropdown autoClose="outside" onToggle={onToggleChange}>
        <Dropdown.Toggle
          variant=""
          className="border-0 d-flex align-items-center px-0 py-1"
        >
          <Image src={appImages.EllipsIcon} alt="More" width={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isVisibles.includes("edit") && 
          <Dropdown.Item key="edit" onClick={() => actionClick && actionClick("edit")}>
            <Image src={appImages.EditIcon} alt="Edit" />
            Edit
          </Dropdown.Item>
          }
          {isVisibles.includes("archive") && 
          <Dropdown.Item key="archive" onClick={() => actionClick && actionClick("archive")}>
            <Image src={appImages.ArchiveIcon} alt="Archive" />
            {archiveText ?? 'Archive'}
          </Dropdown.Item>
          }
          {isVisibles.includes("delete") && 
          <Dropdown.Item key="delete" onClick={() => actionClick && actionClick("delete")}>
            <Image src={appImages.DeleteIcon} alt="Delete" />
            Delete
          </Dropdown.Item>
          }
          {isVisibles.includes("approve") && 
          <Dropdown.Item key="approve" onClick={() => actionClick && actionClick("approve")}>
            <Image src={appImages.RightSignIcon} alt="Approve" />
            Approve
          </Dropdown.Item>
          }
          {isVisibles.includes("active") && 
          <Dropdown.Item key="active">
            <Switch checked={status} handleToggleSwitch={(status:boolean) => actionClick && actionClick("active", status)} /> Active
          </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
export default ActionDropdown;
