import { useMutation, useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import { ScalingLoader, SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, CardHeader, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import SuccessModal from "common/modal/successModal/successModal";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";
import { CreateJobsiteAPI, EditJobsiteAPI, GetJobsiteFormDataAPI, GetJobsiteJobsiteDetailAPI } from "services/jobsite";
import { CreateJobsiteProps } from "types/jobsite";
import { CreateJobsiteSchema } from "utils/schema/jobsite";
import ReactGoogleMapComponent from "common/googleMap/googleMap";
import { useNavigate, useParams } from "react-router-dom";

const CreateJobsite = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const { setBreadCrumb } = GlobalState();
  const [formData, setFormData] = useState<any>(null);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [curIndexChange, setCurIndexChange] = useState(0);
  const [polygonData, setPolygonData] = useState<any[]>([]);
  const [jobsitePolygonData, setJobsitePolygonData] = useState<any[]>([]);
  const [activePolygonData, setActivePolygonData] = useState<any[]>([]);
  const [markerData, setMarkerData] = useState<any>(null);
  const [customeMapErr, setCustomeMapErr] = useState<any>(null);

  // console.log('polygonData--', polygonData)
  // console.log('markerData--', markerData)

  const breadcrumbList = [
    {
      title: "Jobsite",
      slug: "/jobsite",
    },
    {
      title: `${!!id ? 'Edit' : "Create"} Jobsite`,
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateJobsiteProps>({
    resolver: yupResolver(CreateJobsiteSchema),
  });

  const { isPending, mutate: CreateJobsiteMutate } = useMutation({
    mutationFn: CreateJobsiteAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data: any) => {
      toast.success(data?.data?.message);
      navigate('/jobsite');
    },
  });

  const { isPending: isPendingEdit, mutate: EditJobsiteMutate } = useMutation({
    mutationFn: EditJobsiteAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data: any) => {
      toast.success(data?.data?.message);
      navigate('/jobsite');
    },
  });

  const { fetchStatus } = useQuery({
    queryKey: ["jobsiteDetail"],
    enabled: !!id,
    queryFn: async () => {
      try {
        const data =
          id && (await GetJobsiteJobsiteDetailAPI({id: id}));
        const result = data && data?.data?.result;
        if (result) {
          const polygonData = result?.jobsite_polygon_info?.map((item:any) => {
            return(
              {'lat': +item?.latitude, 'lng': +item?.longitude}
            )
          })
          setValue("jobsite_name", result?.jobsite_title);
          setValue("region", result?.region_info);
          setValue("client", result?.client_info);
          setValue("builder_name", result?.building_owner);
          setValue("address", result?.address);
          setValue("total_unit", result?.total_unit);
          setMarkerData({lat: +result?.latitude, lng: +result?.longitude});
          setActivePolygonData(polygonData);
          setPolygonData(polygonData);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: isPendingFormData } = useQuery({
    queryKey: ["jobsiteFormData"],
    queryFn: async () => {
      try {
        const data = await GetJobsiteFormDataAPI();
        setFormData(data.data.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const onSubmit = (submitFormData: any, e: any) => {
    e.preventDefault();
    if (!polygonData && !markerData) {
      return setCustomeMapErr("Please draw polygon and marker on map");
    } else if (!polygonData) {
      return setCustomeMapErr("Please draw polygon on map");
    } else if (polygonData?.length<3) {
      return setCustomeMapErr("Please draw valid polygon with minimum 3 points");
    } else if (!markerData) {
      return setCustomeMapErr("Please draw marker on map");
    } else {
      setCustomeMapErr(null);
    }
    const polygon = polygonData.map((item: any) => {
      return (
        {latitude: item?.lat.toString(), longitude: item?.lng.toString()}
      )
    })
    const body = {
      id: id,
      jobsite_title: submitFormData?.jobsite_name,
      region_id: submitFormData?.region?.id,
      client_id: submitFormData?.client?.id,
      building_owner: submitFormData?.builder_name,
      address: submitFormData?.address,
      total_unit: submitFormData?.total_unit,
      latitude: markerData?.lat.toString(),
      longitude: markerData?.lng.toString(),
      points: polygon,
    };
    
    if (polygonData && markerData) {
      if(!!id) {
        EditJobsiteMutate(body);
      } else {
        CreateJobsiteMutate(body);
      }
    }
  };

  const handlePolygonData = (data: any[]) => {
    setPolygonData(data);
  };
  const handleMarkerData = (data: any) => {
    setMarkerData(data);
  };
  const handleAddressData = (data: any) => {
    setValue('address', data);
    trigger("address");
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Card className="mb-30">
          <CardHeader className="border-0 bg-purple text-white fw-600">
            Add Jobsite
          </CardHeader>
          <Card.Body>
            {fetchStatus==='fetching' ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : (
              <>
                <div className="detail-form d-flex gap-2">
                  <Row>
                    <Col md={4}>
                      <Textbox
                        control={control}
                        type="text"
                        id="jobsite_name"
                        name="jobsite_name"
                        value=""
                        placeholder={"Jobsite Name"}
                        autoComplete="off"
                        max={50}
                        errorMessage={
                          errors.jobsite_name && errors.jobsite_name?.message
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Controller
                        name="region"
                        control={control}
                        render={({ field }) => (
                          <div className="form-group">
                            <Select
                              isLoading={isPendingFormData}
                              {...field}
                              getOptionLabel={(item: any) => item.region_title}
                              getOptionValue={(item: any) => item.id}
                              options={formData?.region ?? []}
                              isSearchable
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              placeholder={"Select Region"}
                              classNamePrefix="react-select"
                              className="react-select-container"
                              menuPlacement="bottom"
                            />
                            {errors.region && (
                              <div className="invalid-feedback d-block">
                                {errors.region.message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <Controller
                        name="client"
                        control={control}
                        render={({ field }) => (
                          <div className="form-group">
                            <Select
                              isLoading={isPendingFormData}
                              {...field}
                              getOptionLabel={(item: any) => item.full_name}
                              getOptionValue={(item: any) => item.id}
                              options={formData?.clients ?? []}
                              isSearchable
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              placeholder={"Select Client"}
                              classNamePrefix="react-select"
                              className="react-select-container"
                              menuPlacement="bottom"
                            />
                            {errors.client && (
                              <div className="invalid-feedback d-block">
                                {errors.client.message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <Textbox
                        control={control}
                        type="text"
                        id="builder_name"
                        name="builder_name"
                        value=""
                        placeholder={"Builder Name"}
                        autoComplete="off"
                        max={100}
                        errorMessage={
                          errors.builder_name && errors.builder_name?.message
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Textbox
                        control={control}
                        type="text"
                        id="address"
                        name="address"
                        value=""
                        placeholder={"Address"}
                        autoComplete="off"
                        max={250}
                        errorMessage={errors.address && errors.address?.message}
                      />
                    </Col>
                    <Col md={4}>
                      <Textbox
                        control={control}
                        type="number"
                        id="total_unit"
                        name="total_unit"
                        value=""
                        onPaste={(e) => e.preventDefault()}
                        onKeyDown={(e: any) => inputNumberHandleKeyDown(e)}
                        placeholder={"Total Unit"}
                        autoComplete="off"
                        max={10}
                        errorMessage={
                          errors.total_unit && errors.total_unit?.message
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Textbox
                        control={control}
                        disabled={true}
                        type="number"
                        id="latitude"
                        name="latitude"
                        value={markerData?.lat}
                        placeholder={"Latitude"}
                        autoComplete="off"
                      />
                    </Col>
                    <Col md={4}>
                      <Textbox
                        control={control}
                        disabled={true}
                        type="number"
                        id="longitude"
                        name="longitude"
                        value={markerData?.lng}
                        placeholder={"Longitude"}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="google-map">
                  <div className="action-btn mb-1">
                    <Button
                      variant="outline-primary"
                      onClick={() => setCurIndexChange(curIndexChange + 1)}
                    >
                      Clear Polygon
                    </Button>
                    {customeMapErr && (
                    <div className="invalid-feedback d-block fs-6">
                      {customeMapErr}
                    </div>
                  )}
                  </div>
                  <ReactGoogleMapComponent
                    resetPolygon={curIndexChange}
                    handlePolygonData={handlePolygonData}
                    handleAddressData={handleAddressData}
                    handleMarkerData={handleMarkerData}
                    polygonData={jobsitePolygonData}
                    activePolygonData={activePolygonData}
                    markerData={markerData}
                  />
                </div>
              </>
            )}
          </Card.Body>
        </Card>
        <div className="text-center mb-4">
          <Button type="submit" variant="primary" disabled={isPending || isPendingEdit}>
            {(isPending||isPendingEdit) && <SubmitLoader />}
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateJobsite;
