import { AlphabetOnlyRegex, EmailRegex, PhoneRegex, RestrictSpaceRegex, ZipCodeRegex } from "utils/constants/constant";
import * as Yup from "yup";

export const EditProfileSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required").matches(
    AlphabetOnlyRegex,
    "Please enter only alphabets"
  ),
  profile_image: Yup.string().nullable(),
  first_name: Yup.string().trim().required("First name is required").matches(
    AlphabetOnlyRegex,
    "Please enter only alphabets"
  ),
  last_name: Yup.string().trim().required("Last name is required").matches(
    AlphabetOnlyRegex,
    "Please enter only alphabets"
  ),
  phone: Yup.string()
    .required("Mobile number is required")
    .matches(PhoneRegex, "A valid Mobile number is required"),
  email: Yup.string()
    .required("Email address is required")
    .matches(EmailRegex, "A valid email address is required")
    .email("A valid email address is required")
    .min(5, "A minimum of 2 characters is required")
    .max(100, "A maximum of 100 characters is allowed"),
  state: Yup.object().required("State must be selected"),
  city_id: Yup.object().required("City must be selected"),
  zipcode: Yup.string().required("Zipcode is required").matches(ZipCodeRegex, "A valid Zipcode is required"),
  address: Yup.string().trim().required("Address is required"),
  time_zone: Yup.object().required("Timezone must be selected"),
  birthdate: Yup.date().nullable(),
  uniform_size: Yup.object().nullable(),
});

export const ChangePasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .required("Current Passsword is required")
    .password()
    .minLowercase(
      1,
      "Current Password must contain at least 1 lowercase letter"
    )
    .minUppercase(
      1,
      "Current Password must contain at least 1 uppercase letter"
    )
    .minNumbers(1, "Current Password must contain at least 1 number")
    .minSymbols(1, "Current Password must contain at least 1 symbol")
    .min(8, "Current Password must be at least 8 characters long")
    .max(64, "Current Password cannot be longer than 64 characters"),

  new_password: Yup.string()
    .required("New Passsword is required")
    .matches(RestrictSpaceRegex, "New Password cannot contain spaces")
    .password()
    .minLowercase(
      1,
      "New Password must contain at least 1 lowercase letter"
    )
    .minUppercase(
      1,
      "New Password must contain at least 1 uppercase letter"
    )
    .minNumbers(1, "New Password must contain at least 1 number")
    .minSymbols(1, "New Password must contain at least 1 symbol")
    .min(8, "New Password must be at least 8 characters long")
    .max(64, "New Password cannot be longer than 64 characters")
    .test(
      "passwords-match",
      "Passwords does not same as current password",
      function (value) {
        return this.parent.current_password !== value;
      }
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .test(
      "passwords-match",
      "Confirm password should match with New password",
      function (value) {
        return this.parent.new_password === value;
      }
    ),
});

export const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("New Passsword is required")
    .matches(RestrictSpaceRegex, "New Password cannot contain spaces")
    .password()
    .minLowercase(
      1,
      "New Password must contain at least 1 lowercase letter"
    )
    .minUppercase(
      1,
      "New Password must contain at least 1 uppercase letter"
    )
    .minNumbers(1, "New Password must contain at least 1 number")
    .minSymbols(1, "New Password must contain at least 1 symbol")
    .min(8, "New Password must be at least 8 characters long")
    .max(64, "New Password cannot be longer than 64 characters")
    .test(
      "passwords-match",
      "Passwords does not same as current password",
      function (value) {
        return this.parent.current_password !== value;
      }
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .test(
      "passwords-match",
      "Confirm password should match with New password",
      function (value) {
        return this.parent.new_password === value;
      }
    ),
});
