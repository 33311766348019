import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { RadioToggleButtonProps } from "types/form";

const RadioButton: React.FC<RadioToggleButtonProps> = ({
  control,
  type = "radio",
  id = "",
  name = "",
  className = "",
  options = [],
  label = "",
  value='',
  errorMessage = "",
  isRequired = false,
  onRadioChange,
  disabled,
}) => {
  return (
    <>
      <Controller
        control={control}
        defaultValue={value}
        name={name}
        render={({ field: { onChange, value, name } }) => (
          <Form.Group className="form-group">
            {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
            {isRequired && <span className="required-text fw-bold">*</span>}

            <div
              key={`inline-${type}`}
              className={`form-checkbox ${className} d-flex gap-3`}
            >
              {options.map((item:any, index:any) => {
                return (
                  <Form.Check
                    disabled={disabled}
                    key={index}
                    id={`radio-button-${index}-${id}`}
                    type="radio"
                    name={name}
                    label={item.label}
                    value={item.value}
                    checked={value === item.value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      onRadioChange && onRadioChange(e);
                    }}
                    aria-label={`radio-button-${index}-${id}`}
                  />
                );
              })}
            </div>

            {errorMessage && (
              <div className="invalid-feedback d-block">{errorMessage}</div>
            )}
          </Form.Group>
        )}
      />
    </>
  );
};

export default RadioButton;
