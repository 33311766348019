import { request } from "services/index";
import { AxiosResponse } from "axios";

// Observation Title API'S
export const GetTitleDashboardAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/manage-title-count",
    method: "GET",
  });
  return response;
};
export const GetObservationTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/observation-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const CreateObservationTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-observation-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditObservationTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-observation-title",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteObservationTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-observation-title",
    method: "POST",
    body,
  });
  return response;
};

// Task Title API'S
export const GetTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/task-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-task-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreateTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-task-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-task-title",
    method: "POST",
    body,
  });
  return response;
};


// Instant Task Title API'S
export const GetInstantTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/instant-task-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteInstantTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-instant-task-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreateInstantTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-instant-task-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditInstantTaskTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-instant-task-title",
    method: "POST",
    body,
  });
  return response;
};


// Supplies Title API'S
export const GetClientPositionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/client-position-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteClientPositionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-client-position-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreateClientPositionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-client-position-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditClientPositionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-client-position-title",
    method: "POST",
    body,
  });
  return response;
};


// Concern Title API'S
export const GetConcernTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/concern-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteConcernTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-concern-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreateConcernTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-concern-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditConcernTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-concern-title",
    method: "POST",
    body,
  });
  return response;
};


// Concern Title API'S
export const GetCompanyTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/company-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteCompanyTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-company-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreateCompanyTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-company-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditCompanyTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-company-title",
    method: "POST",
    body,
  });
  return response;
};


// Region Title API'S
export const GetRegionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/region-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeleteRegionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-region-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreateRegionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-region-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditRegionTitleAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-region-title",
    method: "POST",
    body,
  });
  return response;
};


// Position Name API'S
export const GetPositionAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/position-title-list",
    method: "POST",
    body,
  });
  return response;
};
export const DeletePositionAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-position-title",
    method: "POST",
    body,
  });
  return response;
};
export const CreatePositionAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-position-title",
    method: "POST",
    body,
  });
  return response;
};
export const EditPositionAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-position-title",
    method: "POST",
    body,
  });
  return response;
};
export const PositionRoleListAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/position-role-list",
    method: "GET",
  });
  return response;
};