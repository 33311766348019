import CommonModal from "common/modal/commonModal";
import React from "react";
import { Image, Modal } from "react-bootstrap";
import { ModalProps } from "types/modal";

const ImagePreviewModal: React.FC<ModalProps> = ({
  title,
  show,
  handleClose,
  data
}) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`modal-md`}
    >
      <Modal.Header className="bg-purple border-0 d-flex align-items-center justify-content-between ">
        <h2 className="mb-0 text-white fw-600"> Image Preview</h2>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="image-preview">
          <Image src={data?.img_url} alt='' />
        </div>
      </Modal.Body>
    </Modal>

  );
};

export default ImagePreviewModal;
