import CryptoJS from 'crypto-js';
import { ROLE_SECRET_KEY, USER_DATA_SECRET_KEY } from 'utils/constants/constant';

export const getLoginData = () => {
    try {
      const loginData = localStorage.getItem("_login");
      if (loginData) {
        if(JSON.parse(loginData).remember_me === true){
          return JSON.parse(loginData);
        }else {
          return null; 
        }
      }
      return "";
    } catch (error) {
      console.error("Error loading data from localStorage:", error);
      return undefined;
    }
  };

  export const getAccesToken = () => {
    try {
      const token = localStorage.getItem("access_token");
      return token;
    } catch (error) {
      console.error(`Error get token from localStorage: ${error}`);
    }
  };

  export const saveTokenToLocalStorage = (token: string) => {
    try {
      if(token) {
        localStorage.setItem("access_token", token);
      }
      // if (typeof window !== "undefined") {
      // }
    } catch (error) {
      console.error(`Error saving token to localStorage: ${error}`);
    }
  };

  export const saveProfileDataStorage = (data: any) => {
    try {
      if(data) {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), USER_DATA_SECRET_KEY).toString();
        // localStorage.setItem(name, encrypted);
        localStorage.setItem("profile_data", encrypted);
      }
    } catch (error) {
      console.error(`Error saving profileData to localStorage: ${error}`);
    }
  };

  export const getProfileData = () => {
    try {
      const encProfileData: any = localStorage.getItem("profile_data");
      const decrypted = CryptoJS.AES.decrypt(encProfileData, USER_DATA_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    if(decrypted){
        return JSON.parse(decrypted);
      }else {
        return null;
      }
    } catch (error) {
      console.error(`Error get profileData from localStorage: ${error}`);
    }
  };

  export const setUserRoleToStorage = (data: any) => {
    try {
      if(data) {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), ROLE_SECRET_KEY).toString();
        // localStorage.setItem(name, encrypted);
        localStorage.setItem("user_key", encrypted);
      }
    } catch (error) {
      console.error(`Error saving userRole to localStorage: ${error}`);
    }
  };

  export const getUserRole = () => {
    try {
      const encRole: any = localStorage.getItem("user_key");
      const decrypted = CryptoJS.AES.decrypt(encRole, ROLE_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    if(decrypted){
        return JSON.parse(decrypted);
      }else {
        return null;
      }
    } catch (error) {
      console.error(`Error get userRole from localStorage: ${error}`);
    }
  };

  export const handleLogout = () => {
    try {
      localStorage.removeItem("profile_data");
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_key");
    } catch (error) {
      console.error(`Error remove localStorage data: ${error}`);
    }
  };