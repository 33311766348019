import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "global.scss";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import AppRoutes from "routes";
import { GlobalContextProvider } from "context/globalContext";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      // staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});

function App() {
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <GlobalContextProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              // pauseOnHover
              // theme="colored"
              transition={Slide}
              style={{ zIndex: "999999" }}
            />
            <AppRoutes />
          </GlobalContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
