import React, { useEffect, useState } from "react";
import Textbox from "common/form/textBox";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Textareabox from "common/form/textAreaBox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import DragAndDropFileUpload from "common/dragFileUpload/dragFileUpload";
import { FileError } from "react-dropzone";
import { CreateInstantTaskAPI } from "services/task";
import { useParams } from "react-router-dom";
import { CreateDocumentSchema } from "utils/schema/document";
import appImages from "utils/appImages";
import { GetAreaListAPI, JobsiteDropdownListAPI } from "services/common";
import ReactSelect from "react-select";
import { CreateDocumentProps } from "types/document";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { CreateDocumentAPI, EditDocumentAPI, GetDocumentDetailAPI } from "services/document";

const CreateDocument: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  data,
  isCreateModal,
  currentRow,
}) => {
  const [uploadedImgs, setUploadedImgs] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [imgUploadErr, setImgUploadErr] = useState<string | null>(null);
  const [formData, setFormData] = useState<any>({});

  const defaultValue = {
    date: new Date(),
    time: new Date()
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    trigger,
  } = useForm<CreateDocumentProps>({
    defaultValues: defaultValue,
    resolver: yupResolver(CreateDocumentSchema),
  });

  const { fetchStatus } = useQuery({
    queryKey: ["documentDetail"],
    enabled: !isCreateModal,
    queryFn: async () => {
      try {
        const data =
          currentRow && (await GetDocumentDetailAPI(currentRow.toString()));
        const result = data && data?.data?.result;
        if (result) {
          GetAreaList({ jobsite_id: [result?.jobsite_id] });
          setUploadedImgs(
            result?.document_images?.map(
              (item: any) => item?.document_image_url
            )
          );
          setValue("document_title", result?.document_title);
          setValue("jobsite", result?.jobsite);
          setValue("area", result?.area);
          setValue("date", new Date(result?.date));
          setValue("time", moment(result?.time, "hh:mm A").toDate());
          setValue("description", result?.description);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: isPendingJobsiteList, mutate: GetJobsiteList } =
    useMutation({
      mutationFn: JobsiteDropdownListAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setFormData({ jobsiteList: data.data.result });
      },
    });

  const { isPending: isPendingAreaList, mutate: GetAreaList } = useMutation({
    mutationFn: GetAreaListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, areaList: data.data.result });
    },
  });

  useEffect(() => {
    GetJobsiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isPending, mutate: CreateDocumentMutate } = useMutation({
    mutationFn: CreateDocumentAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });
  const { isPending: isPendingUpdate, mutate: UpdateDocumentMutate } =
    useMutation({
      mutationFn: EditDocumentAPI,
      onError: (error: any) => {
        if (typeof error?.data?.message === "string") {
          toast.error(error?.data?.message);
        } else {
          error?.data?.message?.forEach((item: any) => {
            Object.keys(item).forEach((fieldName: any) => {
              setError(fieldName, {
                type: "custom",
                message: item[fieldName][0],
              });
            });
          });
        }
      },
      onSuccess: (data) => {
        callback && callback();
        setShowSuccessModal && setShowSuccessModal(true, "update");
        handleClose();
      },
    });

  const onSubmit = (submitFormData: any) => {
    if (isCreateModal && selectedFiles.length < 1) {
      return setImgUploadErr("Please Select Images");
    } else {
      setImgUploadErr(null);
    }
    const formData = new FormData();
    formData.append("document_title", submitFormData?.document_title);
    formData.append("jobsite_id", submitFormData?.jobsite?.id);
    if(submitFormData?.area) {
      formData.append("area_id", submitFormData?.area?.id);
    }
    formData.append(
      "date",
      moment(submitFormData?.date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append("time", moment(submitFormData?.time).format("hh:mm A"));
    formData.append("description", submitFormData?.description);

    if (selectedFiles && Array.isArray(selectedFiles)) {
      selectedFiles.forEach((image) => {
        formData.append("documents[]", image);
      });
    }
    if (!isCreateModal && currentRow) {
      formData.append("id", currentRow.toString());
    }

    if (isCreateModal && selectedFiles.length > 0) {
      CreateDocumentMutate(formData);
    } else if(!isCreateModal) {
      UpdateDocumentMutate(formData);
    }
  };

  const handleUploadFiles = (files: any[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleUploadValidate = (file: File): FileError | FileError[] | null => {
    if (!file.type.startsWith("image/")) {
      setImgUploadErr("Only Support image Files only");
      return null;
    }
    setImgUploadErr(null);
    return null;
  };

  const removeSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        {!isCreateModal && fetchStatus === "fetching" ? (
          <SpinerLoader />
        ) : (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col md={12}>
              <Textbox
                control={control}
                type="text"
                id="document_title"
                name="document_title"
                value=""
                placeholder={"Document Title"}
                autoComplete="off"
                max={50}
                errorMessage={
                  errors.document_title && errors.document_title?.message
                }
              />
            </Col>
            <Col md={12}>
              <Controller
                name="jobsite"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <ReactSelect
                      isLoading={isPendingJobsiteList}
                      {...field}
                      getOptionLabel={(item: any) => item.jobsite_title}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.jobsiteList ?? []}
                      isSearchable
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption);
                        setValue("area", null);
                        if (selectedOption) {
                          GetAreaList({ jobsite_id: [selectedOption?.id] });
                        }
                      }}
                      placeholder={"Jobsite"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.jobsite && (
                      <div className="invalid-feedback d-block">
                        {errors.jobsite.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <ReactSelect
                      isLoading={isPendingAreaList}
                      {...field}
                      getOptionLabel={(item: any) => item.area_name}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.areaList ?? []}
                      isSearchable
                      isClearable
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Area (Building)"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <DatePickerReact
                startDate={watch("date")}
                disabled={true}
                control={control}
                id="date"
                name="date"
                value=""
                className="form control"
                placeholder="Date"
                autoComplete="off"
                errorMessage={errors.date && errors.date.message}
                onChange={(date) => {
                  if (date) {
                    setValue("date", date);
                    trigger("date");
                    }
                    }}
                    />
            </Col>
            <Col md={12}>
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <div className="form-group react-timepicker">
                    <ReactDatePicker
                      selected={watch("time")}
                      disabled={true}
                      onChange={(time: any) => {
                        field.onChange(time);
                      }}
                      placeholderText="Time"
                      className="form-control"
                      autoComplete="off"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    {errors.time && (
                      <div className="invalid-feedback d-block">
                        {errors.time.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <Textareabox
                control={control}
                type="textarea"
                id="description"
                name="description"
                value=""
                placeholder={"Description"}
                errorMessage={errors.description && errors.description?.message}
              />
            </Col>
            <Col md={12}>
              <div className="d-flex flex-column custom-select-wrapper mb-5">
                <div className="d-block">
                  {`* You can upload Maximum ${
                    6 - selectedFiles.length - uploadedImgs.length
                  } image *`}
                </div>
                <div className="d-flex selected-image-wrapper horizontal-scrollbar gap-2">
                  {uploadedImgs.length > 0 &&
                    uploadedImgs.map((img: any, index: number) => {
                      return (
                        <div key={index} className="d-flex flex-column">
                          <div
                            key={index}
                            className="selected-image d-flex align-items-center justify-content-center"
                            style={{ backgroundImage: `url(${img})` }}
                          ></div>
                        </div>
                      );
                    })}
                  {selectedFiles?.map((file: any, index) => {
                    let imageURL = URL.createObjectURL(file);
                    return (
                      <div className="d-flex flex-column">
                        <div
                          key={index}
                          className="selected-image d-flex align-items-center justify-content-center"
                          style={{ backgroundImage: `url(${imageURL})` }}
                        >
                          <div
                            className="remove-image d-flex align-items-center justify-content-center cursor-pointer"
                            onClick={() => removeSelectedImage(index)}
                          >
                            <Image
                              src={appImages.CloseImage}
                              alt="More"
                              width={10}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <DragAndDropFileUpload
                  handleValidate={handleUploadValidate}
                  uploadFiles={handleUploadFiles}
                  totalItems={selectedFiles?.length + uploadedImgs?.length}
                  maxAllows={6 - selectedFiles.length - uploadedImgs?.length}
                />
                <div className="invalid-feedback d-block">{imgUploadErr}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending || isPendingUpdate}>
                {(isPending || isPendingUpdate) && <SubmitLoader />}
                {isCreateModal ? "Create" : "Update"}
              </Button>
            </div>
          </Row>
        </Form>
        )}
      </CommonModal>
    </>
  );
};

export default CreateDocument;
