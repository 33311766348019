import { Image, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import appImages from "utils/appImages";
import "components/signIn/signIn.scss";
import { useMutation } from "@tanstack/react-query";
import { ForgotPassAPI } from "services/auth";
import { ForgotPassSchema } from "utils/schema/auth";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitLoader } from "common/loader/loader";
import Textbox from "common/form/textBox";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPassSchema),
  });

  const { isPending, mutate: ForgotPassMutate } = useMutation({
    mutationFn: ForgotPassAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data) => {
      toast.success(data.data.message);
    },
  });

  const onSubmit = async (data: any) => {
    const body = {
      email: data?.email,
    };
    await ForgotPassMutate(body);
  };

  return (
    <>
      <div className="signin-page w-100 d-flex align-items-center justify-content-center">
        <div className="signin-logo">
          <Image src={appImages.LoginLogo} />
        </div>
        <div className="signin-form bg-white">
          <h1 className="fw-600">Forgot Password?</h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Textbox
              control={control}
              type="text"
              id="email"
              name="email"
              value=""
              label="Email Address"
              // onKeyDown={handleKeyDown}
              placeholder={"Enter email address"}
              autoComplete="off"
              max={100}
              errorMessage={errors.email && errors.email?.message}
            />
            <Button
              variant="primary"
              type="submit"
              className="text-center d-block"
              disabled={isPending}
            >
              {isPending && <SubmitLoader />}Send Reset Link
            </Button>
            <Link
              to={"/signin"}
              className="link forgot-password text-center mt-2"
            >
              Back to Login ?
            </Link>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
