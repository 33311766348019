import { useState } from "react";
import { Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { FileError } from "react-dropzone";
import appImages from "utils/appImages";

interface UploadFileProps {
  uploadFiles: (files: any[]) => void;
  handleValidate?: (file: File) => FileError | FileError[] | null;
  maxAllows?: number;
  totalItems?: number;
}

const DragAndDropFileUpload: React.FC<UploadFileProps> = ({ uploadFiles, handleValidate, maxAllows=6, totalItems }) => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    validator: handleValidate,
    disabled: totalItems ? totalItems>5 : false,
    maxFiles:maxAllows,
    accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/webp': [],
        'image/heic': [],
        'image/jfif': [],
    },
    onDrop: (acceptedFiles: any[]) => {
      uploadFiles(acceptedFiles);
      setUploadedFiles(acceptedFiles);
    },
  });

  return (
    <div className="custom-select d-flex align-items-center justify-content-center flex-column text-center "
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Image src={appImages.UploadIcon} alt="" width={79} height={57}/>
      <p className="mb-0">Click or drag & drop Image files</p>
    </div>
  );
};
export default DragAndDropFileUpload;
