import { useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import { ScalingLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import { Button, Card, CardHeader, Form, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetObservationDetailAPI } from "services/observation";
import appImages from "utils/appImages";
import { useReactToPrint } from "react-to-print";
import "assets/scss/media.scss";

const ObservationDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const { setBreadCrumb } = GlobalState();
  const myRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  const breadcrumbList = [
    {
      title: location?.pathname?.replaceAll('/', '')?.split('-').includes('dashboard') ? 'Dashboard' : "Observations",
      slug: location?.pathname?.replaceAll('/', '')?.split('-').includes('dashboard') ? '/dashboard' : "/observation",
    },
    {
      title: location?.pathname?.replaceAll('/', '')?.split('-').includes('print') ? "Print View" : "Observation Details",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, setValue, watch } = useForm({});

  const { isPending: editObservationPending, fetchStatus } = useQuery({
    queryKey: ["observationData"],
    queryFn: async () => {
      try {
        const data = id && (await GetObservationDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("profile_image", result?.user_info?.profile_image_url);
          setValue("id", result?.id);
          setValue("valet_name", result?.user_info?.full_name);
          setValue("title", result?.observation_title?.observation_title ?? '-');
          setValue("time_zone", result?.user_info?.time_zone_info.offset);
          setValue("address", result?.user_info?.address);
          setValue("region", result?.user_info?.employee_info?.region_info?.region_title);
          setValue("state", result?.user_info?.state_info?.state_name);
          setValue("city", result?.user_info?.city_info?.city_name);
          setValue("zipcode", result?.user_info?.zipcode);
          setValue("jobsite", result?.jobsite?.jobsite_title);
          setValue("area", result?.area?.area_name ?? '-');
          setValue("unit_no", result?.unit_no ?? '-');
          setValue("date", result?.date);
          setValue("time", result?.time);
          setValue(
            "observation_title",
            result?.observation_title?.observation_title
          );
          setValue("observation_images", result?.observation_images);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  return (
    <>
      {location?.pathname?.replaceAll('/', '')?.split('-').includes('print') &&
        <div className="main-title d-flex align-items-center justify-content-end">
          {watch("task_status") !== "2" && fetchStatus !== "fetching" && (
            <Button
              variant="primary"
              onClick={handlePrint}
            >
              Print
            </Button>
          )}
        </div>
      }
      <div ref={myRef} className="print-content">
        <Card className="mb-30">
          <CardHeader className="border-0 bg-purple text-white fw-600">
            Employee profile details
          </CardHeader>
          <Card.Body>
            {fetchStatus === "fetching" ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : (
              <div className="detail-form d-sm-flex d-block">
                <div className="detail-form-user mb-sm-0 mb-4">
                  <Image src={watch("profile_image")} alt="" />
                </div>
                <Form>
                  <div className="position-relative form-group-icon">
                    <Textbox
                      control={control}
                      type="text"
                      id="id"
                      name="id"
                      value=""
                      label={"ID"}
                      placeholder={"ID"}
                      autoComplete="off"
                      disabled
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                    >
                      <Image
                        src={appImages.CopyIcon}
                        className="cursor-pointer"
                        alt="copy"
                        onClick={() => {
                          copy(watch("id"));
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                  <Textbox
                    control={control}
                    type="text"
                    id="valet_name"
                    name="valet_name"
                    value=""
                    label={"Valet Name"}
                    placeholder={"Valet Name"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="title"
                    name="title"
                    value=""
                    label={"Title"}
                    placeholder={"Title"}
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="time_zone"
                    name="time_zone"
                    value=""
                    label="Time Zone"
                    placeholder="Time Zone"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="region"
                    name="region"
                    value=""
                    label="Region"
                    placeholder="Region"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="address"
                    name="address"
                    value=""
                    label="Address"
                    placeholder="Address"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="state"
                    name="state"
                    value=""
                    label="State"
                    placeholder="State"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="city"
                    name="city"
                    value=""
                    label="City"
                    placeholder="City"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="number"
                    id="zipcode"
                    name="zipcode"
                    value=""
                    label="Zipcode"
                    placeholder="Zipcode"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="jobsite"
                    name="jobsite"
                    value=""
                    label="Jobsite"
                    placeholder="Jobsite"
                    autoComplete="off"
                    disabled
                  />
                  <Textbox
                    control={control}
                    type="text"
                    id="area"
                    name="area"
                    value=""
                    label="Area"
                    placeholder="Area"
                    autoComplete="off"
                    disabled
                  />
                </Form>
              </div>
            )}
          </Card.Body>
        </Card>
        <Card className="mb-30">
          <CardHeader className="border-0 bg-purple text-white fw-600">
            Observations
          </CardHeader>
          <Card.Body>
            <div className="d-flex media">
              <div className="flex-shrink-0 media-left">
                <Image src={appImages.ObservationIcon} alt="" width={24} height={24} />
              </div>
              <div className="flex-grow-1 card-title d-block media-right">
                <h2>Observation Title</h2>
                <p>{watch("observation_title")}</p>
              </div>
            </div>
            <div className="observation-detail card-title d-block">
              <h2 className="mb-md-3 mb-2">Attached Images Details</h2>
              {fetchStatus === "fetching" ? (
                <div className="d-flex justify-content-center">
                  <ScalingLoader />
                </div>
              ) : (
                <div className="detail-form d-sm-flex d-block">
                  <div className="detail-form-images mb-sm-0 mb-3">
                    {watch("observation_images")?.map(
                      (img: any, index: number) => {
                        return (
                          <Image
                            key={index}
                            tabIndex={index}
                            src={img?.observation_image_url}
                            alt=""
                          />
                        );
                      }
                    )}
                  </div>
                  <div className="d-flex flex-column w-100">
                    <Form>
                      <Textbox
                        control={control}
                        type="text"
                        id="jobsite"
                        name="jobsite"
                        value=""
                        label={"Jobsite"}
                        placeholder={"Jobsite"}
                        autoComplete="off"
                        disabled
                      />
                      <Textbox
                        control={control}
                        type="text"
                        id="area"
                        name="area"
                        value=""
                        label="Area (Building)"
                        placeholder="Area"
                        autoComplete="off"
                        disabled
                      />
                      <Textbox
                        control={control}
                        type="text"
                        id="unit_no"
                        name="unit_no"
                        value=""
                        label={"Unit No"}
                        placeholder={"Unit No"}
                        autoComplete="off"
                        disabled
                      />
                      <Textbox
                        control={control}
                        type="text"
                        id="date"
                        name="date"
                        value=""
                        label={"Date"}
                        placeholder={"Date"}
                        autoComplete="off"
                        disabled
                      />
                      <Textbox
                        control={control}
                        type="text"
                        id="time"
                        name="time"
                        value=""
                        label={"Time"}
                        placeholder={"Time"}
                        autoComplete="off"
                        disabled
                      />
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ObservationDetail;
