import { useMutation, useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import JobSiteTable from "common/jobsiteTable/jobSiteTable";
import { ScalingLoader } from "common/loader/loader";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import ImagePreviewModal from "components/documentUpload/imagePreviewModal";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Form,
  Image,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SortOrder, TableColumn } from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";
import ReactSelect, { components } from "react-select";
import { toast } from "react-toastify";
import { ClientObservationReportAPI, ClientWorkDetailListAPI, GetClientDetailAPI } from "services/client";
import { GetObservationReportAPI } from "services/dashboard";
import appImages from "utils/appImages";
import { PerPageOptions } from "utils/constants/constant";

const ClientDetail = () => {
  // react data table state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<any>({});
  const [empWorkList, setWorkListData] = useState<any>();
  // react data table state
  const { id } = useParams();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [copied, setCopied] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowTaskId, setSelectedRowTaskId] = useState(null);
  const [showJobSite, setShowJobSite] = useState<boolean>(false);
  // observation table states
  const [observationFilterDate, setObservationFilterDate] =
    useState<Date | null>(null);
  const [observationCurrentPage, setObservationCurrentPage] =
    useState<number>(1);
  const [obsrvReportData, setObsrvReportData] = useState<any>();
  const [sortObsrvColumn, setObsrvSortColumn] = useState<any>({});
  const [obsrvRowsPerPage, setObsrvRowsPerPage] = useState<number>(10);
  const [imgPreviewData, setImgPreviewData] = useState<any>({});
  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  // observation table states
  const { setBreadCrumb } = GlobalState();

  const breadcrumbList = [
    {
      title: "Client Profile",
      slug: "/client",
    },
    {
      title: "Client Profile Details",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, setValue, watch } = useForm({});

  const { fetchStatus } = useQuery({
    queryKey: ["editEmployeeData"],
    queryFn: async () => {
      try {
        const data = id && (await GetClientDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("id", result?.id);
          setValue("user_name", result?.full_name);
          setValue(
            "company_name",
            result?.client_info?.company_title_info?.company_title
          );
          setValue("title", result?.title ?? "-");
          setValue("email", result?.email);
          setValue("primary_number", result?.phone);
          setValue(
            "secondary_number",
            result?.client_info?.secondary_phone ?? "-"
          );
          setValue("address", result?.address);
          setValue("state", result?.state_info?.state_name);
          setValue("city", result?.city_info?.city_name);
          setValue("zipcode", result?.zipcode);
          setValue(
            "position",
            result?.client_info?.client_position_info?.position_title
          );
          setValue("unit", result?.client_info?.unit ?? "-");
          setValue("total_unit", result?.client_info?.total_unit ?? "-");
          setValue(
            "area",
            result?.client_info?.area_info.length > 0
              ? result?.client_info?.area_info
                  ?.map((el: any) => el?.area_name)
                  .join(", ")
              : "-"
          );
          setValue(
            "regional_manager",
            result?.client_info?.regional_manager_info?.full_name
          );
          setValue(
            "jobsite",
            result?.client_info?.job_site_info
              ?.map((el: any) => el?.jobsite_title)
              .join(", ")
          );
          setValue("time_zone", result?.time_zone_info.label);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const getReportListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      date:
        fromDate &&
        moment(fromDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD"),
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
      user_id: id && id,
    };
    GetReportsMutate(data);
  };

  useEffect(() => {
    getReportListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    fromDate,
  ]);

  const { isPending, mutate: GetReportsMutate } = useMutation({
    mutationFn: ClientWorkDetailListAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setWorkListData(data.data.result);
      return data;
    },
  });

  const { isPending: isPendingObsrvList, mutate: GetObsrvListMutate } =
    useMutation({
      mutationFn: ClientObservationReportAPI,
      onError: (error: any) => {
        toast.error(error?.data?.message);
        throw error;
      },
      onSuccess: (data: any) => {
        setObsrvReportData(data.data.result);
        return data;
      },
    });

  const getObservationListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      user_id: id && id,
      per_page: obsrvRowsPerPage,
      date:
        observationFilterDate &&
        moment(observationFilterDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
          "YYYY-MM-DD"
        ),
      page: page ? page : observationCurrentPage,
      order_by_column: sortObsrvColumn?.column_name ?? column,
      order_by: sortObsrvColumn?.sort_dir ?? sortOrder,
    };
    GetObsrvListMutate(data);
  };

  useEffect(() => {
    getObservationListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    obsrvRowsPerPage,
    // currentPage,
    sortObsrvColumn?.column_name,
    sortObsrvColumn?.sort_dir,
    obsrvRowsPerPage,
    observationFilterDate,
  ]);

  const handleDetailClick = (id: any, taskId: any) => {
    setSelectedRow(id);
    setSelectedRowTaskId(taskId);
    setShowJobSite(true);
  };

  const handleStatus = (key: string) => {
    switch (key) {
      case "0":
        return "Pending";
      case "1":
        return "Assigned";
      case "2":
        return "Completed";
      case "3":
        return "Ongoing";
    }
  };

  const handleShowImg = (img: any) => {
    setImgPreviewData({ img_url: img });
    setShowImgPreview(true);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Jobsite",
      selector: (row: any) => row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 3,
      name: "Task",
      selector: (row: any) =>
        row?.task_title?.length > 25
          ? row?.task_title.slice(0, 25) + "..."
          : row?.task_title,
      sortField: "task_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Employee",
      selector: (row: any) =>
        row?.valet_name?.length > 25
          ? row?.valet_name.slice(0, 25) + "..."
          : row?.valet_name,
      sortField: "valet_name",
      sortable: true,
    },
    // {
    //   id: 5,
    //   name: "Client",
    //   selector: (row: any) => row?.client_name ?? '-',
    //   sortField: "client_name",
    //   sortable: true,
    // },
    {
      id: 6,
      name: "Date",
      selector: (row: any) =>
        row?.start_datetime ? moment(row?.start_datetime).format("D MMM, YYYY") : "-",
    },
    {
      id: 7,
      name: "Jobsite Clock in Clock out",
      selector: (row: any) => (row?.jobsite_punch_in ? moment(row?.jobsite_punch_in).format("hh:mm A") : "-") +
      " to " +
      (row?.jobsite_punch_out ? moment(row?.jobsite_punch_out).format("hh:mm A") : "-"),
    },
    {
      id: 8,
      name: "Clock in Image",
      cell: (row: any) =>
        row.punch_in_image_url ? (
          <Image className="clock-img" src={row.punch_in_image_url} />
        ) : (
          "-"
        ),
    },
    {
      id: 9,
      name: "Clock out Image",
      cell: (row: any) =>
        row.punch_out_image_url ? (
          <Image className="clock-img" src={row.punch_out_image_url} />
        ) : (
          "-"
        ),
    },
    {
      id: 10,
      name: "Valet Location",
      cell: (row: any) => (
        <Image
          className="valet-location cursor-pointer"
          src={appImages.EyeIcon}
        />
      ),
      width: "90px",
    },
    {
      id: 11,
      name: "Official clock in/out",
      selector: (row: any) =>
        (row?.punch_in ? moment(row?.punch_in).format("hh:mm A") : "-") +
        " to " +
        (row?.punch_out ? moment(row?.punch_out).format("hh:mm A") : "-"),
    },
    {
      id: 12,
      name: "Status",
      cell: (row: any) => (
        <div
          className={
            handleStatus(row?.task_status)?.toLocaleLowerCase() +
            " status-tooltip"
          }
        >
          {handleStatus(row?.task_status)}
        </div>
      ),
      width: "130px",
    },
    {
      id: 13,
      name: "More",
      cell: (row: any) => (
        <Image
          className="cursor-pointer"
          onClick={() => handleDetailClick(row?.jobsite_id, row?.id)}
          src={appImages.RightDetailIcon}
          alt="edit"
          width={14}
          height={14}
        />
      ),
      width: "80px",
    },
  ];

  const obsrvColumns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Profile",
      cell: (row: any) => (
        <Image
          className="profile-img rounded-circle"
          src={row?.profile_image_url}
        />
      ),
      width: "90px",
    },
    {
      id: 3,
      name: "Valet Name",
      selector: (row: any) => row?.user_name,
      sortField: "user_name",
      sortable: true,
    },
    {
      id: 4,
      name: "Observations Date",
      selector: (row: any) =>
        row?.date ? moment(row?.date).format("D MMM, YYYY") : "-",
      sortField: "date",
      sortable: true,
      width: "12%",
    },
    {
      id: 5,
      name: "Observations Title",
      selector: (row: any) =>
        row?.observation_title
          ? row?.observation_title.length > 25
            ? row?.observation_title.slice(0, 25) + "..."
            : row?.observation_title
          : "-",
      sortField: "observation_title",
      sortable: true,
      width: "12%",
    },
    {
      id: 6,
      name: "Jobsite",
      selector: (row: any) =>
        row?.jobsite_title?.length > 25
          ? row?.jobsite_title.slice(0, 25) + "..."
          : row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 7,
      name: "Area (Building)",
      selector: (row: any) =>
        row?.area_name
          ? row?.area_name?.length > 25
            ? row?.area_name.slice(0, 25) + "..."
            : row?.area_name
          : "-",
      sortField: "area_name",
      sortable: true,
    },
    {
      id: 8,
      name: "Unit",
      selector: (row: any) => row?.unit_no ?? "-",
      sortField: "unit_no",
      sortable: true,
    },
    {
      id: 9,
      name: "Image",
      cell: (row: any) =>
        row.observation_images?.length > 0 ? (
          <>
            <div className="gap-2 observation-images">
              {row?.observation_images
                ?.slice(0, 3)
                ?.map((item: any, index: number) => {
                  return (
                    <Image
                      onClick={() =>
                        handleShowImg(
                          row?.observation_images?.[0]?.observation_image_url
                        )
                      }
                      className="clock-img cursor-pointer"
                      loading="lazy"
                      key={index}
                      src={item?.observation_image_url}
                    />
                  );
                })}
              {row.observation_images?.length > 3 && (
                <span className="d-flex align-items-center justify-content-center">
                  +{row.observation_images?.length - 3}
                </span>
              )}
            </div>
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   id: 10,
    //   name: "Print",
    //   cell: (row: any) => (
    //     <Nav.Link
    //       as={NavLink}
    //       to={`/dashboard-print-observations-detail/${row?.id}`}
    //     >
    //       <Image
    //         className="valet-location cursor-pointer"
    //         src={appImages?.PrinterIcon}
    //       />
    //     </Nav.Link>
    //   ),
    //   width: "70px",
    // },
    // {
    //   id: 11,
    //   name: "More",
    //   cell: (row: any) => (
    //     <div className="action-btn">
    //       <Nav.Link
    //         as={NavLink}
    //         to={`/dashboard-observations-detail/${row?.id}`}
    //       >
    //         <Image
    //           src={appImages.RightDetailIcon}
    //           alt="edit"
    //           width={14}
    //           height={14}
    //         />
    //       </Nav.Link>
    //     </div>
    //   ),
    //   width: "80px",
    // },
  ];

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? sortDirection : undefined,
    });
  };

  const handleChangeObsrvPerPage = (id: number) => {
    id && setObsrvRowsPerPage(id);
    setObservationCurrentPage(1);
  };

  const handleObsrvSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setObsrvSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  return (
    <>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          Client profile details
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="detail-form d-sm-flex d-block">
              <Form>
                <div className="position-relative form-group-icon">
                  <Textbox
                    control={control}
                    type="text"
                    id="id"
                    name="id"
                    value=""
                    label={"ID"}
                    placeholder={"ID"}
                    autoComplete="off"
                    disabled
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                  >
                    <Image
                      src={appImages.CopyIcon}
                      alt="copy"
                      className="cursor-pointer"
                      onClick={() => {
                        copy(watch("id"));
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    />
                  </OverlayTrigger>
                </div>
                <Textbox
                  control={control}
                  type="text"
                  id="user_name"
                  name="user_name"
                  value=""
                  label={"User Name"}
                  placeholder={"User Name"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="company_name"
                  name="company_name"
                  value=""
                  label={"Company Name"}
                  placeholder={"Company Name"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="title"
                  name="title"
                  value=""
                  label={"Title"}
                  placeholder={"Title"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="email"
                  name="email"
                  value=""
                  label={"Email"}
                  placeholder={"Email"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="primary_number"
                  name="primary_number"
                  value=""
                  label={"Primary Number"}
                  placeholder="Primary Number"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="secondary_number"
                  name="secondary_number"
                  value=""
                  label="Secondary Contact"
                  placeholder="Secondary Contact"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="address"
                  name="address"
                  value=""
                  label="Address"
                  placeholder="Address"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="zipcode"
                  name="zipcode"
                  value=""
                  label="Zipcode"
                  placeholder="Zipcode"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="city"
                  name="city"
                  value=""
                  label="City"
                  placeholder="City"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="state"
                  name="state"
                  value=""
                  label="State"
                  placeholder="State"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="position"
                  name="position"
                  value=""
                  label="Position"
                  placeholder="Position"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="unit"
                  name="unit"
                  value=""
                  label="Unit"
                  placeholder="Unit"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="total_unit"
                  name="total_unit"
                  value=""
                  label="Total Unit"
                  placeholder="Total Unit"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="area"
                  name="area"
                  value=""
                  label="Area"
                  placeholder="Area"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="regional_manager"
                  name="regional_manager"
                  value=""
                  label="Regional Manager Name"
                  placeholder="Regional Manager Name"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="jobsite"
                  name="jobsite"
                  value=""
                  label="Jobsite"
                  placeholder="Jobsite"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="time_zone"
                  name="time_zone"
                  value=""
                  label="Time Zone"
                  placeholder="Time Zone"
                  autoComplete="off"
                  disabled
                />
              </Form>
            </div>
          )}
        </Card.Body>
      </Card>
      <div className="main-title d-md-flex d-block align-items-center justify-content-between">
        <h2>Employee work details</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <div className="form-group">
            <ReactDatePicker
              disabled={isPending}
              dateFormat={"dd MMM yyyy"}
              isClearable
              selected={fromDate}
              placeholderText={"Date"}
              onChange={(date: any) => {
                setCurrentPage(1);
                setFromDate(date);
              }}
              className="form-control"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <ReactSelect
            isDisabled={false}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
              //   field.onChange(selectedOption);
              //   setSiteData([]);
              //   setValue("site_name", []);
              //   // handleAreaSelectChange(selectedOption);
              //   if (selectedOption?.length) {
              //     getSiteData({ area_id: selectedOption?.map(el => el.id) });
              //   }
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={empWorkList?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={empWorkList?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getReportListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      <Col md={12}>
        <div className="main-title d-md-flex d-block align-items-center justify-content-between">
          <h2>Observations as per clients</h2>
          <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
            <div className="form-group">
              <ReactDatePicker
                dateFormat={"dd MMM yyyy"}
                isClearable
                selected={observationFilterDate}
                placeholderText={"Date"}
                onChange={(date: any) => {
                  setObservationCurrentPage(1);
                  setObservationFilterDate(date);
                }}
                className="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <ReactSelect
              isDisabled={isPending}
              getOptionLabel={(item: any) => item.name}
              getOptionValue={(item: any) => item.id}
              options={PerPageOptions}
              defaultValue={PerPageOptions[1]}
              components={{
                SingleValue: ({ children, ...props }) => {
                  return (
                    <components.SingleValue {...props}>
                      {"Records per page: " + children}
                    </components.SingleValue>
                  );
                },
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      {"Records per page: " + children}
                    </components.Placeholder>
                  );
                },
                IndicatorSeparator: () => null,
              }}
              onChange={(selectedOption) => {
                selectedOption && handleChangeObsrvPerPage(selectedOption.id);
              }}
              placeholder={""}
              classNamePrefix="react-select"
              className="react-select-container"
              menuPlacement="bottom"
            />
          </div>
        </div>
        <ReactDataTable
          columns={obsrvColumns}
          tableData={obsrvReportData?.data}
          rowsPerPage={obsrvRowsPerPage}
          totalRecord={obsrvReportData?.total}
          showPage={observationCurrentPage - 1}
          onPageChange={(page) => {
            setObservationCurrentPage(page + 1);
            getObservationListData(page + 1);
          }}
          inProgress={isPendingObsrvList}
          handleSort={handleObsrvSort}
        />
      </Col>
      {showJobSite && (
        <JobSiteTable
          show={showJobSite}
          title="Jobsite"
          handleClose={() => setShowJobSite(false)}
          id={selectedRow}
          taskId={selectedRowTaskId}
        />
      )}
      {showImgPreview && (
        <ImagePreviewModal
          data={imgPreviewData}
          show={showImgPreview}
          handleClose={() => setShowImgPreview(false)}
        />
      )}
    </>
  );
};

export default ClientDetail;
