import { ScalingLoader } from "common/loader/loader";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ReactDataTableProps } from "types/reactDataTable";
import appImages from "utils/appImages";
import TableStyles from "utils/constants/tableStyles";
import { ReactPagination } from "utils/helpers/reactPagination";
import "assets/scss/reactDatatable.scss";

const ReactDataTable: React.FC<ReactDataTableProps> = ({
  columns,
  tableData,
  rowsPerPage,
  totalRecord,
  showPage,
  onPageChange,
  dynamicClass,
  inProgress = false,
  isPagination = true,
  handleSort,
  handleRowSelected,
  isSelectableRow = false,
  clearSelectedRows = false,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [currDataRange, setCurrDataRange] = useState<string>("");
  const [itemOffset, setItemOffset] = useState(1);

  useEffect(() => {
    if (showPage === 0) {
      setItemOffset(1);
    }
  }, [showPage]);

  useEffect(() => {
    const endOffset = Math.min(itemOffset - 1 + rowsPerPage, totalRecord);
    setCurrDataRange(`${itemOffset}-${endOffset}`);
    setPageCount(Math.ceil(totalRecord / rowsPerPage) || 1);
  }, [itemOffset, rowsPerPage, totalRecord]);

  const handlePageClick = (event: any) => {
    const newOffset = event * rowsPerPage + 1;
    setItemOffset(newOffset);
  };

  const handlePageChange = (page: number) => {
    onPageChange && onPageChange(page);
    handlePageClick(page);
  };

  return (
    <>
      <Card className="mb-30">
        <CardBody>
          <div className={`react-datatable ${dynamicClass}`}>
            <DataTable
              columns={columns}
              responsive={true}
              data={tableData}
              customStyles={TableStyles}
              pagination={isPagination}
              sortIcon={
                <Image
                  src={appImages.SortIcon}
                  className="table-sorticon"
                  width={12}
                  style={{ marginLeft: "5px" }}
                />
              }
              paginationPerPage={100}
              paginationTotalRows={100}
              // onRowClicked={(row: any) => handleRowClick(row)}
              onSelectedRowsChange={handleRowSelected}
              progressPending={inProgress}
              progressComponent={<ScalingLoader />}
              sortServer
              onSort={handleSort}
              selectableRows={isSelectableRow}
              // selectableRowsComponent={BootstrapCheckbox}
              clearSelectedRows={clearSelectedRows}
              paginationComponent={(props: any) => (
                <div className="d-sm-flex d-block text-center align-items-center justify-content-between pagination-wrapper">
                  <span className="table-count fw-600 mb-sm-0 mb-2 d-block">{`${currDataRange} of ${totalRecord}`}</span>
                  <ReactPagination
                    pageCount={pageCount}
                    currentPage={showPage}
                    onPageChange={(page: number) => handlePageChange(page)}
                  />
                </div>
              )}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ReactDataTable;
