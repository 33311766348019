import * as Yup from "yup";

export const CreateConcernSchema = Yup.object().shape({
  task_name: Yup.object()
    .required("Task is required")
    .typeError("Task is required"),
  concern_name: Yup.object()
    .required("Concern is required")
    .typeError("Concern is required"),
  jobsite: Yup.object()
    .required("Jobsite is required")
    .typeError("Jobsite is required"),
  date: Yup.date().required("Date is required").typeError("Date is required"),
  time: Yup.date().required("Time is required").typeError("Time is required"),
});

export const SubmitFeedbackSchema = Yup.object().shape({
  write_answer: Yup.string().required("Answer is required")
});
