import React, { useState } from "react";
import { Tabs, Tab, Col, Card, CardBody, Image } from "react-bootstrap";
import ListingComp from "common/detailTabs/listingComp";
import Select from "react-select";
import "assets/scss/tabs.scss";
import "common/detailTabs/detailTabs.scss";
import moment from "moment";
import { DashboarCardDropdownData } from "utils/constants/constant";
import { ScalingLoader } from "common/loader/loader";

interface DetailTabsProps {
  data: any;
  loading: boolean;
  handleChangeFilter: (data: any) => void;
}

const DetailTabs: React.FC<DetailTabsProps> = ({
  data,
  handleChangeFilter,
  loading,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<any>({});

  const GetCurrentName = (key:string) => {
    switch(key){
      case 'today':
        return "Today's";
      case 'this_week':
        return "This Week's";
      case 'this_month':
        return "This Month's";
      default:
        return "Today's";
    }
  }

  const ordinal_suffix_of = (i:number) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

  return (
    <>
      <Card className="mb-30 dashboard-tabs">
        <CardBody>
          <Tabs
            defaultActiveKey="birthday"
            id="uncontrolled-tab-example"
            className="horizontal-scrollbar"
          >
            <Tab eventKey="birthday" title={"Birthdays"}>
              {/* <ListingComp title="Today's Birthday" data={data?.birthday} /> */}
              <div className="card-title d-flex row">
                <Col xs={6}>
                  <h2>{GetCurrentName(selectedFilter?.birthday)} Birthday</h2>
                </Col>
                <Col xs={6}>
                  <Select
                    isDisabled={loading}
                    getOptionLabel={(item: any) => item.name}
                    getOptionValue={(item: any) => item.id}
                    options={DashboarCardDropdownData}
                    defaultValue={DashboarCardDropdownData[0]}
                    isSearchable
                    onChange={(selectedOption) => {
                      setSelectedFilter((prevState: any) => ({
                        ...prevState,
                        birthday: selectedOption?.id,
                      }));
                      handleChangeFilter({
                        ...selectedFilter,
                        birthday: selectedOption?.id,
                      });
                    }}
                    placeholder={"Select Day"}
                    classNamePrefix="react-select"
                    className="react-select-container"
                    menuPlacement="bottom"
                  />
                </Col>
              </div>
              <div className="tab-listing-content-wrapper">
                {loading ? (
                  <span className="d-flex justify-content-center">
                    <ScalingLoader />
                  </span>
                ) : (
                  <>
                    {data?.birthday?.length>0 ? data?.birthday?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="tab-listing-content d-flex align-items-center justify-content-between"
                        >
                          <div className="tab-listing-profile d-flex align-items-center">
                            <Image
                              className="profile-img rounded-circle"
                              loading="lazy"
                              src={item?.profile_image_url}
                              alt=""
                            />
                            <h3>
                              {item?.full_name && item?.full_name?.length > 25
                                ? item?.full_name.slice(0, 25) + "..."
                                : item?.full_name}{" "}
                              {/* <span className="d-block">
                                {moment(item?.birthdate).format("D MMM, YYYY")}
                              </span> */}
                            </h3>
                          </div>
                          <span className="tab-listing-date">
                          {moment(item?.birthdate).format("D MMM, YYYY")}
                          </span>
                        </div>
                      );
                    })
                    : <span className="d-flex justify-content-center">No Record's Found</span>
                  }
                  </>
                )}
              </div>
            </Tab>
            <Tab eventKey="anniversary" title={"Anniversaries"}>
              {/* <ListingComp title="Today's Anniversaries" data={data?.anniversary} /> */}
              <div className="card-title d-flex row">
                <Col xs={6}>
                  <h2>{GetCurrentName(selectedFilter?.anniversary)} Anniversaries</h2>
                </Col>
                <Col xs={6}>
                  <Select
                    isDisabled={loading}
                    getOptionLabel={(item: any) => item.name}
                    getOptionValue={(item: any) => item.id}
                    options={DashboarCardDropdownData}
                    defaultValue={DashboarCardDropdownData[0]}
                    isSearchable
                    onChange={(selectedOption) => {
                      setSelectedFilter((prevState: any) => ({
                        ...prevState,
                        anniversary: selectedOption?.id,
                      }));
                      handleChangeFilter({
                        ...selectedFilter,
                        anniversary: selectedOption?.id,
                      });
                    }}
                    placeholder={"Select Day"}
                    classNamePrefix="react-select"
                    className="react-select-container"
                    menuPlacement="bottom"
                  />
                </Col>
              </div>
              <div className="tab-listing-content-wrapper">
                {loading ? (
                  <span className="d-flex justify-content-center">
                    <ScalingLoader />
                  </span>
                ) : (
                  <>
                    {data?.anniversary?.length>0 ? data?.anniversary?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="tab-listing-content d-flex align-items-center justify-content-between"
                        >
                          <div className="tab-listing-profile d-flex align-items-center">
                            <Image
                              className="profile-img rounded-circle"
                              loading="lazy"
                              src={item?.profile_image_url}
                              alt=""
                            />
                            <h3>
                              {item?.full_name && item?.full_name?.length > 25
                                ? item?.full_name.slice(0, 25) + "..."
                                : item?.full_name}{" "}
                              <span className="d-block">
                                {moment(item?.join_date).format("D MMM, YYYY")}
                              </span>
                            </h3>
                          </div>
                          <span className="tab-listing-date">
                            {ordinal_suffix_of(+item?.anniversary_years)} Work Anniversary
                          </span>
                        </div>
                      );
                    })
                    : <span className="d-flex justify-content-center">No Record's Found</span>
                    }
                  </>
                )}
              </div>
            </Tab>
            <Tab eventKey="holiday" title={"Holidays"}>
              {/* <ListingComp title="Today's Holidays" data={[]} /> */}
              <div className="tab-listing-content-wrapper mt-3">
              <span className="d-flex justify-content-center">No Record's Found</span>
              </div>
            </Tab>
            <Tab eventKey="time_off" title={"Time Off"}>
              <div className="tab-listing-content-wrapper mt-3">
              <span className="d-flex justify-content-center">No Record's Found</span>
              </div>
              {/* <ListingComp title="Today's Absent" data={[]} /> */}
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  );
};

export default DetailTabs;
