import appImages from "utils/appImages";

export const UserDateFormat = "MMM DD, YYYY";

export const USER_DATA_SECRET_KEY = 'savy_user_data';
export const ROLE_SECRET_KEY = 'savvy_role_key';

export const SUPER_ADMIN_ROLE = 1;
export const SYS_ADMIN_ROLE = 2;
export const CLIENT_ROLE = 3;
export const EMP_RM_ROLE = 4;
export const EMP_VALET_ROLE = 5;
export const EMP_HR_ROLE = 6;

export const EmailRegex =
  /^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/;
export const PhoneRegex =
  /^(\+?1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
export const ZipCodeRegex =
  /^\d{5}$/;
export const AlphabetOnlyRegex =
  /^[a-zA-Z ]+$/;
export const RestrictSpaceRegex =
  /^\S*$/;

export const PerPageOptions = [
  {
    id: 5,
    name: "5",
  },
  {
    id: 10,
    name: "10",
  },
  {
    id: 20,
    name: "20",
  },
  {
    id: 50,
    name: "50",
  }
];

export const NavItems = [
  {
    id: 1,
    name: "Dashboard",
    icon: appImages.DashboardIcon,
    path: "/dashboard",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 2,
    name: "Employee Profiles",
    icon: appImages.ProfileIcon,
    path: "/employee",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE],
  },
  {
    id: 3,
    name: "Client Profiles",
    icon: appImages.ProfileIcon,
    path: "/client",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE],
  },
  {
    id: 4,
    name: "Manage Jobsite",
    icon: appImages.JobsiteIcon,
    path: "/jobsite",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 5,
    name: "Observations",
    icon: appImages.ObservationIcon,
    path: "/observation",
    submenu: [
      { name: "Walkthrough", path: "/observation-walkthrough" },
      { name: "Light Audit", path: "/observation-lightaudit" },
    ],
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 6,
    name: "Manage Task",
    icon: appImages.DocumentIcon,
    path: "/task",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 7,
    name: "Review Reports",
    icon: appImages.ReportIcon,
    path: "/reviewReports",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 8,
    name: "QR Codes",
    icon: appImages.QrcodeIcon,
    path: "/qr",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 9,
    name: "Manage Concern",
    icon: appImages.ConcernIcon,
    path: "/concern",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  // {
  //   id: 10,
  //   name: "Supply Request",
  //   icon: appImages.SupplyrequestIcon,
  //   path: "#",
  //   //   role: [SupervisorRole, FloorManagerRole],
  // },
  // {
  //   id: 11,
  //   name: "Message",
  //   icon: appImages.MessageIcon,
  //   path: "#",
  //   //   role: [AdminRole, CompanyOwnerRole, SupervisorRole, FloorManagerRole],
  // },
  {
    id: 12,
    name: "Announcements",
    icon: appImages.AnnouncementIcon,
    path: "/announcement",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  {
    id: 13,
    name: "Document",
    icon: appImages.DocumentIcon,
    path: "/document",
    submenu: [{ name: "Sent Document", path: "/sent-document" }],
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
  // {
  //   id: 14,
  //   name: "Notification",
  //   icon: appImages.NotificationIcon,
  //   path: "#",
  //   //   role: [CompanyOwnerRole],
  // },
  {
    id: 15,
    name: "Manage Title",
    icon: appImages.DocumentIcon,
    path: "/title",
    submenu: [
      { name: "Observation Title", path: "/observations-title" },
      { name: "Task Title", path: "/tasks-title" },
      { name: "Instant Task Title", path: "/instanttask-title" },
      { name: "Concern Title", path: "/concerns-title" },
      { name: "Client Position Title", path: "/clientposition-title" },
      { name: "Company Title", path: "/company-title" },
      { name: "Region Title", path: "/region-title" },
      { name: "Employee Position", path: "/position-title" },
    ],
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE],
  },
  {
    id: 16,
    name: "Holiday",
    icon: appImages.HolidayIcon,
    path: "/holiday",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE],
  },
  {
    id: 17,
    name: "Manage Password",
    icon: appImages.KeyIcon,
    path: "/managePassword",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE],
  },
  {
    id: 18,
    name: "Manage Valet",
    icon: appImages.ManageValetIcon,
    path: "/valet",
    role: [EMP_RM_ROLE, SYS_ADMIN_ROLE],
  },
  {
    id: 19,
    name: "My Profile",
    icon: appImages.ProfileIcon,
    path: "/profile",
    role: [SUPER_ADMIN_ROLE, SYS_ADMIN_ROLE, EMP_RM_ROLE],
  },
];

export const DashboarCardDropdownData = [
  {
      id: 'today',
      name: "Today",
  },
  {
      id: "this_week",
      name: "This Week",
  },
  {
      id: 'this_month',
      name: "This Month",
  },
];