import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Form, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import { NavLink } from "react-router-dom";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GlobalState } from "context/globalContext";
import { GetTaskAPI } from "services/task";
import moment from "moment";
import CreateTask from "components/manageTask/createTask";
import AssignWork from "components/manageTask/assignWork";

const Task = () => {
  const { setBreadCrumb } = GlobalState();
  const [isCreateTask, setIsCreateTask] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [showAssignWork, setShowAssignWork] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<any>({});

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "Manage Task",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "createInstant":
        data = {
          title: "Instant Task Create successfully",
          description: "Instant Task has been successfully created",
        };
        return data;
      case "createTask":
        data = {
          title: "Task Create successfully",
          description: "Task has been successfully created",
        };
        return data;
      case "assignWork":
        data = {
          title: "Task assign successfully",
          description: "Task has been successfully assigned",
        };
        return data;
      default:
        return null;
    }
  };

  const getTaskListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search_term: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetTaskMutate(data);
  };

  useEffect(() => {
    getTaskListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const handleCreateTaskCallback = () => {
    getTaskListData();
    setShowAssignWork(true);
  };

  const { isPending, mutate: GetTaskMutate } = useMutation({
    mutationFn: GetTaskAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setTaskData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const handleTaskStatus = (key: string) => {
    switch (key) {
      case "0":
        return "Pending";
      case "1":
        return "Assigned";
      case "2":
        return "Completed";
      case "3":
        return "Ongoing";
      case "4":
        return "Ongoing";
    }
  };

  const handleAssignWork = (id: number) => {
    setCurrentRow(id);
    setShowAssignWork(true);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Task Name",
      selector: (row: any) =>
        row?.task_title && row?.task_title?.length > 25
          ? row?.task_title.slice(0, 25) + "..."
          : row?.task_title,
      sortField: "task_title",
      sortable: true,
    },
    {
      id: 3,
      name: "Valet Name",
      selector: (row: any) =>
        row?.valet_name
          ? row?.valet_name?.length > 25
            ? row?.valet_name.slice(0, 25) + "..."
            : row?.valet_name
          : "-",
      sortField: "valet_name",
      sortable: true,
    },
    {
      id: 4,
      name: "Jobsite",
      selector: (row: any) =>
        row?.jobsite_title
          ? row?.jobsite_title?.length > 25
            ? row?.jobsite_title.slice(0, 25) + "..."
            : row?.jobsite_title
          : "-",
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 5,
      name: "Area",
      cell: (row: any) => (
        <>
          {row?.area_name ? (
            <>
              {/* {row?.area_name} */}
              {row?.area_name?.length > 25
                ? row?.area_name.slice(0, 25) + "..."
                : row?.area_name}
              {row?.total_areas > 1 && (
                <>
                  {`, `}
                  <span>+{row?.total_areas - 1}</span>
                </>
              )}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    // {
    //   id: 5,
    //   name: "Area",
    //   selector: (row: any) =>
    //     row?.area_name ? row?.area_name?.length > 25
    //       ? row?.area_name.slice(0, 25) + "..."
    //       : row?.area_name : "-",
    //   sortField: "area_name",
    //   sortable: true,
    // },
    {
      id: 6,
      name: "Unit",
      selector: (row: any) => row?.unit ?? "-",
      sortField: "unit",
      sortable: true,
      width: "80px",
    },
    {
      id: 7,
      name: "Description",
      selector: (row: any) =>
        row?.description
          ? row?.description.length > 25
            ? row?.description.slice(0, 25) + "..."
            : row?.description
          : "-",
    },
    {
      id: 8,
      name: "Date & Time",
      selector: (row: any) =>
        `${moment(row?.start_date).format("DD MMM YYYY")}, ${row?.start_time}`,
    },
    {
      id: 9,
      name: "Created by",
      selector: (row: any) => row?.created_by,
    },
    {
      id: 10,
      name: "Status",
      cell: (row: any) => (
        <>
          {row?.task_status === "0" ? (
            <button
              className="btn btn-table btn-primary"
              onClick={() => handleAssignWork(row?.id)}
            >
              Assign Work
            </button>
          ) : (
            <div
              className={
                handleTaskStatus(row?.task_status)?.toLocaleLowerCase() +
                " status-tooltip"
              }
            >
              {handleTaskStatus(row?.task_status.toString())}
            </div>
          )}
        </>
      ),
      width: "13%",
    },
    {
      id: 11,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link as={NavLink} to={`/task-detail/${row?.id}`}>
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  const handleCloseCreateModal = () => {
    setIsCreateTask(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Task List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="text"
              name="sarch"
              id="search"
              value={search}
              placeholder="Search"
              // maxLength={20}
              autoComplete="off"
              onChange={(e: any) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
          <Button variant="primary" onClick={() => setIsCreateTask(true)}>
            Add Task
          </Button>
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={taskData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={taskData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getTaskListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {isCreateTask && (
        <CreateTask
          title={"Add Task"}
          setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateTask}
          callback={handleCreateTaskCallback}
          callbackState={(id: number) => setCurrentRow(id)}
          show={isCreateTask}
          handleClose={handleCloseCreateModal}
          currentRow={currentRow}
        />
      )}
      {showAssignWork && (
        <AssignWork
          title={"Assign Work"}
          setShowSuccessModal={handleShowSuccessModal}
          callback={getTaskListData}
          show={showAssignWork}
          handleClose={() => setShowAssignWork(false)}
          currentRow={currentRow}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
    </>
  );
};

export default Task;
