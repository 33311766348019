import { useQuery } from "@tanstack/react-query";
import DashboardCard from "common/dashboardCard/dashboardCard";
import { ScalingLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetTitleDashboardAPI } from "services/title";

const TitleDashboard = () => {
  const { setBreadCrumb } = GlobalState();
  const [titleData, setTitleData] = useState<any>();

  const breadcrumbList = [
    {
      title: "Manage Title",
      slug: "#",
    },
  ];

  const { isPending: isPendingTitleDashboard } = useQuery({
    queryKey: ["clientFormData"],
    queryFn: async () => {
      try {
        const data = await GetTitleDashboardAPI();
        return setTitleData(data.data.result);
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Manage Title</h2>
      </div>
      {isPendingTitleDashboard ? (
        <div className="d-flex justify-content-center"><ScalingLoader /></div>
      ) : (
        <div className="dashboard-cards-main d-grid mb-30">
          <DashboardCard
            cardHeading={"Observation Title"}
            navRoute={'/observations-title'}
            count={titleData?.observation_title ?? 0}
          />
          <DashboardCard
            cardHeading={"Task Title"}
            navRoute={'/tasks-title'}
            count={titleData?.task_title ?? 0}
            />
          <DashboardCard
            cardHeading={"Instant Task Title"}
            navRoute={'/instanttask-title'}
            count={titleData?.instant_task_title ?? 0}
            />
          <DashboardCard
            cardHeading={"Concern Title"}
            navRoute={'/concerns-title'}
            count={titleData?.concern_title ?? 0}
            />
          <DashboardCard
            cardHeading={"Client Position Title"}
            navRoute={'/clientposition-title'}
            count={titleData?.client_position_title ?? 0}
            />
          <DashboardCard
            cardHeading={"Company Title"}
            navRoute={'/company-title'}
            count={titleData?.company_title ?? 0}
            />
          <DashboardCard
            cardHeading={"Region Title"}
            navRoute={'/region-title'}
            count={titleData?.region_title ?? 0}
            />
          <DashboardCard
            cardHeading={"Employee Position Title"}
            navRoute={'/position-title'}
            count={titleData?.employee_position_title ?? 0}
          />
        </div>
      )}
    </>
  );
};

export default TitleDashboard;
