import React from "react";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormModalProps } from "types/modal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Textbox from "common/form/textBox";
import { DownloadQrAPI, GetQRDetailAPI } from "services/qr";

const QrDetail: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  currentRow,
}) => {
  const { control, setValue, watch } = useForm({});

  const { fetchStatus } = useQuery({
    queryKey: ["qrDisplayDetail"],
    queryFn: async () => {
      try {
        const data =
          currentRow && (await GetQRDetailAPI(currentRow.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("qr_code_name", result?.qr_code_name);
          setValue("jobsite", result?.jobsite_title);
          setValue("qr_image_url", result?.qr_code_image_url);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const handlePrintQr = (imageUrl: string) => {

    const img = document.createElement('img');
    img.src = imageUrl;

    const printWindow = window.open('', '_blank');
    printWindow && printWindow.document.body.appendChild(img);

    // Wait for the image to load before triggering the print dialog
    img.onload = () => {
      printWindow && printWindow.print();
      printWindow && printWindow.close();
    };
  }

  const { isPending: pendingDownloadQR, mutate: DownloadQrMutate } =
    useMutation({
      mutationFn: DownloadQrAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: async (data: any) => {
        const imageUrl = data?.data?.result;
        try {
          const link = document.createElement('a');
          link.href = imageUrl;
          link.className = "download_qr";
          link.target = '_balnk';
          link.download = 'qr.png'
          document.body.appendChild(link);
          link.click();
          // document.body.removeChild(link);
          // link.parentNode && link.parentNode.removeChild(link);
        } catch (error) {
          console.error('Error downloading file:', error);
        }

      },
    });

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-default"}
      >
        {fetchStatus === "fetching" ? (
          <SpinerLoader />
        ) : (
          <>
            <div className="detail-form">
              <Form className="d-block">
                <Row>
                  <Col md={6}>
                    <Textbox
                      control={control}
                      type="text"
                      id="qr_code_name"
                      name="qr_code_name"
                      value=""
                      label={"QR Code Name"}
                      placeholder={"QR Code Name"}
                      autoComplete="off"
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <Textbox
                      control={control}
                      type="text"
                      id="jobsite"
                      name="jobsite"
                      value=""
                      label={"Jobsite"}
                      placeholder={"Jobsite"}
                      autoComplete="off"
                      disabled
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="qr-img text-center">
              <Image src={watch('qr_image_url')} alt='' width={240} height={240}/>
            </div>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="outline-primary" onClick={() => handlePrintQr(watch('qr_image_url'))}>
                Print QR Code
              </Button>
              <Button
                onClick={() => DownloadQrMutate(currentRow)}
                type="submit"
                disabled={pendingDownloadQR}
              >
                {pendingDownloadQR && <SubmitLoader />}Download
              </Button>
            </div>
          </>
        )}
      </CommonModal>
    </>
  );
};

export default QrDetail;
