const TableStyles = {
  table: {
    style: {
      Position: "relative",
      zIndex: "1",
    },
  },
  rows: {
    style: {
      borderColor: "#eee",
      "&:not(:last-of-type)": {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      "@media (max-width: 1600px)": {
        minHeight: "42px",
      },
      "@media (max-width: 1400px)": {
        minHeight: "40px",
      },
    },
  },
  headRow: {
    style: {
      "@media (max-width: 1600px)": {
        padding: "12px 0px",
      },
      "@media (max-width: 1400px)": {
        padding: "10px 0",
      },
      minHeight: "auto",
      backgroundColor: "#eee",
      borderBottom: "0",
      padding: "16px 0px",
      borderRadius: "10px"
    },
  },
  headCells: {
    style: {
      "@media (max-width: 1800px)": {
        fontSize: "15px",
        padding: "0 15px",
      },
      "@media (max-width: 1600px)": {
        fontSize: "14px",
        padding: "0 12px",
      },
      "@media (max-width: 1400px)": {
        fontSize: "13px",
        padding: "0 10px",
      },
      // display: "inline-table",
      fontSize: "16px",
      fontWeight: "600",
      color: "#1B1D23",
      borderRight: "1px solid rgba(112, 112, 112, .5)",
      lineHeight: "1.2",
      // padding: "0 20px",
      "&:last-child": {
        borderRight: "none",
      },
    },
  },
  cells: {
    style: {
      "@media (max-width: 1800px)": {
        padding: "8px 15px",
      },
      "@media (max-width: 1600px)": {
        fontSize: "13px",
        padding: "8px 12px",
      },
      "@media (max-width: 1400px)": {
        fontSize: "12px",
        padding: "8px 10px",
      },
      color: "#707070;",
      padding: "8px 16px",
      fontSize: "14px",
      fontWeight: "600",
    },
  },
};
export default TableStyles;
