import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetAreaListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/area-dropdown-list`,
    method: "POST",
    body
  });
  return response;
};

export const GetCityListAPI = async (id: number) => {
  const response: AxiosResponse<any > = await request({
    url: `/city/${id}`,
    method: "GET",
  });
  return response;
};

export const GetPositionTitleAPI = async () => {
  const response: AxiosResponse<any > = await request({
    url: `/position-title-list`,
    method: "POST",
  });
  return response;
};

export const JobsiteDropdownListAPI = async () => {
  const response: AxiosResponse<any > = await request({
    url: `/jobsite-dropdown-list`,
    method: "GET",
  });
  return response;
};

export const GetValetListAPI = async (body: any) => {
  const response: AxiosResponse<any > = await request({
    url: `/valet-list-jobsite`,
    method: "POST",
    body
  });
  return response;
};

export const AssignWorkAPI = async (body: any) => {
  const response: AxiosResponse<any > = await request({
    url: `/task-assign`,
    method: "POST",
    body
  });
  return response;
};

export const EmployeeByRoleAPI = async (body: any) => {
  const response: AxiosResponse<any > = await request({
    url: `/employee-by-role`,
    method: "POST",
    body
  });
  return response;
};

export const EmpJobsiteDetailListAPI = async (body: any) => {
  const response: AxiosResponse<any > = await request({
    url: `/employee-work-report-detail`,
    method: "POST",
    body
  });
  return response;
};
