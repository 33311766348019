import * as Yup from "yup";

export const CreateTitle = Yup.object().shape({
    title_name: Yup.string().trim().required('Title Name is required'),
  })

export const CreateServiceSchema = Yup.object().shape({
    service_title: Yup.object().required("Service Title is reqired"),
    ck_data: Yup.string().nullable(),
    guidline: Yup.string()
  })

export const CreatePositionSchema = Yup.object().shape({
    position_name: Yup.string().trim().required('Position Name is required'),
    role_id: Yup.object().required('Role is required'),
  })