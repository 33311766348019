import ImagePreviewModal from "components/documentUpload/imagePreviewModal";
import React, { useState } from "react";
import { Image } from "react-bootstrap";

interface ImagesColumnProps {
  row: string[] | undefined;
}

const ImagesColumn: React.FC<ImagesColumnProps> = ({ row }) => {
  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  const [imgPreviewData, setImgPreviewData] = useState<string>(""); // Assuming imgPreviewData is a string for img_url

  const handleShowImg = (img: string) => {
    setImgPreviewData(img);
    setShowImgPreview(true);
  };

  return (
    <>
      {row?.map((img, index) => {
        if (img) {
          return (
            <Image
              key={index}
              src={img}
              alt=""
              onClick={() => handleShowImg(img)}
              style={{ cursor: "pointer", margin: "5px" }}
            />
          );
        }
      })}
      {showImgPreview && (
        <ImagePreviewModal
          data={{ img_url: imgPreviewData }} // Pass the data as expected by ImagePreviewModal
          show={showImgPreview}
          handleClose={() => setShowImgPreview(false)}
        />
      )}
    </>
  );
};

export default ImagesColumn;
