import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerProps } from "types/form";
import "assets/scss/datePicker.scss";

export const DatePickerReact: React.FC<DatePickerProps> = ({
  extraClass = "",
  control,
  name = "",
  id = "",
  className = "",
  value = "",
  placeholder = "",
  disabled = false,
  readonly = false,
  required = false,
  pattern,
  autoFocus = false,
  autoComplete = "on",
  onChange,
  label = "",
  errorMessage = "",
  isRequired = false,
  startDate =undefined,
  minDate =undefined,
  maxDate=undefined,
  isClearable=false,
  dateFormat="dd MMM yyyy",
  holidays=[]
}) => {
  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field }) => (
          <Form.Group className={`form-group ${extraClass}`}>
            {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
            <DatePicker
                dateFormat={dateFormat}
                isClearable={isClearable}
                disabled={disabled}
                holidays={holidays}
                // name="doj"
                selected={startDate}
                placeholderText={placeholder}
                onChange={(date:any) => onChange && onChange(date)}
                className="form-control"
                minDate={minDate}
                maxDate={maxDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                readOnly={readonly}
            />
            {errorMessage && (
              <div className="invalid-feedback d-block">{errorMessage}</div>
            )}
          </Form.Group>
        )}
      />
    </>
  );
};
