import React from "react";
import { Modal } from "react-bootstrap";
import { ModalProps } from "types/modal";
import "assets/scss/modal.scss";

const CommonModal: React.FC<ModalProps> = ({
  title,
  show,
  handleClose,
  children,
  dynamicCLass,
  modalSize='modal-xl'
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`${dynamicCLass} ${modalSize}`}
      backdrop="static"
    >
      <Modal.Header className="bg-purple border-0 d-flex align-items-center justify-content-between ">
        <h2 className="mb-0 text-white fw-600"> {title}</h2> 
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
export default CommonModal;
