import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { Button, Image } from "react-bootstrap";
import appImages from "utils/appImages";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import { useMutation } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import ConfirmModal from "common/confirmModal/confirmModal";
import { GlobalState } from "context/globalContext";
import { DeleteInstantTaskTitleAPI, DeleteTaskTitleAPI, GetInstantTaskTitleAPI, GetTaskTitleAPI } from "services/title";
import moment from "moment";
import CreateInstantTaskTitle from "components/manageTitle/instantTaskTitle/createInstantTaskTitle";
import ActionDropdown from "common/actionDropdown/actionDropdown";

const InstantTaskTitleList = () => {
  const { setBreadCrumb } = GlobalState();
  const rowsPerPage = 10;
  const [isCreateTitle, setIsCreateTitle] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [TitleData, setTitleData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<any>({});
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [editTitleData, setEditTitleData] = useState<string|null>(null);

  const breadcrumbList = [
    {
      title: "Instant Task Title",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "delete":
        data = {
          title: "Instant Task Title Deleted successfully",
          description: "Instant Task Title has been successfully deleted",
        };
        return data;
        case "edit":
        data = {
          title: "Instant Task Title Updated successfully",
          description: "Instant Task Title has been successfully updated",
        };
        return data;
      case "create":
        data = {
          title: "Instant Task Title Create successfully",
          description: "Instant Task Title has been successfully created",
        };
        return data;
      default:
        return null;
    }
  };

  const getTitleListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      pagination: 1,
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetTitleMutate(data);
  };

  useEffect(() => {
    getTitleListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const { isPending, mutate: GetTitleMutate } = useMutation({
    mutationFn: GetInstantTaskTitleAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setTitleData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const { isPending: DeletePending, mutate: DeleteMutate } = useMutation({
    mutationFn: DeleteInstantTaskTitleAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getTitleListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  const handleDelete = () => {
    setCurConfirmModal("delete");
    isShowConfirmModal("delete");
  };
  const handleEdit = (data?: any) => {
    setEditTitleData(data)
    setIsEditTitle(true)
    setCurConfirmModal("edit");
    isShowConfirmModal("edit");
  };

  const handleActionClick = (action: string, status?: any, id?: number, data?: any) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "delete":
        return handleDelete();
        case "edit":
        return handleEdit(data);
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
    },
    {
      id: 2,
      name: "Instant Task Title",
      selector: (row: any) => row?.instant_task_title,
      sortField: "instant_task_title",
      sortable: true,
    },
    {
      id: 3,
      name: "Date & Time",
      selector: (row: any) => moment(row?.created_at).format('DD MMMM YYYY, hh:mm A'),
      sortField: "created_at",
      sortable: true,
    },
    {
      id: 4,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id, row)
            }
            isVisibles={["edit", "delete"]}
            onToggleChange={(e: boolean) => isDropdownOpen(e)}
          />
        </>
      ),
      width: "150px",
    }
  ];

  const handleCloseCreateModal = () => {
    setIsCreateTitle(false);
    setIsEditTitle(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  return (
    <>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2>Instant Task Title</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Button variant="primary" onClick={() => setIsCreateTitle(true)}>
            Add Title
          </Button>
        </div>
      </div>
      <ReactDataTable
        dynamicClass={dropdownOpen ? "dropdown-open" : ""}
        columns={columns}
        tableData={TitleData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={TitleData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => setCurrentPage(page + 1)}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {(isCreateTitle || isEditTitle) && (
        <CreateInstantTaskTitle
          title={isCreateTitle ? "Add Instant Task" : "Edit Instant Task"}
          setShowSuccessModal={handleShowSuccessModal}
          callback={getTitleListData}
          show={isCreateTitle || isEditTitle}
          handleClose={handleCloseCreateModal}
          isCreateModal={isCreateTitle}
          data={editTitleData}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {showConfirmModal === "delete" && (
        <ConfirmModal
          show={showConfirmModal === "delete"}
          isLoading={DeletePending}
          onConfirm={() => DeleteMutate({ id: currentRow })}
          handleClose={handleCloseConfirmModal}
          type="delete"
          message={
            "Do you really want to delete this record? This process can not be undone."
          }
        />
      )}
    </>
  );
};

export default InstantTaskTitleList;
