import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetJobsiteAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/jobsite-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetJobsiteJobsiteDetailAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/jobsite-detail",
    method: "POST",
    body,
  });
  return response;
};

export const CreateJobsiteAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-jobsite",
    method: "POST",
    body,
  });
  return response;
};

export const EditJobsiteAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-jobsite",
    method: "POST",
    body,
  });
  return response;
};

export const GetJobsiteFormDataAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/get-jobsite-form-data",
    method: "GET",
  });
  return response;
};

export const GetJobsiteAreaListAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/area-list",
    method: "POST",
    body
  });
  return response;
};

export const JobsiteArchiveAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/manage-jobsite-archive",
    method: "POST",
    body
  });
  return response;
};

export const CreateAreaAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-area",
    method: "POST",
    body,
  });
  return response;
};

export const EditAreaAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-area",
    method: "POST",
    body,
  });
  return response;
};

export const AreaDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/area-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const AreaArchiveAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/manage-area-archive",
    method: "POST",
    body
  });
  return response;
};