import { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import "components/announcement/announcement.scss";
import appImages from "utils/appImages";
import { useLocation, useNavigate } from "react-router-dom";
import CreateAnnounceGroup from "components/announcement/createAnnounceGroup";
import AnnounceGroupValetListModal from "components/announcement/announceGroupValetList";
import SuccessModal from "common/modal/successModal/successModal";
import { useMutation } from "@tanstack/react-query";
import { AnnouncementDetailListAPI, GroupListAPI, GroupNameUpdateAPI } from "services/announcement";
import { toast } from "react-toastify";
import { ScalingLoader } from "common/loader/loader";
import AddAnnouncement from "./addAnnouncement";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GlobalState } from "context/globalContext";
import UpdateAnnounceGroupName from "./updateAnnounceGroupName";
import GroupMemberListModal from "./groupMemberList";

const Announcement = () => {
  const [searchGroup, setSearchGroup] = useState<string>("");
  const [isCreateAnnounce, setShowCreateAnnounce] = useState<boolean>(false);
  const [isCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const [isUpdateGroupName, setShowUpdateGroupName] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [isShowMultiSelectModal, setIsShowMultiSelectModal] =
    useState<boolean>(false);
  const [isShowGroupMemberList, setIsShowGroupMemberList] =
    useState<boolean>(false);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [curConfirmModal, setCurConfirmModal] = useState<string | number>("");
  const [groupData, setGroupData] = useState<any>({});
  const [groupList, setGroupList] = useState<any[]>([]);
  const [groupDetailList, setGroupDetailList] = useState<any[] | null>(null);
  const nevigate = useNavigate();
  const debouncedSearch = useDebouncedSearch(searchGroup, 600);
  const { setBreadCrumb } = GlobalState();
  const breadcrumbList = [
    {
      title: "Announcement",
      slug: "#",
    }
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string | number) => {
    let data: any = {};
    switch (key) {
      case "createAnnounceGroup":
        data = {
          title: "Group Create successfully",
          description: "Group has been successfully created",
        };
        return data;
      case "createAnnounce":
        data = {
          title: "Announcement Create successfully",
          description: "Announcement has been successfully created",
        };
        return data;
      case "updateGroupName":
        data = {
          title: "Group name Update successfully",
          description: "Group name has been successfully updated",
        };
        return data;
      default:
        return null;
    }
  };

  const { isPending: isGrouptListPending, mutate: GetGroupList } = useMutation({
    mutationFn: GroupListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setGroupList(data.data.result);
      setSelectedGroup(data.data.result?.find((el:any) => el.id===selectedGroup?.id))
    },
  });

  const {
    isPending: isAnnounceDetailListPending,
    mutate: GetAnnounceDetailList,
  } = useMutation({
    mutationFn: AnnouncementDetailListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setGroupDetailList(data.data.result);
    },
  });

  const handleSlectedGroup = (item: any) => {
    setSelectedGroup(item);
    GetAnnounceDetailList(groupList.find((el) =>el.announcement_group_id===item?.announcement_group_id));
  };

  useEffect(() => {
    GetGroupList(searchGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const handleShowSuccessModal = (status: boolean, slug: string | number) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  const CallbackState = (data: any) => {
    setGroupData(data);
    setIsShowMultiSelectModal(true);
  };

  return (
    <>
      {/* <Button variant="primary" onClick={() => setIsCreateTask(true)}>
            Add Task
          </Button>

          {isCreateAnnounce && (
        <AddAnnouncement
          title={"Add Announcement"}
        //   setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateAnnounce}
        //   callback={getTaskListData}
          show={isCreateAnnounce}
          handleClose={() => setIsCreateTask(false)}
        //   currentRow={currentRow}
        />
      )} */}
      <div className="inner-wrapper">
        <div className="inner-sidebar vertical-scrollbar">
          <div className="inner-sidebar-top-section">
            <div className="inner-sidebar-search">
              <Form.Group className={`form-group`}>
                <Form.Control
                  type="text"
                  name="sarch"
                  id="search"
                  value={searchGroup}
                  placeholder="Search"
                  // maxLength={20}
                  autoComplete="off"
                  onChange={(e: any) => {
                    // setCurrentPage(1);
                    setSearchGroup(e.target.value);
                  }}
                />
              </Form.Group>
            </div>
            <Button
              variant="primary"
              className="w-100"
              onClick={() => setShowCreateGroup(true)}
            >
              Create Group
            </Button>
          </div>
          <div className="inner-sidebar-listing">
            {isGrouptListPending ? (
              <span className="d-flex justify-content-center">
                <ScalingLoader />
              </span>
            ) : (
              <ul className="ps-0">
                {groupList?.map((item: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleSlectedGroup(item)}
                      className={`d-flex align-items-center cursor-pointer ${
                        selectedGroup?.announcement_group_id === item?.announcement_group_id ? "active" : "inactive"
                      }`}
                    >
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center gap-3">
                          <Image src={appImages.avatarImage} alt="Edit" />
                          <span>{item?.group_name}</span>
                        </div>
                        {/* <div className="menu-image">
                          <Image src={appImages.morebtnIcon} alt="Edit" />
                        </div> */}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        {/* <div className='inner-body-nofound d-flex align-items-center justify-content-center flex-column'>
          <Image src={appImages.noAnnouncementImage} alt="Edit" />
          <span>Company-wide announcement not found!</span>
        </div> */}
        <div className="inner-body">
          {selectedGroup ? (
            <>
              <div className="inner-header d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex align-items-center gap-3">
                    <div className="d-flex align-items-center profile gap-3">
                      {/* <div className="profile-image active"> */}
                      <div className="profile-image">
                        <Image src={appImages.avatarImage} alt="Edit" />
                      </div>
                      <span>{selectedGroup?.group_name}</span>
                    </div>
                    {selectedGroup?.is_group===1 &&
                      <span className="btn btn-link ms-1" onClick={() => setShowUpdateGroupName(true)}>Edit</span>
                    }
                  </div>
                  {selectedGroup?.is_group===1 &&
                  <Button variant="outline-primary" onClick={() => setIsShowGroupMemberList(true)}>
                    Group Members{" "}
                    <Image
                      className="img-left"
                      src={appImages.ArrowIcon}
                      alt=""
                      width={17}
                      height={17}
                    />
                  </Button>
                  }
                </div>
              </div>
              <div className="inner-body-content">
                <div className="announcement-listing-wrapper vertical-scrollbar">
                  {isAnnounceDetailListPending ? (
                    <span className="d-flex justify-content-center">
                      <ScalingLoader />
                    </span>
                  ) : (
                    <>
                      {groupDetailList &&
                        groupDetailList?.map((item: any, index: number) => (
                          <div key={index} className="media d-flex">
                            <div className="media-left">
                              <Image
                                src={appImages.AnnouncementnewtImage}
                                alt=""
                                width={30}
                                height={30}
                              />
                            </div>
                            <div className="media-right">
                              <div className="d-flex align-items-center justify-content-between media-header">
                                <h2 className="mb-0">
                                  {item?.announcement_title}
                                </h2>
                                <Image
                                  className="cursor-pointer"
                                  src={appImages.ArrowIcon}
                                  onClick={()=> nevigate(`/announcement-detail/${item?.id}`)}
                                  alt=""
                                  width={17}
                                  height={17}
                                />
                              </div>
                              <p className="mb-0">
                                {item?.announcement_description}
                              </p>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
                {/* Don't Remove */}
                {/* <div className='send-message'>
              <Textbox
                control={control}
                type="text"
                id="title"
                name="title"
                value=""
                placeholder={"Write your message"}
                autoComplete="off"
              // max={50}
              // errorMessage={errors.title && errors.title?.message}
              />
              <div className='input-links d-flex gap-3'>
                <Image src={appImages.linkIcon} alt="link" className='cursor-pointer'/>
                <Button variant="primary btn-sm">Send</Button>
              </div>
            </div> */}
                <div className="add-announcement">
                  <Image
                    onClick={() => setShowCreateAnnounce(true)}
                    src={appImages.addIcon}
                    alt="link"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="inner-body-nofound inner-body-content d-flex align-items-center justify-content-center flex-column ps-0">
              <Image src={appImages.noAnnouncementImage} alt="Edit" />
              <span>No selected</span>
              <div className="add-announcement">
                <Image
                  onClick={() => setShowCreateAnnounce(true)}
                  src={appImages.addIcon}
                  alt="link"
                  className="cursor-pointer"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isCreateGroup && (
        <CreateAnnounceGroup
          title={"Add Group Name"}
          callbackState={CallbackState}
          show={isCreateGroup}
          handleClose={() => {
            setShowCreateGroup(false);
          }}
        />
      )}
      {isUpdateGroupName && (
        <UpdateAnnounceGroupName
          title={"Update Group Name"}
          data={selectedGroup}
          callback={() => GetGroupList('')}
          setShowSuccessModal={handleShowSuccessModal}
          show={isUpdateGroupName}
          handleClose={() => {
            setShowUpdateGroupName(false);
          }}
        />
      )}
      {isShowMultiSelectModal && (
        <AnnounceGroupValetListModal
          title={`Create Group`}
          data={groupData}
          // callback={GetGroupList}
          setShowSuccessModal={handleShowSuccessModal}
          show={isShowMultiSelectModal}
          handleClose={() => setIsShowMultiSelectModal(false)}
        />
      )}
      {isShowGroupMemberList && (
        <GroupMemberListModal
          title={`Group Members`}
          data={selectedGroup}
          // callback={GetGroupList}
          setShowSuccessModal={handleShowSuccessModal}
          show={isShowGroupMemberList}
          handleClose={() => setIsShowGroupMemberList(false)}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {isCreateAnnounce && (
        <AddAnnouncement
          title={"Add Announcement"}
          setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateAnnounce}
          //   callback={getTaskListData}
          show={isCreateAnnounce}
          handleClose={() => setShowCreateAnnounce(false)}
          //   currentRow={currentRow}
        />
      )}
    </>
  );
};

export default Announcement;
