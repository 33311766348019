import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Form, Overlay, Tooltip } from "react-bootstrap";
import { TextBoxProps } from "types/form";
import "assets/scss/inputs.scss";

const Textbox: React.FC<TextBoxProps> = ({
  type = "text",
  icon,
  control,
  name = "",
  id = "",
  className = "",
  value = "",
  placeholder = "",
  min = 1,
  max,
  disabled = false,
  readonly = false,
  required = false,
  pattern,
  autoFocus = false,
  autoComplete = "on",
  onChange,
  onPaste,
  onKeyDown,
  onKeyUp,
  onBlur,
  label = "",
  errorMessage = "",
  isRequired = false,
  onFocus,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field }) => (
          <Form.Group className={`form-group`} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
            {isRequired && <span className="required-text fw-bold">*</span>}
            <Form.Control
              ref={target}
              type={type}
              name={name}
              id={id}
              className={`${className} ${errorMessage ? "" : ""}`}
              value={field.value || value}
              placeholder={placeholder}
              maxLength={max}
              minLength={min}
              min={0}
              disabled={disabled}
              readOnly={readonly}
              required={required}
              pattern={pattern}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              onChange={(e: any) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              onPaste={onPaste}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              form="novalidatedform"
              onFocus={onFocus}
            />
            {errorMessage && (
              <div className="invalid-feedback d-block">{errorMessage}</div>
            )}
            {disabled &&
            <Overlay target={target.current} show={showTooltip} placement="top">
              {(props) => (
                <Tooltip id="tooltip" {...props}>
                  {field.value || value}
                </Tooltip>
              )}
            </Overlay>
            }
          </Form.Group>
        )}
      />
    </>
  );
};

export default Textbox;
