import { useEffect } from "react";
import SystemAdminDashboard from "components/dashboard/systemAdminDashboard/systemAdminDashboard";
import { GlobalState } from "context/globalContext";

const Dashboard = () => {
  const { setBreadCrumb } = GlobalState();

  const breadcrumbList = [
    {
      title: "Dashboard",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SystemAdminDashboard />
    </>
  );
};

export default Dashboard;
