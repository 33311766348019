import { useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import { ScalingLoader, SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetObservationDetailAPI } from "services/observation";
import appImages from "utils/appImages";
import "assets/scss/media.scss";
import { GetDocumentDetailAPI } from "services/document";
import moment from "moment";
import MultiSelectListModal from "./multiSelectListModal";
import SuccessModal from "common/modal/successModal/successModal";

const DocumentDetail = () => {
  const { id } = useParams();
  const [copied, setCopied] = useState(false);
  const [shareModal, setCurentShareModal] = useState<string>('');
  const [curConfirmModal, setCurentConfirmModal] = useState<number|string>(0);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const { setBreadCrumb } = GlobalState();

  const breadcrumbList = [
    {
      title: "Document",
      slug: "/document",
    },
    {
      title: "Document Detail",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, setValue, watch } = useForm({});

  const { fetchStatus } = useQuery({
    queryKey: ["DocumentDetailData"],
    queryFn: async () => {
      try {
        const data = id && (await GetDocumentDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          setValue("id", result?.id);
          setValue("document_title", result?.document_title);
          setValue("jobsite", result?.jobsite_title);
          setValue("area", result?.area_name ?? '-');
          setValue("date", moment(result?.date).format("DD MMM, YYYY"));
          setValue("time", result?.time);
          setValue("description", result?.description);
          setValue("document_images", result?.document_images);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const ConfirmTitle = (key: number|string) => {
    let data: any = {};
    switch (key) {
      case 4:
        data = {
          title: "Document Shared successfully",
          description: "Document has been successfully shared to Regional Manager's",
        };
        return data;
      case 3:
        data = {
          title: "Document Shared successfully",
          description: "Document has been successfully shared to Client's",
        };
        return data;
      case 5:
        data = {
          title: "Document Shared successfully",
          description: "Document has been successfully shared to Valet's",
        };
        return data;
      default:
        return null;
    }
  };

  const GetShareModalTitle = (value: string) => {
    switch (value) {
      case "CLIENT":
        return "Client"
      case "RM":
        return "Regional Manager"
      case "VALET":
        return "Valet"
      default:
        return '';
    }
  }
  const GetRoleID = (value: string) => {
    switch (value) {
      case "CLIENT":
        return 3
      case "RM":
        return 4
      case "VALET":
        return 5
      default:
        return undefined;
    }
  }

  const handleShowSuccessModal = (status: boolean, slug: number|string) => {
    setIsShowSuccessModal(status);
    setCurentConfirmModal(slug);
  };

  const handleCloseShareModal = () => {
    setCurentShareModal('')
  }

  return (
    <>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          Document details
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="detail-form d-sm-flex d-block">
              <Form>
                <div className="position-relative form-group-icon">
                  <Textbox
                    control={control}
                    type="text"
                    id="id"
                    name="id"
                    value=""
                    label={"ID"}
                    placeholder={"ID"}
                    autoComplete="off"
                    disabled
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                  >
                    <Image
                      src={appImages.CopyIcon}
                      className="cursor-pointer"
                      alt="copy"
                      onClick={() => {
                        copy(watch("id"));
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    />
                  </OverlayTrigger>
                </div>
                <Textbox
                  control={control}
                  type="text"
                  id="date"
                  name="date"
                  value=""
                  label={"Date"}
                  placeholder={"Date"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="document_title"
                  name="document_title"
                  value=""
                  label={"Document Title"}
                  placeholder={"Document Title"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="jobsite"
                  name="jobsite"
                  value=""
                  label="Jobsite"
                  placeholder="Jobsite"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="area"
                  name="area"
                  value=""
                  label="Area"
                  placeholder="Area"
                  autoComplete="off"
                  disabled
                />
              </Form>
            </div>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-30">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div className="d-flex media">
              <div className="flex-shrink-0 media-left">
                <Image
                  src={appImages.DocumentDetailIcon}
                  alt=""
                  width={24}
                  height={24}
                />
              </div>
              <div className="flex-grow-1 card-title d-block media-right mb-0">
                <h2>{watch("document_title")}</h2>
                <p className="mb-0">{watch("description")}</p>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          Documents
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="observation-img-uploader">
              <div className="d-flex flex-wrap custom-select-wrapper">
                <div className="d-flex selected-image-wrapper">
                  {watch("document_images")?.map((image: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className="selected-image d-flex align-items-center justify-content-center mt-0"
                        style={{
                          backgroundImage: `url(${image?.document_image_url})`,
                        }}
                      ></div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      <div className="d-flex flex-wrap justify-content-center mb-lg-5 mb-3 gap-md-3 gap-2">
        <Button type="submit" variant="primary" onClick={() => setCurentShareModal('RM')}>
          {false && <SubmitLoader />} Share to RM
        </Button>
        <Button type="submit" variant="primary" onClick={() => setCurentShareModal('VALET')}>
          {false && <SubmitLoader />} Share to Valet
        </Button>
        <Button type="submit" variant="primary" onClick={() => setCurentShareModal('CLIENT')}>
          {false && <SubmitLoader />} Share to Client
        </Button>
      </div>

      {shareModal?.length > 0 && (
        <MultiSelectListModal
          title={GetShareModalTitle(shareModal)}
          role={GetRoleID(shareModal)}
          setShowSuccessModal={handleShowSuccessModal}
          show={shareModal?.length > 0}
          handleClose={handleCloseShareModal}
          documentID={id}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
    </>
  );
};

export default DocumentDetail;
