import { useMutation, useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import { ScalingLoader, SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, CardHeader, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import SuccessModal from "common/modal/successModal/successModal";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";
import { AreaDetailAPI, CreateAreaAPI, CreateJobsiteAPI, EditAreaAPI, GetJobsiteFormDataAPI, GetJobsiteJobsiteDetailAPI } from "services/jobsite";
import { CreateAreaProps, CreateJobsiteProps } from "types/jobsite";
import { CreateAreaSchema, CreateJobsiteSchema } from "utils/schema/jobsite";
import ReactGoogleMapComponent from "common/googleMap/googleMap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CreateArea = () => {
    const { state } = useLocation();
  const {id} = useParams();
  const navigate = useNavigate();
  const { setBreadCrumb } = GlobalState();
  const [curIndexChange, setCurIndexChange] = useState(0);
  const [polygonData, setPolygonData] = useState<any[]>([]);
  const [jobsitePolygonData, setJobsitePolygonData] = useState<any[]>(state?.jobsiteData ?? []);
  const [activePolygonData, setActivePolygonData] = useState<any[]>([]);
  const [markerData, setMarkerData] = useState<any>(null);
  const [addressData, setAddressData] = useState<any>(null);
  const [customeMapErr, setCustomeMapErr] = useState<any>(null);

  console.log('activePolygonData--', activePolygonData)
  console.log('markerData--', markerData)

  const breadcrumbList = [
    {
      title: "Jobsite Detail",
      slug: `/jobsite-detail/${state.id}`,
    },
    {
      title: `${!!id ? 'Edit' : "Create"} Area`,
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateAreaProps>({
    resolver: yupResolver(CreateAreaSchema),
  });

  const { isPending, mutate: CreateAreaMutate } = useMutation({
    mutationFn: CreateAreaAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data: any) => {
      toast.success(data?.data?.message);
      navigate(`/jobsite-detail/${state?.id}`);
    },
  });

  const { isPending:isPendingUpdate, mutate: EditAreaMutate } = useMutation({
    mutationFn: EditAreaAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data: any) => {
      toast.success(data?.data?.message);
      navigate(`/jobsite-detail/${state?.id}`);
    },
  });

  const { fetchStatus } = useQuery({
    queryKey: ["areaDetail"],
    enabled: !!id,
    queryFn: async () => {
      try {
        const data =
          id && (await AreaDetailAPI(id.toString()));
        const result = data && data?.data?.result;
        if (result) {
          const polygonData = result?.area_polygon_info?.map((item:any) => {
            return(
              {'lat': +item?.latitude, 'lng': +item?.longitude}
            )
          })
          setValue("area_name", result?.area_name);
          setValue("total_unit", result?.total_unit);
          setMarkerData({lat: +result?.latitude, lng: +result?.longitude});
          setActivePolygonData(polygonData);
          setPolygonData(polygonData);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });


  const onSubmit = (submitFormData: any, e: any) => {
    e.preventDefault();
    if (!polygonData && !markerData) {
      return setCustomeMapErr("Please draw polygon and marker on map");
    } else if (!polygonData) {
      return setCustomeMapErr("Please draw polygon on map");
    } else if (polygonData?.length<3) {
      return setCustomeMapErr("Please draw valid polygon with minimum 3 points");
    } else if (!markerData) {
      return setCustomeMapErr("Please draw marker on map");
    } else {
      setCustomeMapErr(null);
    }
    const polygon = polygonData.map((item: any) => {
      return (
        {latitude: item?.lat.toString(), longitude: item?.lng.toString()}
      )
    })
    const body = {
      id: id,
      jobsite_id: state?.id,
      area_name: submitFormData?.area_name,
      total_unit: submitFormData?.total_unit,
      latitude: markerData?.lat.toString(),
      longitude: markerData?.lng.toString(),
      points: polygon,
    };
    
    if (polygonData && markerData) {
        if(!!id){
            EditAreaMutate(body);
        } else {
            CreateAreaMutate(body);
        }
    }
  };

  const handlePolygonData = (data: any[]) => {
    setPolygonData(data);
  };
  const handleMarkerData = (data: any) => {
    setMarkerData(data);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Card className="mb-30">
          <CardHeader className="border-0 bg-purple text-white fw-600">
            Add Area
          </CardHeader>
          <Card.Body>
            {/* {fetchStatus==='fetching' ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : ( */}
              <>
                <div className="detail-form d-flex gap-2">
                  <Row>
                    <Col md={6}>
                      <Textbox
                        control={control}
                        type="text"
                        id="area_name"
                        name="area_name"
                        value=""
                        placeholder={"Area Name"}
                        autoComplete="off"
                        max={100}
                        errorMessage={
                          errors.area_name && errors.area_name?.message
                        }
                      />
                    </Col>
                    
                    <Col md={6}>
                      <Textbox
                        control={control}
                        type="number"
                        id="total_unit"
                        name="total_unit"
                        value=""
                        onPaste={(e) => e.preventDefault()}
                        onKeyDown={(e: any) => inputNumberHandleKeyDown(e)}
                        placeholder={"Total Unit"}
                        autoComplete="off"
                        max={10}
                        errorMessage={
                          errors.total_unit && errors.total_unit?.message
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <Textbox
                        control={control}
                        disabled={true}
                        type="number"
                        id="latitude"
                        name="latitude"
                        value={markerData?.lat}
                        placeholder={"Latitude"}
                        autoComplete="off"
                      />
                    </Col>
                    <Col md={6}>
                      <Textbox
                        control={control}
                        disabled={true}
                        type="number"
                        id="longitude"
                        name="longitude"
                        value={markerData?.lng}
                        placeholder={"Longitude"}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="google-map">
                  <div className="action-btn mb-1">
                    <Button
                      variant="outline-primary"
                      onClick={() => setCurIndexChange(curIndexChange + 1)}
                    >
                      Clear Polygon
                    </Button>
                    {customeMapErr && (
                    <div className="invalid-feedback d-block fs-6">
                      {customeMapErr}
                    </div>
                  )}
                  </div>
                  {fetchStatus!=="fetching" &&
                  <ReactGoogleMapComponent
                    resetPolygon={curIndexChange}
                    handlePolygonData={handlePolygonData}
                    handleAddressData={() => {}}
                    handleMarkerData={handleMarkerData}
                    polygonData={jobsitePolygonData}
                    activePolygonData={activePolygonData}
                    markerData={markerData}
                  />
                }
                </div>
              </>
            {/* )} */}
          </Card.Body>
        </Card>
        <div className="text-center mb-4">
          <Button type="submit" variant="primary" disabled={isPending || isPendingUpdate}>
            {(isPending || isPendingUpdate) && <SubmitLoader />}
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateArea;
