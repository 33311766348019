import * as Yup from "yup";

export const GenerateQrSchema = Yup.object().shape({
    jobsite: Yup.object()
    .required("Jobsite is required")
    .typeError("Jobsite is required"),
    qr_name: Yup.string().required('QR Name is required'),
    start_date: Yup.date().required("Start Date is required").typeError("Start Date is required"),
    end_date: Yup.date().required("End Date is required").typeError("End Date is required"),
  })

export const ActiveQrSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required").typeError("Start Date is required"),
    end_date: Yup.date().required("End Date is required").typeError("End Date is required"),
  })