import DashboardCard from "common/dashboardCard/dashboardCard";
import DetailTabs from "common/detailTabs/detailTabs";
import { ReactChart } from "common/reactChart/reactChart";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import "components/dashboard/systemAdminDashboard/systemAdminDashboard.scss";
import Select, { components } from "react-select";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Image,
  Nav,
  Row,
} from "react-bootstrap";
import { SortOrder, TableColumn } from "react-data-table-component";
import appImages from "utils/appImages";
import { useMutation } from "@tanstack/react-query";
import {
  GetDashboardTabsDataAPI,
  GetObservationReportAPI,
  GetSysAdminDashboardAPI,
} from "services/dashboard";
import { toast } from "react-toastify";
import { ScalingLoader } from "common/loader/loader";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { GetReportsAPI } from "services/reviewReport";
import JobSiteTable from "common/jobsiteTable/jobSiteTable";
import { NavLink } from "react-router-dom";
import ImagePreviewModal from "components/documentUpload/imagePreviewModal";
import { PerPageOptions } from "utils/constants/constant";
import CommonModal from "common/modal/commonModal";
import LiveTrackingMap from "common/googleMap/liveTrackingMap";
import { GetLiveLocationAPI } from "services/task";

const SystemAdminDashboard = () => {
  // react data table state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  // react data table state
  // observation table state
  const [observationCurrentPage, setObservationCurrentPage] =
    useState<number>(1);
  const [obsrvRowsPerPage, setObsrvRowsPerPage] = useState<number>(10);
  const [observationFilterDate, setObservationFilterDate] =
    useState<Date | null>(null);
  const [sortObsrvColumn, setObsrvSortColumn] = useState<any>({});
  const [obsrvReportData, setObsrvReportData] = useState<any>();
  // observation table state
  const [reportData, setReportData] = useState<any>();
  const [tabsData, setTabsData] = useState<any>();
  const [sortColumn, setSortColumn] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowTaskId, setSelectedRowTaskId] = useState(null);
  const [showJobSite, setShowJobSite] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [graphFilterDate, setGraphFilterDate] = useState<Date>(new Date());
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartEmpty, isChartEmpty] = useState<string | null>(null);
  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<any>();
  const [imgPreviewData, setImgPreviewData] = useState<any>({});
  const [showLiveLocation, setShowLiveLocation] = useState<boolean>(false);
  const [currentLocationId, setCurrentLocationId] = useState<any>(null);
  const [polygonData, setPolygonData] = useState<any[]>([]);
  const [markerData, setMarkerData] = useState<any>({});
  const [dateFilter, setFilter] = useState<any>({
    anniversary: "today",
    birthday: "today",
  });

  const { mutate: GetLiveLocationMutate } = useMutation({
    mutationFn: GetLiveLocationAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      const result = data && data?.data?.result;
      const polygonData = result?.job_site_info?.jobsite_polygon_info?.map(
        (item: any) => ({ lat: +item?.latitude, lng: +item?.longitude })
      );
      const areaPolygonData = result?.area_info?.area_polygon_info?.map(
        (item: any) => ({ lat: +item?.latitude, lng: +item?.longitude })
      );
      setMarkerData({
        lat: +result?.employee_task_info?.emp_cur_latitude,
        lng: +result?.employee_task_info?.emp_cur_longitude,
      });
      setPolygonData([polygonData, areaPolygonData]);
    },
  });

  useEffect(() => {
    let intervalId: any;

    if (showLiveLocation) {
      GetLiveLocationMutate({ task_id: currentLocationId });
      intervalId = setInterval(() => {
        GetLiveLocationMutate({ task_id: currentLocationId });
      }, 15000); // 15 seconds
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showLiveLocation]);

  const getReportListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      date:
        fromDate &&
        moment(fromDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD"),
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetReportsMutate(data);
  };

  useEffect(() => {
    getReportListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    fromDate,
  ]);

  const { isPending, mutate: GetReportsMutate } = useMutation({
    mutationFn: GetReportsAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setReportData(data.data.result);
      return data;
    },
  });

  const getObservationListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: obsrvRowsPerPage,
      date:
        observationFilterDate &&
        moment(observationFilterDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
          "YYYY-MM-DD"
        ),
      page: page ? page : observationCurrentPage,
      order_by_column: sortObsrvColumn?.column_name ?? column,
      order_by: sortObsrvColumn?.sort_dir ?? sortOrder,
    };
    GetObsrvListMutate(data);
  };

  useEffect(() => {
    getObservationListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    obsrvRowsPerPage,
    // currentPage,
    sortObsrvColumn?.column_name,
    sortObsrvColumn?.sort_dir,
    obsrvRowsPerPage,
    observationFilterDate,
  ]);

  const { isPending: isPendingObsrvList, mutate: GetObsrvListMutate } =
    useMutation({
      mutationFn: GetObservationReportAPI,
      onError: (error: any) => {
        toast.error(error?.data?.message);
        throw error;
      },
      onSuccess: (data: any) => {
        setObsrvReportData(data.data.result);
        return data;
      },
    });

  const { isPending: isPendingDashTabs, mutate: GetDashboardTabsDataMutate } =
    useMutation({
      mutationFn: GetDashboardTabsDataAPI,
      onError: (error: any) => {
        toast.error(error?.data?.message);
        throw error;
      },
      onSuccess: (data: any) => {
        setTabsData(data.data.result);
        return data;
      },
    });

  useEffect(() => {
    const body = {
      birthdate_filter: dateFilter?.birthday,
      anniversary_filter: dateFilter?.anniversary,
    };
    GetDashboardTabsDataMutate(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const handleShowImg = (img: any) => {
    setImgPreviewData({ img_url: img });
    setShowImgPreview(true);
  };

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };
  const handleObsrvSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setObsrvSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  useEffect(() => {
    isChartEmpty(null);
    const completedCount = +dashboardData?.task_data?.completed_count ?? 0;
    const pendingCount = +dashboardData?.task_data?.pending_count ?? 0;
    const acceptedCount = +dashboardData?.task_data?.accepted_count ?? 0;

    if (completedCount === 0 && pendingCount === 0 && acceptedCount === 0) {
      isChartEmpty("No Data Available!");
      setChartData([
        ["Task", "Hours per Day"],
        [`Task Completed`, completedCount],
        [`Task Pending`, pendingCount],
        [`Task Ongoing`, acceptedCount],
      ]);
    } else {
      isChartEmpty(null);
      setChartData([
        ["Task", "Hours per Day"],
        [`Task Completed`, completedCount],
        [`Task Pending`, pendingCount],
        [`Task Ongoing`, acceptedCount],
      ]);
      setIsChartLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const handleChangeFilter = (data: any) => {
    setFilter((oldData: any) => ({ ...oldData, ...data }));
  };

  const { isPending: isFetchingDashboard, mutate: GetDashboardData } =
    useMutation({
      mutationFn: GetSysAdminDashboardAPI,
      onError: (error: any) => {
        toast.error(error?.data?.message);
        throw error;
      },
      onSuccess: (data: any) => {
        setDashboardData(data.data.result);
        return data;
      },
    });

  useEffect(() => {
    const body = {
      task_date:
        graphFilterDate &&
        moment(graphFilterDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
          "YYYY-MM-DD"
        ),
    };
    GetDashboardData(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphFilterDate]);

  const PieOptions = {
    // title: "My Daily Activities",
    pieSliceText: "none",
    animation: { duration: 1000, easing: "out" },
    pieSliceBorderColor: "#9e9e9e",

    legend: {
      position: "right",
      alignment: "center",
      textStyle: {
        color: "black",
        // fontName: <string>,
        fontSize: 15,
      },
    },
    // sliceVisibilityThreshold: 0.2,
    // tooltip: { trigger: "none" },
    // pieStartAngle: 200,
    slices: {
      0: { color: "#1EDB6A" },
      1: { color: "#FFD260" },
      2: { color: "#716F71" },
    },
    pieHole: 0.7,
    is3D: false,
  };

  const handleDetailClick = (id: any, taskId: any) => {
    setSelectedRow(id);
    setSelectedRowTaskId(taskId);
    setShowJobSite(true);
  };

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  const handleChangeObsrvPerPage = (id: number) => {
    id && setObsrvRowsPerPage(id);
    setObservationCurrentPage(1);
  };

  const handleStatus = (key: string) => {
    switch (key) {
      case "0":
        return "Pending";
      case "1":
        return "Pending";
      case "3":
        return "Pending";
      case "4":
        return "Ongoing";
      case "2":
        return "Completed";
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Jobsite",
      selector: (row: any) => row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 3,
      name: "Task",
      selector: (row: any) =>
        row?.task_title?.length > 25
          ? row?.task_title.slice(0, 25) + "..."
          : row?.task_title,
      sortField: "task_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Employee",
      selector: (row: any) =>
        row?.valet_name?.length > 25
          ? row?.valet_name.slice(0, 25) + "..."
          : row?.valet_name,
      sortField: "valet_name",
      sortable: true,
      width: "9%",
    },
    // {
    //   id: 5,
    //   name: "Client",
    //   selector: (row: any) => row?.client_name ?? "-",
    //   sortField: "client_name",
    //   sortable: true,
    // },
    {
      id: 6,
      name: "Date",
      selector: (row: any) =>
        row?.start_datetime
          ? moment(row?.start_datetime).format("D MMM, YYYY")
          : "-",
    },
    {
      id: 7,
      name: "Jobsite Clock in Clock out",
      selector: (row: any) => (row?.jobsite_punch_in ? moment(row?.jobsite_punch_in).format("hh:mm A") : "-") +
      " to " +
      (row?.jobsite_punch_out ? moment(row?.jobsite_punch_out).format("hh:mm A") : "-"),
    },
    {
      id: 8,
      name: "Clock in Image",
      cell: (row: any) =>
        row.punch_in_image_url ? (
          <Image
            onClick={() => handleShowImg(row?.punch_in_image_url)}
            className="clock-img cursor-pointer"
            src={row.punch_in_image_url}
          />
        ) : (
          "-"
        ),
    },
    {
      id: 9,
      name: "Clock out Image",
      cell: (row: any) =>
        row.punch_out_image_url ? (
          <Image
            onClick={() => handleShowImg(row?.punch_out_image_url)}
            className="clock-img cursor-pointer"
            src={row.punch_out_image_url}
          />
        ) : (
          "-"
        ),
    },
    {
      id: 10,
      name: "Valet Location",
      cell: (row: any) => (
        <button
          style={{ border: "none", background: "transparent" }}
          disabled={row?.task_status !== "4"}
          onClick={() => {
            setShowLiveLocation(true);
            setCurrentLocationId(row?.id);
          }}
        >
          <Image
            className={`valet-location ${
              row?.task_status !== "4" ? "disabled-icon" : ""
            }`}
            src={appImages.EyeIcon}
          />
        </button>
      ),
      width: "7%",
    },
    {
      id: 11,
      name: "Official clock in/out",
      selector: (row: any) =>
        (row?.punch_in ? moment(row?.punch_in).format("hh:mm A") : "-") +
        " to " +
        (row?.punch_out ? moment(row?.punch_out).format("hh:mm A") : "-"),
    },
    {
      id: 12,
      name: "Status",
      cell: (row: any) => (
        <div
          className={
            handleStatus(row?.task_status)?.toLocaleLowerCase() +
            " status-tooltip"
          }
        >
          {handleStatus(row?.task_status)}
        </div>
      ),
      width: "130px",
    },
    {
      id: 13,
      name: "More",
      cell: (row: any) => (
        <Image
          className="cursor-pointer"
          onClick={() => handleDetailClick(row?.jobsite_id, row?.id)}
          src={appImages.RightDetailIcon}
          alt="edit"
          width={14}
          height={14}
        />
      ),
      width: "80px",
    },
  ];

  const obsrvColumns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Profile",
      cell: (row: any) => (
        <Image
          className="profile-img rounded-circle"
          src={row?.profile_image_url}
        />
      ),
      width: "90px",
    },
    {
      id: 3,
      name: "Valet Name",
      selector: (row: any) => row?.user_name,
      sortField: "user_name",
      sortable: true,
    },
    {
      id: 4,
      name: "Observations Date",
      selector: (row: any) =>
        row?.date ? moment(row?.date).format("D MMM, YYYY") : "-",
      sortField: "date",
      sortable: true,
      width: "12%",
    },
    {
      id: 5,
      name: "Observations Title",
      selector: (row: any) => row?.observation_title ?? "-",
      sortField: "observation_title",
      sortable: true,
      width: "12%",
    },
    {
      id: 6,
      name: "Jobsite",
      selector: (row: any) =>
        row?.jobsite_title?.length > 25
          ? row?.jobsite_title.slice(0, 25) + "..."
          : row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 7,
      name: "Area (Building)",
      selector: (row: any) =>
        row?.area_name
          ? row?.area_name?.length > 25
            ? row?.area_name.slice(0, 25) + "..."
            : row?.area_name
          : "-",
      sortField: "area_name",
      sortable: true,
    },
    {
      id: 8,
      name: "Unit",
      selector: (row: any) => row?.unit_no ?? "-",
      sortField: "unit_no",
      sortable: true,
    },
    {
      id: 9,
      name: "Image",
      cell: (row: any) =>
        row.observation_images?.length > 0 ? (
          <>
            <div className="gap-2 observation-images">
              {row?.observation_images
                ?.slice(0, 3)
                ?.map((item: any, index: number) => {
                  return (
                    <Image
                      onClick={() =>
                        handleShowImg(
                          row?.observation_images?.[0]?.observation_image_url
                        )
                      }
                      className="clock-img cursor-pointer"
                      loading="lazy"
                      key={index}
                      src={item?.observation_image_url}
                    />
                  );
                })}
              {row.observation_images?.length > 3 && (
                <span className="d-flex align-items-center justify-content-center">
                  +{row.observation_images?.length - 3}
                </span>
              )}
            </div>
          </>
        ) : (
          "-"
        ),
    },
    {
      id: 10,
      name: "Print",
      cell: (row: any) => (
        <Nav.Link
          as={NavLink}
          to={`/dashboard-print-observations-detail/${row?.id}`}
        >
          <Image
            className="valet-location cursor-pointer"
            src={appImages?.PrinterIcon}
          />
        </Nav.Link>
      ),
      width: "70px",
    },
    {
      id: 11,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link
            as={NavLink}
            to={`/dashboard-observations-detail/${row?.id}`}
          >
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  return (
    <>
      <Row>
        <Col xl={8} lg={12}>
          <div className="dashboard-cards-main d-grid mb-30">
            <DashboardCard
              navRoute={"/jobsite"}
              cardHeading={"Total Jobsites"}
              count={dashboardData?.count?.jobsite_count ?? 0}
              img={appImages.DashboardIcon01}
            />
            <DashboardCard
              navRoute={"/employee"}
              navState={{ role_id: 5 }}
              cardHeading={"Total Valet"}
              count={dashboardData?.count?.valet_count ?? 0}
              img={appImages.DashboardIcon02}
            />
            <DashboardCard
              navRoute={"/employee"}
              cardHeading={"Total Employee"}
              count={dashboardData?.count?.employee_count ?? 0}
              img={appImages.DashboardIcon03}
            />
            <DashboardCard
              navRoute={"/client"}
              cardHeading={"Total Client"}
              count={dashboardData?.count?.client_count ?? 0}
              img={appImages.DashboardIcon04}
            />
          </div>
          <div className="piechart mb-30">
            <Card className="overflow-visible">
              <CardBody>
                <CardTitle>
                  <h2>Number of task completed + task pending</h2>
                  <div className="form-group">
                    <ReactDatePicker
                      dateFormat={"dd MMM yyyy"}
                      selected={graphFilterDate}
                      placeholderText={"Date"}
                      onChange={(date: any) => {
                        setGraphFilterDate(date);
                      }}
                      className="form-control"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </CardTitle>
                {isChartLoading ||
                isFetchingDashboard ||
                chartData?.length === 0 ? (
                  <div className="d-flex justify-content-center">
                    <ScalingLoader />
                  </div>
                ) : (
                  <>
                    {chartEmpty && (
                      <div
                        className="d-flex justify-content-center"
                        // style={{ width: "100%", height: "284px" }}
                      >
                        {chartEmpty}
                      </div>
                    )}
                    <ReactChart
                      chartType="PieChart"
                      data={chartData}
                      options={PieOptions}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col xl={4} lg={12}>
          <DetailTabs
            loading={isPendingDashTabs}
            handleChangeFilter={handleChangeFilter}
            data={{
              anniversary: tabsData?.anniversary,
              birthday: tabsData?.birthday,
            }}
          />
        </Col>
        <Col md={12}>
          <div className="main-title d-md-flex d-block align-items-center justify-content-between">
            <h2>Employee work details</h2>
            <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
              <div className="form-group">
                <ReactDatePicker
                  dateFormat={"dd MMM yyyy"}
                  isClearable
                  selected={fromDate}
                  placeholderText={"Date"}
                  onChange={(date: any) => {
                    setCurrentPage(1);
                    setFromDate(date);
                  }}
                  className="form-control"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <Select
                isDisabled={isPending}
                getOptionLabel={(item: any) => item.name}
                getOptionValue={(item: any) => item.id}
                options={PerPageOptions}
                defaultValue={PerPageOptions[1]}
                components={{
                  SingleValue: ({ children, ...props }) => {
                    return (
                      <components.SingleValue {...props}>
                        {"Records per page: " + children}
                      </components.SingleValue>
                    );
                  },
                  Placeholder: ({ children, ...props }) => {
                    return (
                      <components.Placeholder {...props}>
                        {"Records per page: " + children}
                      </components.Placeholder>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                onChange={(selectedOption) => {
                  selectedOption && handleChangePerPage(selectedOption.id);
                }}
                placeholder={""}
                classNamePrefix="react-select"
                className="react-select-container"
                menuPlacement="bottom"
              />
            </div>
          </div>
          <ReactDataTable
            columns={columns}
            tableData={reportData?.data}
            rowsPerPage={rowsPerPage}
            totalRecord={reportData?.total}
            showPage={currentPage - 1}
            onPageChange={(page) => {
              setCurrentPage(page + 1);
              getReportListData(page + 1);
            }}
            inProgress={isPending}
            handleSort={handleSort}
          />
          {showJobSite && (
            <JobSiteTable
              show={showJobSite}
              title="Jobsite"
              handleClose={() => setShowJobSite(false)}
              id={selectedRow}
              taskId={selectedRowTaskId}
            />
          )}
        </Col>
        <Col md={12}>
          <div className="main-title d-md-flex d-block align-items-center justify-content-between">
            <h2>Observations as per clients</h2>
            <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
              <div className="form-group">
                <ReactDatePicker
                  dateFormat={"dd MMM yyyy"}
                  isClearable
                  selected={observationFilterDate}
                  placeholderText={"Date"}
                  onChange={(date: any) => {
                    setObservationCurrentPage(1);
                    setObservationFilterDate(date);
                  }}
                  className="form-control"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <Select
                isDisabled={isPending}
                getOptionLabel={(item: any) => item.name}
                getOptionValue={(item: any) => item.id}
                options={PerPageOptions}
                defaultValue={PerPageOptions[1]}
                components={{
                  SingleValue: ({ children, ...props }) => {
                    return (
                      <components.SingleValue {...props}>
                        {"Records per page: " + children}
                      </components.SingleValue>
                    );
                  },
                  Placeholder: ({ children, ...props }) => {
                    return (
                      <components.Placeholder {...props}>
                        {"Records per page: " + children}
                      </components.Placeholder>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                onChange={(selectedOption) => {
                  selectedOption && handleChangeObsrvPerPage(selectedOption.id);
                }}
                placeholder={""}
                classNamePrefix="react-select"
                className="react-select-container"
                menuPlacement="bottom"
              />
            </div>
          </div>
          <ReactDataTable
            columns={obsrvColumns}
            tableData={obsrvReportData?.data}
            rowsPerPage={obsrvRowsPerPage}
            totalRecord={obsrvReportData?.total}
            showPage={observationCurrentPage - 1}
            onPageChange={(page) => {
              setObservationCurrentPage(page + 1);
              getObservationListData(page + 1);
            }}
            inProgress={isPendingObsrvList}
            handleSort={handleObsrvSort}
          />
        </Col>
      </Row>
      {showImgPreview && (
        <ImagePreviewModal
          data={imgPreviewData}
          show={showImgPreview}
          handleClose={() => setShowImgPreview(false)}
        />
      )}
      {showLiveLocation && (
        <CommonModal
          title={"Live Tracking"}
          show={showLiveLocation && !!polygonData.length}
          handleClose={() => setShowLiveLocation(false)}
          dynamicCLass={"full-modal"}
        >
          <LiveTrackingMap polygonData={polygonData} markerData={markerData} />
        </CommonModal>
      )}
    </>
  );
};

export default SystemAdminDashboard;
