import { useMutation, useQuery } from "@tanstack/react-query";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import React, { useState } from "react";
import { Button, Form, Image, Modal, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { GetGroupMemberListAPI } from "services/announcement";
import { MultiSelectDocumentModalProps } from "types/modal";

const GroupMemberListModal: React.FC<MultiSelectDocumentModalProps> = ({
  title,
  show,
  handleClose,
  callback,
  setShowSuccessModal,
  data: propData,
}) => {
  console.log("propData--", propData);
  const [listingData, setLisingData] = useState<any>({});
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const { fetchStatus } = useQuery({
    queryKey: ["groupMemberData"],
    queryFn: async () => {
      try {
        const data = await GetGroupMemberListAPI({ group_id: propData?.id });
        setLisingData(data?.data?.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  return (
    <CommonModal
      title={title}
      show={show}
      handleClose={handleClose}
      dynamicCLass={"multi-select-modal"}
      modalSize={"modal-default"}
    >
      {fetchStatus === "fetching" ? (
        <div className="d-flex align-items-center justify-content-center p-3">
          <SpinerLoader />
        </div>
      ) : (
        <div className="multi-select-modal-body">
          <Form.Group controlId="formMultiSelect m-3">
          {listingData?.length>0 ?
            listingData?.map((user: any) => {
              return (
                <div className="d-flex align-items-center justify-content-between group-member-list">
                    <div className="member-detail">
                  <Image
                    loading="lazy"
                    className="profile-img rounded-circle me-2"
                    src={user?.user_info?.profile_image_url}
                    alt="profile"
                  />
                  {user?.user_info?.full_name &&
                  user?.user_info?.full_name?.length > 25
                    ? user?.user_info?.full_name?.slice(0, 25) + "..."
                    : user?.user_info?.full_name}
                    </div>
                    <Button variant="primary btn-sm">
                        Send Message
                    </Button>
                </div>
              );
            })
        : <span className="d-block text-center">No Records Found</span>}
          </Form.Group>
        </div>
      )}
    </CommonModal>
  );
};

export default GroupMemberListModal;
