import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalProps, SuccessModalProps } from "types/modal";
import appImages from "utils/appImages";
import "assets/scss/modal.scss";
import { SubmitLoader } from "common/loader/loader";

const ConfirmModal: React.FC<ModalProps> = ({
  title,
  message,
  isLoading,
  show,
  handleClose,
  onConfirm,
  type = "",
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className="bg-purple border-0 d-flex align-items-center justify-content-between">
        <h2 className="mb-0 text-white fw-600">Are you sure?</h2>
        <button
          type="button"
          className="btn-close p-0 m-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>{message}</p>
        <div className="d-flex justify-content-center gap-xxl-3 gap-2">
          <Button variant="secondary" className="btn-lg btn-outline-primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={onConfirm}
            variant="primary"
            className="btn-lg"
            disabled={isLoading}
          >
            {isLoading &&
              <SubmitLoader /> }
            { type === "delete" ? (
              "Delete"
            ) : (
              "Confirm"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmModal;
