import * as Yup from "yup";

export const EditObservationSchema = Yup.object().shape({
  // description: Yup.string().required("Description is required"),
  // unit: Yup.number().required("Unit is required").typeError("Unit is required").positive("Unit must be a positive number"),
  observation: Yup.object().required("Observation must be selected"),
  jobsite: Yup.object().required("Observation must be selected"),
  // area: Yup.object().required("Observation must be selected"),
  date: Yup.date().required("Date is required").typeError("Date is required"),
  time: Yup.date().required("Time is required").typeError("Time is required"),
});