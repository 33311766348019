import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GlobalState } from "context/globalContext";
import { GetReportsAPI } from "services/reviewReport";
import ReactDatePicker from "react-datepicker";
import JobSiteTable from "common/jobsiteTable/jobSiteTable";
import moment from "moment";
import ImagePreviewModal from "components/documentUpload/imagePreviewModal";

const ReviewReports = () => {
  const { setBreadCrumb } = GlobalState();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [reportData, setReportData] = useState<any>();
  const [sortColumn, setSortColumn] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowTaskId, setSelectedRowTaskId] = useState(null);
  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  const [showJobSite, setShowJobSite] = useState<boolean>(false);
  const [imgPreviewData, setImgPreviewData] = useState<any>({});

  const breadcrumbList = [
    {
      title: "Review Reports",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReportListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      date:
        fromDate &&
        moment(fromDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD"),
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetReportsMutate(data);
  };

  useEffect(() => {
    getReportListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    fromDate,
  ]);

  const { isPending, mutate: GetReportsMutate } = useMutation({
    mutationFn: GetReportsAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setReportData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const handleStatus = (key: string) => {
    switch (key) {
      case "0":
        return "Pending";
      case "1":
        return "Pending";
      case "3":
        return "Pending";
      case "4":
        return "Ongoing";
      case "2":
        return "Completed";
    }
  };

  const handleDetailClick = (id: any, taskId: any) => {
    setSelectedRow(id);
    setSelectedRowTaskId(taskId);
    setShowJobSite(true);
  };

  const handleShowImg = (img: any) => {
    setImgPreviewData({ img_url: img });
    setShowImgPreview(true);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Jobsite",
      selector: (row: any) => row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 3,
      name: "Task",
      selector: (row: any) =>
        row?.task_title?.length > 25
          ? row?.task_title.slice(0, 25) + "..."
          : row?.task_title,
      sortField: "task_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Employee",
      selector: (row: any) =>
        row?.valet_name?.length > 25
          ? row?.valet_name.slice(0, 25) + "..."
          : row?.valet_name,
      sortField: "valet_name",
      sortable: true,
    },
    {
      id: 5,
      name: "Client",
      selector: (row: any) => row?.client_name ?? '-',
      sortField: "client_name",
      sortable: true,
    },
    {
      id: 6,
      name: "Date",
      selector: (row: any) => row?.start_datetime ? moment(row?.start_datetime).format('D MMM, YYYY') : "-"
    },
    {
      id: 7,
      name: "Jobsite Clock in Clock out",
      selector: (row: any) => row?.start_time + " - " + row?.end_time,
    },
    {
      id: 8,
      name: "Clock in Image",
      cell: (row: any) => (
            row.punch_in_image_url ? <Image onClick={() => handleShowImg(row?.punch_in_image_url)} className="clock-img cursor-pointer" src={row.punch_in_image_url} /> : '-'
      ),
    },
    {
      id: 9,
      name: "Clock out Image",
      cell: (row: any) => (
        row.punch_out_image_url ? <Image onClick={() => handleShowImg(row?.punch_out_image_url)} className="clock-img cursor-pointer" src={row.punch_out_image_url} /> : '-'
      ),
    },
    {
      id: 10,
      name: "Valet Location",
      cell: (row: any) => (
        <Image className="valet-location cursor-pointer" src={appImages.EyeIcon} />
      ),
      width: "90px",
    },
    {
      id: 11,
      name: "Official clock in/out",
      selector: (row: any) => 
        (row?.punch_in ? moment(row?.punch_in).format('hh:mm A') : "-") + " to " + (row?.punch_out ? moment(row?.punch_out).format('hh:mm A') : "-"),
    },
    {
      id: 12,
      name: "Status",
      cell: (row: any) => (
        <div
          className={
            handleStatus(row?.task_status)?.toLocaleLowerCase() +
            " status-tooltip"
          }
        >
          {handleStatus(row?.task_status)}
        </div>
      ),
      width: "130px",
    },
    {
      id: 13,
      name: "More",
      cell: (row: any) => (
        <Image
          className="cursor-pointer"
          onClick={() => handleDetailClick(row?.jobsite_id, row?.id)}
          src={appImages.RightDetailIcon}
          alt="edit"
          width={14}
          height={14}
        />
      ),
      width: "80px",
    },
  ];

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Employee work details</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <div className="form-group">
            <ReactDatePicker
              disabled={isPending}
              dateFormat={"dd MMM yyyy"}
              isClearable
              selected={fromDate}
              placeholderText={"Date"}
              onChange={(date: any) => {
                setCurrentPage(1);
                setFromDate(date);
              }}
              className="form-control"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={reportData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={reportData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getReportListData(page+1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {showJobSite && (
        <JobSiteTable
          show={showJobSite}
          title="Jobsite"
          handleClose={() => setShowJobSite(false)}
          id={selectedRow}
          taskId={selectedRowTaskId}
        />
      )}
      {showImgPreview && (
        <ImagePreviewModal
          data={imgPreviewData}
          show={showImgPreview}
          handleClose={() => setShowImgPreview(false)}
        />
      )}
    </>
  );
};

export default ReviewReports;
