import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetSysAdminDashboardAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/dashboard",
    method: "POST",
    body,
  });
  return response;
};

export const GetObservationReportAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/observation-report",
    method: "POST",
    body,
  });
  return response;
};

export const GetDashboardTabsDataAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/dashboard-birthdays",
    method: "POST",
    body,
  });
  return response;
};