import { useMutation, useQuery } from "@tanstack/react-query";
import Textareabox from "common/form/textAreaBox";
import Textbox from "common/form/textBox";
import { ScalingLoader, SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, CardHeader, Col, Form, Image, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  GetObservationDetailAPI,
  GetObservationFormDataAPI,
  UpdateObservationAPI,
} from "services/observation";
import DatePicker from "react-datepicker";
import DragAndDropFileUpload from "common/dragFileUpload/dragFileUpload";
import { GetAreaListAPI } from "services/common";
import { EditObservationSchema } from "utils/schema/observation";
import { yupResolver } from "@hookform/resolvers/yup";
import SuccessModal from "common/modal/successModal/successModal";
import moment from "moment";
import "../../assets/scss/customSelect.scss";
import { FileError } from "react-dropzone";
import appImages from "utils/appImages";
import { EditObservationProps } from "types/observation";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";

const ObservationEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setBreadCrumb } = GlobalState();
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formData, setFormData] = useState<any>(null);
  const [areaData, setAreaData] = useState(null);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [imgUploadErr, setImgUploadErr] = useState("");
  const breadcrumbList = [
    {
      title: "Observations",
      slug: "/observation",
    },
    {
      title: "Observation Edit",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<EditObservationProps>({
    resolver: yupResolver(EditObservationSchema),
  });

  const { isPending, mutate: ObservationDetailMutate } = useMutation({
    mutationFn: GetObservationDetailAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      const result = data && data?.data?.result;
      if (result) {
        GetAreaMutate({ jobsite_id: [result?.jobsite?.id] });
        setUploadedFiles(
          result?.observation_images?.map(
            (item: any) => item?.observation_image_url
          )
        );
        setValue("observation", result?.observation_title);
        setValue("jobsite", result?.jobsite);
        setValue("area", result?.area);
        setValue("unit", result?.unit_no);
        setValue("description", result?.description);
        setValue("date", new Date(result?.date));
        setValue("time", moment(result?.time, "hh:mm A").toDate());
      }
    },
  });

  useEffect(() => {
    id && ObservationDetailMutate(id?.toString());
  }, []);

  const { isPending: isPendingFormData } = useQuery({
    queryKey: ["observationFormData"],
    queryFn: async () => {
      try {
        const data = await GetObservationFormDataAPI();
        setFormData(data.data.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });
  const { isPending: getAreaPending, mutate: GetAreaMutate } = useMutation({
    mutationFn: GetAreaListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setAreaData(data?.data?.result);
    },
  });
  const { isPending: updatePending, mutate: UpdateObservationMutate } =
    useMutation({
      mutationFn: UpdateObservationAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: () => {
        setSuccessModal(true);
        id && ObservationDetailMutate(id?.toString());
        setSelectedFiles([]);
      },
    });

  const handleUploadFiles = (files: any[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    id && formData.append("id", id);
    formData.append("observation_title_id", submitFormData?.observation?.id);
    if(submitFormData?.description) {
      formData.append("description", submitFormData?.description);
    }
    formData.append("jobsite_id", submitFormData?.jobsite?.id);
    if(submitFormData?.area){
      formData.append("area_id", submitFormData?.area?.id);
    }
    formData.append(
      "date",
      moment(submitFormData?.date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append("time", moment(submitFormData?.time).format("hh:mm A"));
    if(submitFormData?.unit){
      formData.append("unit", submitFormData?.unit);
    }
    if (selectedFiles && Array.isArray(selectedFiles)) {
      selectedFiles.forEach((image) => {
        formData.append("images[]", image);
      });
    }
    UpdateObservationMutate(formData);
  };

  const handleUploadValidate = (file: File): FileError | FileError[] | null => {
    // Example validation logic
    if (!file) {
      // console.log("no file uploaded!");
    }
    if (!file.type.startsWith("image/")) {
      setImgUploadErr("Only Support image Files only");
      console.warn("File type is not an image");
      return null; // Return null since it's not a validation error
    }
    setImgUploadErr("");
    // If validation passes, return null
    return null;
  };

  const removeSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="main-title">
          <h2>Edit Observation Detail</h2>
        </div>
        <Row className="mb-2">
          <Col md={3}>
            <Form.Group className={`form-group`}>
              <Controller
                name="observation"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isDisabled={isPendingFormData}
                      {...field}
                      getOptionLabel={(item: any) => item.observation_title}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.observationTitle ?? []}
                      isSearchable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Observation Title"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.observation && (
                      <div className="invalid-feedback d-block">
                        {errors.observation.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className={`form-group`}>
              <Controller
                name="jobsite"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isDisabled={true}
                      // isDisabled={isPendingFormData || getAreaPending}
                      {...field}
                      getOptionLabel={(item: any) => item.jobsite_title}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.jobSites ?? []}
                      isSearchable
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption);
                        setValue("area", {});
                        if (selectedOption) {
                          GetAreaMutate({
                            jobsite_id: [selectedOption?.id],
                          });
                        }
                      }}
                      placeholder={"Jobsite"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.jobsite && (
                      <div className="invalid-feedback d-block">
                        {errors.jobsite.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className={`form-group`}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isDisabled={isPendingFormData || getAreaPending}
                      {...field}
                      getOptionLabel={(item: any) => item.area_name}
                      getOptionValue={(item: any) => item.id}
                      options={areaData ?? []}
                      isSearchable
                      isClearable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Area"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.area && (
                      <div className="invalid-feedback d-block">
                        {errors.area.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Textbox
              control={control}
              type="number"
              id="unit"
              name="unit"
              value=""
              onPaste={(e) => e.preventDefault()}
              onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
              placeholder={"Unit"}
              autoComplete="off"
              max={10}
              // errorMessage={errors.unit && errors.unit?.message}
            />
          </Col>
          <Col md={12}>
            <Textareabox
              control={control}
              type="textarea"
              id="description"
              name="description"
              value=""
              // label="Description"
              placeholder={"Description"}
            />
          </Col>
        </Row>
        <Card className="mb-30">
          <CardHeader className="border-0 bg-purple text-white fw-600"></CardHeader>
          <Card.Body>
            {isPending ? (
              <div className="d-flex justify-content-center">
                <ScalingLoader />
              </div>
            ) : (
              <div className="observation-img-uploader">
                <div className="detail-form d-flex gap-2">
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <div className="form-group">
                        <DatePicker
                          selected={watch("date")}
                          id="date"
                          name="date"
                          disabled={true}
                          placeholderText="Date"
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          dateFormat={"dd MMM yyyy"}
                          className="form-control"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        {errors.date && (
                          <div className="invalid-feedback d-block">
                            {errors.date.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                  <Controller
                    name="time"
                    control={control}
                    render={({ field }) => (
                      <div className="form-group react-timepicker">
                        <DatePicker
                          selected={watch("time")}
                          onChange={(time: any) => {
                            field.onChange(time);
                          }}
                          disabled={true}
                          placeholderText="Time"
                          className="form-control"
                          autoComplete="off"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        {errors.time && (
                          <div className="invalid-feedback d-block">
                            {errors.time.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                {/* <div className="d-flex flex-wrap custom-select-wrapper">
                  <div className="d-block">
                    {`* You can upload Maximum ${
                      6 - uploadedFiles.length - selectedFiles?.length
                    } image *`}
                  </div>
                  <div className="d-flex selected-image-wrapper">
                    {uploadedFiles?.map((image: any, index:number) => {
                      return (
                        <div
                          key={index}
                          className="selected-image d-flex align-items-center justify-content-center"
                          style={{
                            backgroundImage: `url(${image?.observation_image_url})`,
                          }}
                        ></div>
                      );
                    })}
                    {selectedFiles?.map((file: any) => {
                      let imageURL = URL.createObjectURL(file);
                      return (
                        <div
                          className="selected-image d-flex align-items-center justify-content-center"
                          style={{ backgroundImage: `url(${imageURL})` }}
                        ></div>
                      );
                    })}
                  </div>
                  <DragAndDropFileUpload
                    handleValidate={handleUploadValidate}
                    uploadFiles={handleUploadFiles}
                    totalItems={uploadedFiles.length + selectedFiles?.length}
                    maxAllows={6 - uploadedFiles.length - selectedFiles?.length}
                  />
                </div> */}
                <div className="d-flex flex-column custom-select-wrapper mb-5">
                <div className="d-block">
                  {`* You can upload Maximum ${
                    6 - selectedFiles.length - uploadedFiles.length
                  } image *`}
                </div>
                <div className="d-flex selected-image-wrapper horizontal-scrollbar gap-2">
                  {uploadedFiles.length > 0 &&
                    uploadedFiles.map((img: any, index: number) => {
                      return (
                        <div key={index} className="d-flex flex-column">
                          <div
                            key={index}
                            className="selected-image d-flex align-items-center justify-content-center"
                            style={{ backgroundImage: `url(${img})` }}
                          ></div>
                        </div>
                      );
                    })}
                  {selectedFiles?.map((file: any, index) => {
                    let imageURL = URL.createObjectURL(file);
                    return (
                      <div className="d-flex flex-column">
                        <div
                          key={index}
                          className="selected-image d-flex align-items-center justify-content-center"
                          style={{ backgroundImage: `url(${imageURL})` }}
                        >
                          <div
                            className="remove-image d-flex align-items-center justify-content-center cursor-pointer"
                            onClick={() => removeSelectedImage(index)}
                          >
                            <Image
                              src={appImages.CloseImage}
                              alt="More"
                              width={10}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <DragAndDropFileUpload
                  handleValidate={handleUploadValidate}
                  uploadFiles={handleUploadFiles}
                  totalItems={selectedFiles?.length + uploadedFiles?.length}
                  maxAllows={6 - selectedFiles.length - uploadedFiles?.length}
                />
                <div className="invalid-feedback d-block">{imgUploadErr}</div>
              </div>
                <div className="invalid-feedback d-block">{imgUploadErr}</div>
              </div>
            )}
          </Card.Body>
        </Card>
        <div className="text-center mb-4">
          <Button type="submit" variant="primary" disabled={updatePending}>
            {updatePending && <SubmitLoader />} Save
          </Button>
        </div>
      </Form>
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => {setSuccessModal(false); navigate('/observation')}}
          title={"Observation Updated Successfully"}
          description={"Observation has been successfully updated"}
        />
      )}
    </>
  );
};

export default ObservationEdit;
