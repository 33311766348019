import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetProfileSysAdminAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/profile-user",
    method: "GET",
  });
  return response;
};

export const GetSysAdminProfileDataAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/get-user-form-data",
    method: "GET",
  });
  return response;
};

export const UpdateProfileSysAdminAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-profile-user",
    method: "POST",
    body
  });
  return response;
};

export const UpdatePasswordAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/change-password",
    method: "POST",
    body
  });
  return response;
};

export const ResetPasswordAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/reset-employee-password",
    method: "POST",
    body
  });
  return response;
};