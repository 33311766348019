import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetConcernListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/concern-list",
    method: "POST",
    body,
  });
  return response;
};

export const CreateConcernAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-concern",
    method: "POST",
    body,
  });
  return response;
};

export const EditConcernListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-concern",
    method: "POST",
    body,
  });
  return response;
};

export const DeleteConcernAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/delete-concern",
    method: "POST",
    body,
  });
  return response;
};

export const GetConcernFormDataAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/get-concern-form-data",
    method: "GET",
  });
  return response;
};

export const GetConcernDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/concern-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const AddConcernFeedbackAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/add-concern-feedback-emp`,
    method: "POST",
    body
  });
  return response;
};