import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Form, Image } from "react-bootstrap";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GlobalState } from "context/globalContext";
import { GetEmployeeAPI } from "services/employee";
import { GetPositionTitleAPI } from "services/common";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import ResetPasswordModal from "./resetPasswordModal";
import SuccessModal from "common/modal/successModal/successModal";

const PasswordList = () => {
  const { setBreadCrumb } = GlobalState();
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [passwordListData, setPasswordListData] = useState<any>();
  const [sortColumn, setSortColumn] = useState<any>({});
  const [positionList, setPositionList] = useState<any[]>([]);
  const [positionFilter, setPositionFilter] = useState<any>({});
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [resetPassModal, setResetPassModal] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<number>();

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "Manage Password",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "resetPassword":
        data = {
          title: "Password changed successfully",
          description: "Password has been successfully changed",
        };
        return data;
    }
  };

  const employeeListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search_term: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
      position_id: positionFilter?.id,
    };
    EmployeeListMutate(data);
  };

  useEffect(() => {
    employeeListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    debouncedSearch,
    positionFilter,
  ]);

  const { isPending, mutate: EmployeeListMutate } = useMutation({
    mutationFn: GetEmployeeAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setPasswordListData(data.data.result);
      return data;
    },
  });

  const { isPending: positionListPending, mutate: GetPositionList } =
    useMutation({
      mutationFn: GetPositionTitleAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setPositionList(data.data.result);
      },
    });

  useEffect(() => {
    GetPositionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: "80px",
    },
    {
      id: 2,
      name: "Profile",
      cell: (row: any) => (
        <Image
          className="profile-img rounded-circle"
          src={row?.profile_image_url}
        />
      ),
      width: "90px",
    },
    {
      id: 3,
      name: "Name",
      selector: (row: any) =>
        row?.full_name && row?.full_name?.length > 25
          ? row?.full_name.slice(0, 25) + "..."
          : row?.full_name,
      sortField: "first_name",
      sortable: true,
    },
    {
      id: 4,
      name: "Position",
      selector: (row: any) => row?.position_title,
      sortField: "position_title",
      sortable: true,
    },
    {
      id: 5,
      name: "Action",
      cell: (row: any) => (
        <>
          <button
            className="btn btn-table btn-primary"
            onClick={() => {
              setCurrentRow(row?.id);
              setResetPassModal(true);
            }}
          >
            Reset Password
          </button>
        </>
      ),
    },
  ];

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={positionListPending || isPending}
            getOptionLabel={(item: any) => item.position_title}
            getOptionValue={(item: any) => item.id}
            options={positionList}
            isClearable
            isSearchable
            onChange={(selectedOption) => {
              setCurrentPage(1);
              setPositionFilter(selectedOption);
            }}
            placeholder={"All"}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="text"
              name="sarch"
              id="search"
              value={search}
              placeholder="Search"
              autoComplete="off"
              onChange={(e: any) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      </div>
      <ReactDataTable
        columns={columns}
        tableData={passwordListData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={passwordListData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          employeeListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {resetPassModal && (
        <ResetPasswordModal
          title={"Reset Password"}
          currentRow={currentRow}
          setShowSuccessModal={handleShowSuccessModal}
          show={resetPassModal}
          handleClose={() => setResetPassModal(false)}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
    </>
  );
};

export default PasswordList;
