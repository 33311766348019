import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Form, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import ConfirmModal from "common/confirmModal/confirmModal";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GlobalState } from "context/globalContext";
import { DeleteQrAPI, GetQRAPI, QrStatusAPI } from "services/qr";
import { Switch } from "common/form/switch";
import moment from "moment";
import GenerateQR from "components/qrCode/generateQR";
import QrDetail from "components/qrCode/qrDetail";
import ActiveQRForm from "components/qrCode/activeQRForm";

const QrList = () => {
  const { setBreadCrumb } = GlobalState();
  const [isCreateQR, setIsCreateQR] = useState(false);
  const [isEditQR, setIsEditQR] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [isDisplayQR, setDisplayQR] = useState<boolean>(false);
  const [QRData, setQRData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [status, setStatus] = useState<boolean | null>(null);
  const [sortColumn, setSortColumn] = useState<any>({});

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "QR Codes",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "delete":
        data = {
          title: "QR Deleted successfully",
          description: "QR has been successfully deleted",
        };
        return data;
      case "create":
        data = {
          title: "QR Create successfully",
          description: "QR has been successfully created",
        };
        return data;
      case "update":
        data = {
          title: "QR Update successfully",
          description: "QR has been successfully updated",
        };
        return data;
      case "status":
        data = {
          title: "QR Status update successfully",
          description: "QR has been successfully update status",
        };
        return data;
      default:
        return null;
    }
  };

  const getQRListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetQRMutate(data);
  };

  useEffect(() => {
    getQRListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const { isPending, mutate: GetQRMutate } = useMutation({
    mutationFn: GetQRAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setQRData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const { isPending: DeletePending, mutate: DeleteMutate } = useMutation({
    mutationFn: DeleteQrAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getQRListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const { isPending: StatusPending, mutate: StatusMutate } = useMutation({
    mutationFn: QrStatusAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getQRListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  const handleDelete = () => {
    setCurConfirmModal("delete");
    isShowConfirmModal("delete");
  };

  const handleStatusChange = (status: boolean) => {
    setStatus(status);
    setCurConfirmModal("status");
    isShowConfirmModal("status");
  };

  const handleActionClick = (action: string, status?: any, id?: number) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "delete":
        return handleDelete();
      case "edit":
        return setIsEditQR(true);
      case "active":
        return handleStatusChange(status);
    }
  };

  const handleShowQR = (id: number) => {
    setCurrentRow(id);
    setDisplayQR(true);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
    },
    {
      id: 2,
      name: "QR Code Name",
      selector: (row: any) => row?.qr_code_name && row?.qr_code_name?.length > 25
      ? row?.qr_code_name.slice(0, 25) + "..."
      : row?.qr_code_name,
      sortField: "qr_code_name",
      sortable: true,
    },
    {
      id: 3,
      name: "Jobsite",
      selector: (row: any) => row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Date Range",
      selector: (row: any) =>
        moment(row?.start_date).format("DD MMM YYYY") +
        " to " +
        moment(row?.end_date).format("DD MMM YYYY"),
    },
    {
      id: 5,
      name: "QR Code",
      cell: (row: any) => (
        <Image
          className="valet-location cursor-pointer"
          src={appImages.EyeIcon}
          onClick={() => handleShowQR(row?.id)}
        />
      ),
    },
    {
      id: 6,
      name: "Status",
      cell: (row: any) => (
        <Switch
          checked={row?.status === "1" ? true : false}
          handleToggleSwitch={(status: boolean) =>
            handleActionClick("active", status, row?.id)
          }
          loader={StatusPending}
        />
      ),
    },
    {
      id: 7,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            status={row?.is_active === "1" ? true : false}
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id)
            }
            isVisibles={["edit", "delete"]}
            onToggleChange={(e: boolean) => isDropdownOpen(e)}
          />
        </>
      ),
      width: "150px",
    },
  ];

  const handleCloseCreateModal = () => {
    setIsCreateQR(false);
    setIsEditQR(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  }

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>QR Code List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id)
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="text"
              name="sarch"
              id="search"
              value={search}
              placeholder="Search"
              // maxLength={20}
              autoComplete="off"
              onChange={(e: any) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
          <Button variant="primary" onClick={() => setIsCreateQR(true)}>
            Generate Qr Code
          </Button>
        </div>
      </div>
      <ReactDataTable
        dynamicClass={dropdownOpen ? "dropdown-open" : ""}
        columns={columns}
        tableData={QRData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={QRData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getQRListData(page+1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {(isCreateQR || isEditQR) && (
        <GenerateQR
          title={isCreateQR ? "Generate QR Code" : "Edit QR Code"}
          setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateQR}
          callback={getQRListData}
          show={isCreateQR || isEditQR}
          handleClose={handleCloseCreateModal}
          currentRow={currentRow}
        />
      )}
      {(showConfirmModal==="status" && status===true) && (
        <ActiveQRForm
          title={"Select Date Range"}
          setShowSuccessModal={handleShowSuccessModal}
          callback={getQRListData}
          show={(showConfirmModal==="status" && status===true)}
          handleClose={() => isShowConfirmModal('')}
          currentRow={currentRow}
        />
      )}
      {isDisplayQR && (
        <QrDetail
          title={"QR Code"}
          show={isDisplayQR}
          handleClose={() => setDisplayQR(false)}
          currentRow={currentRow}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {showConfirmModal === "delete" && (
        <ConfirmModal
          show={showConfirmModal === "delete"}
          isLoading={DeletePending}
          onConfirm={() => DeleteMutate({ id: currentRow })}
          handleClose={handleCloseConfirmModal}
          type="delete"
          message={
            "Do you really want to delete this record? This process can not be undone."
          }
        />
      )}
      {(showConfirmModal==="status" && status===false) && (
        <ConfirmModal
          show={showConfirmModal === "status" && status===false}
          isLoading={StatusPending}
          onConfirm={() =>
            StatusMutate({
              id: currentRow,
              status: status ? "1" : "0",
            })
          }
          handleClose={handleCloseConfirmModal}
          message={"Do you really want to change status of this record?"}
        />
      )}
    </>
  );
};

export default QrList;
