import { ProtectedRoute } from "routes/protectedRoute";
import Dashboard from "components/dashboard/dashboard";
import Employee from "components/employeeProfile/employee";
import EmployeeDetail from "components/employeeProfile/employeeDetail";
import Client from "components/clientProfile/client";
import ClientDetail from "components/clientProfile/clientDetail";
import Observations from "components/observations/observations";
import ObservationDetail from "components/observations/observationDetail";
import ObservationEdit from "components/observations/observationEdit";
import Task from "components/manageTask/task";
import TaskDetail from "components/manageTask/taskdetail";
import ReviewReports from "components/reviewReports/reviewReport";
import QrList from "components/qrCode/qrList";
import TitleDashboard from "components/manageTitle/titleDashboard";
import ObservationTitleList from "components/manageTitle/observationTitle/observationTitleList";
import TaskTitleList from "components/manageTitle/taskTitle/taskTitleList";
import InstantTaskTitleList from "components/manageTitle/instantTaskTitle/instantTaskTitleList";
import ConcernTitleList from "components/manageTitle/concernTitle/concernTitleList";
import RegionTitleList from "components/manageTitle/regionTitle/regionTitleList";
import PositionNameList from "components/manageTitle/positionNameTitle/positionNameList";
import SysAdminProfileDetail from "components/manageProfile/sysAdminProfile/sysAdminProfileDetail";
import ClientPositionTitleList from "components/manageTitle/clientPositionTitle/clientPositionTitleList";
import CompanyTitleList from "components/manageTitle/companyTitle/companyTitleList";
import ConcernList from "components/manageConcern/concernList";
import ConcernDetail from "components/manageConcern/concernDetail";
import DocumentList from "components/documentUpload/documnetList";
import DocumentDetail from "components/documentUpload/documentDetail";
import SentDocumentList from "components/documentUpload/sentDocumentList";
import PasswordList from "components/managePassword/passwordList";
import Announcement from "components/announcement/announcement";
import AnnouncementDetail from "components/announcement/announcementDetail";
import GoogleMap from "common/googleMap/googleMap";
import JobsiteList from "components/manageJobsite/jobsiteList";
import CreateJobsite from "components/manageJobsite/createJobsite";
import JobsiteDetail from "components/manageJobsite/jobsiteDetail";
import CreateArea from "components/manageJobsite/createArea";
import AreaDetail from "components/manageJobsite/areaDetail";
import HolidayList from "components/manageHolidays/holidayList";
import ValetList from "components/manageValet/valetList";

const PrivateRoutes = [
  {
    path: "/dashboard",
    element: <ProtectedRoute component={Dashboard} />,
  },
  {
    path: "/employee",
    element: <ProtectedRoute component={Employee} />,
  },
  {
    path: "/employee-detail/:id",
    element: <ProtectedRoute component={EmployeeDetail} />,
  },
  {
    path: "/client",
    element: <ProtectedRoute component={Client} />,
  },
  {
    path: "/client-detail/:id",
    element: <ProtectedRoute component={ClientDetail} />,
  },
  {
    path: "/observation",
    element: <ProtectedRoute component={Observations} />,
  },
  {
    path: "/observation-walkthrough",
    element: <ProtectedRoute component={Observations} />,
  },
  {
    path: "/observation-lightaudit",
    element: <ProtectedRoute component={Observations} />,
  },
  {
    path: "/observation-detail/:id",
    element: <ProtectedRoute component={ObservationDetail} />,
  },
  {
    path: "/dashboard-observations-detail/:id",
    element: <ProtectedRoute component={ObservationDetail} />,
  },
  {
    path: "/dashboard-print-observations-detail/:id",
    element: <ProtectedRoute component={ObservationDetail} />,
  },
  {
    path: "/observation-edit/:id",
    element: <ProtectedRoute component={ObservationEdit} />,
  },
  {
    path: "/task",
    element: <ProtectedRoute component={Task} />,
  },
  {
    path: "/task-detail/:id",
    element: <ProtectedRoute component={TaskDetail} />,
  },
  {
    path: "/reviewReports",
    element: <ProtectedRoute component={ReviewReports} />,
  },
  {
    path: "/qr",
    element: <ProtectedRoute component={QrList} />,
  },
  {
    path: "/title",
    element: <ProtectedRoute component={TitleDashboard} />,
  },
  {
    path: "/observations-title",
    element: <ProtectedRoute component={ObservationTitleList} />,
  },
  {
    path: "/tasks-title",
    element: <ProtectedRoute component={TaskTitleList} />,
  },
  {
    path: "/instanttask-title",
    element: <ProtectedRoute component={InstantTaskTitleList} />,
  },
  {
    path: "/concerns-title",
    element: <ProtectedRoute component={ConcernTitleList} />,
  },
  {
    path: "/clientposition-title",
    element: <ProtectedRoute component={ClientPositionTitleList} />,
  },
  {
    path: "/company-title",
    element: <ProtectedRoute component={CompanyTitleList} />,
  },
  {
    path: "/region-title",
    element: <ProtectedRoute component={RegionTitleList} />,
  },
  {
    path: "/position-title",
    element: <ProtectedRoute component={PositionNameList} />,
  },
  {
    path: "/profile",
    element: <ProtectedRoute component={SysAdminProfileDetail} />,
  },
  {
    path: "/concern",
    element: <ProtectedRoute component={ConcernList} />,
  },
  {
    path: "/concern-detail/:id",
    element: <ProtectedRoute component={ConcernDetail} />,
  },
  {
    path: "/document",
    element: <ProtectedRoute component={DocumentList} />,
  },
  {
    path: "/sent-document",
    element: <ProtectedRoute component={SentDocumentList} />,
  },
  {
    path: "/document-detail/:id",
    element: <ProtectedRoute component={DocumentDetail} />,
  },
  {
    path: "/managePassword",
    element: <ProtectedRoute component={PasswordList} />,
  },
  {
    path: "/announcement",
    element: <ProtectedRoute component={Announcement} />,
  },
  {
    path: "/announcement-detail/:id",
    element: <ProtectedRoute component={AnnouncementDetail} />,
  },
  {
    path: "/jobsite",
    element: <ProtectedRoute component={JobsiteList} />,
  },
  {
    path: "/create-jobsite",
    element: <ProtectedRoute component={CreateJobsite} />,
  },
  {
    path: "/jobsite-edit/:id",
    element: <ProtectedRoute component={CreateJobsite} />,
  },
  {
    path: "/jobsite-detail/:id",
    element: <ProtectedRoute component={JobsiteDetail} />,
  },
  {
    path: "/create-jobsite-area",
    element: <ProtectedRoute component={CreateArea} />,
  },
  {
    path: "/edit-jobsite-area/:id",
    element: <ProtectedRoute component={CreateArea} />,
  },
  {
    path: "/jobsite-area-detail/:id",
    element: <ProtectedRoute component={AreaDetail} />,
  },
  {
    path: "/holiday",
    element: <ProtectedRoute component={HolidayList} />,
  },
  {
    path: "/valet",
    element: <ProtectedRoute component={ValetList} />,
  },
  {
    path: "/valet-detail/:id",
    element: <ProtectedRoute component={EmployeeDetail} />,
  },
];

export default PrivateRoutes;
