import { useMutation, useQuery } from "@tanstack/react-query";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import React, { useState } from "react";
import { Button, Form, Image, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { EmployeeByRoleAPI } from "services/common";
import { SentDocumentAPI } from "services/document";
import { MultiSelectDocumentModalProps } from "types/modal";

const MultiSelectListModal: React.FC<MultiSelectDocumentModalProps> = ({
  title,
  show,
  handleClose,
  role,
  setShowSuccessModal,
  documentID,
}) => {
  const [listingData, setLisingData] = useState<any[]>([]);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const { fetchStatus } = useQuery({
    queryKey: ["clientFormData"],
    enabled: !!role,
    queryFn: async () => {
      try {
        const data = await EmployeeByRoleAPI({ role_id: role });
        setLisingData(data.data.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: shareDocumentPending, mutate: ShareDocumentMutate } =
    useMutation({
      mutationFn: SentDocumentAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setShowSuccessModal && setShowSuccessModal(true, role ?? 0);
        handleClose();
      },
    });

  const handleSubmit = () => {
    const body = {
      id: documentID && documentID,
      receiver_id: selectedIds,
    };
    ShareDocumentMutate(body);
  };

  const toggleUserSelection = (userId: any) => {
    setSelectedIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  return (
    <CommonModal
      title={title}
      show={show}
      handleClose={handleClose}
      dynamicCLass={"multi-select-modal"}
      modalSize={"modal-default"}
    >
      {fetchStatus === "fetching" ? (
        <div className="d-flex align-items-center justify-content-center p-3">
          <SpinerLoader />
        </div>
      ) : (
        <>
          <div className="multi-select-modal-body vertical-scrollbar">
            <Form.Group controlId="formMultiSelect">
              {listingData?.map((user: any) => (
                <Form.Check
                  key={user.id}
                  type="checkbox"
                  className="d-flex align-items-center"
                  id={`user-checkbox-${user.id}`}
                  label={
                    <>
                      <Image loading="lazy" className="profile-img rounded-circle me-2" src={user?.profile_image_url} alt='profile' />
                      {user?.full_name && user?.full_name?.length > 25
                        ? user?.full_name?.slice(0, 25) + "..."
                        : user?.full_name}
                    </>
                  }
                  onChange={() => toggleUserSelection(user.id)}
                >
                </Form.Check>
              ))}
            </Form.Group>

          </div>
          <Modal.Footer>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={shareDocumentPending || selectedIds?.length < 1}
                onClick={handleSubmit}
              >
                {shareDocumentPending && <SubmitLoader />}
                Send
              </Button>
            </div>
          </Modal.Footer></>
      )}
    </CommonModal>
  );
};

export default MultiSelectListModal;
