import { RestrictSpaceRegex } from "utils/constants/constant";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email address is required")
    .matches(
      /^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/,
      "A valid email address is required"
    )
    .email("A valid email address is required")
    .min(5, "A minimum of 5 characters is required")
    .max(100, "A maximum of 100 characters is allowed"),

  password: Yup.string()
    .required("Passsword is required")
    .password()
    .minLowercase(1, "Password must contain at least 1 lowercase letter")
    .minUppercase(1, "Password must contain at least 1 uppercase letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 symbol")
    .min(8, "Password must be at least 8 characters long")
    .max(64, "Password cannot be longer than 64 characters"),
});

export const ForgotPassSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email address is required")
    .matches(
      /^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/,
      "A valid email address is required"
    )
    .email("A valid email address is required")
    .min(5, "A minimum of 5 characters is required")
    .max(100, "A maximum of 100 characters is allowed"),
});

export const ResetPassSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("New Passsword is required")
    .matches(RestrictSpaceRegex, "New Password cannot contain spaces")
    .password()
    .minLowercase(
      1,
      "New Password must contain at least 1 lowercase letter"
    )
    .minUppercase(
      1,
      "New Password must contain at least 1 uppercase letter"
    )
    .minNumbers(1, "New Password must contain at least 1 number")
    .minSymbols(1, "New Password must contain at least 1 symbol")
    .min(8, "New Password must be at least 8 characters long")
    .max(64, "New Password cannot be longer than 64 characters"),

  confirm_password: Yup.string()
    .required("Please confirm your password")
    .test(
      "passwords-match",
      "Confirm password should match with New password",
      function (value) {
        return this.parent.new_password === value;
      }
    ),
});
