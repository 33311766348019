import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Form, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import ConfirmModal from "common/confirmModal/confirmModal";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GlobalState } from "context/globalContext";
import ReactDatePicker from "react-datepicker";
import {
  GetObservationAPI,
  GetObservationFormDataAPI,
  ObservationApproveAPI,
  SendObservationApi,
  WalkthroughObservationApi,
} from "services/observation";
import moment from "moment";
import { GetAreaListAPI } from "services/common";
import { SubmitLoader } from "common/loader/loader";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";
// import FilterPopup from "components/observations/filterPopup";

const Observations = () => {
  const { setBreadCrumb } = GlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [showSuccessModal, setIsShowSuccessModal]= useState<boolean>(false);
  const [observationData, setObservationData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<any>({});
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [formData, setFormData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  console.log('selectedRows--', selectedRows)
  const [multiBtnActive, setMultiBtnActive] = useState<boolean>(false);
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false);

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "Observations",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setClearSelectedRows(!clearSelectedRows);
    setSelectedRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleRowSelected = (rows: any) => {
    setSelectedRows(rows?.selectedRows.map((el: any) => el.id));
  };

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "approve":
        data = {
          title: "Approved successfully",
          description: "Record has been successfully Approved",
        };
        return data;
      case "walkthrough":
        data = {
          title: "Walkthrough successfully",
          description: "Record has been successfully moved to Walkthrough",
        };
        return data;
      default:
        return null;
    }
  };

  const getObservationListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      observation_type: location.pathname.split('/').includes('observation-walkthrough') ? 3 : location.pathname.split('/').includes('observation-lightaudit') ? 2 : 1,
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      unit: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
      date:
        fromDate &&
        moment(fromDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD"),
      observation_title_id: filter?.reason?.id,
      area_id: filter?.area?.id,
      jobsite_id: filter?.jobsite?.id,
    };
    GetObservationMutate(data);
  };

  useEffect(() => {
    getObservationListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    fromDate,
    filter,
    location.pathname
  ]);

  const { isPending, mutate: GetObservationMutate } = useMutation({
    mutationFn: GetObservationAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setObservationData(data.data.result);
      return data;
    },
  });

  const { isPending: isPendingFormData } = useQuery({
    queryKey: ["observationFormData"],
    queryFn: async () => {
      try {
        const data = await GetObservationFormDataAPI();
        setFormData(data.data.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });
  const { isPending: getAreaPending, mutate: GetAreaMutate } = useMutation({
    mutationFn: GetAreaListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      // setAreaData(data?.data?.result);
      setFormData({ ...formData, areaData: data?.data?.result });
    },
  });
  const { isPending: sendObservationLoading, mutate: SendObservationMutate } =
    useMutation({
      mutationFn: SendObservationApi,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        getObservationListData();
        toast.success(data.data.message);
      },
    });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const { isPending: ApprovePending, mutate: ApproveMutate } = useMutation({
    mutationFn: ObservationApproveAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
      isShowConfirmModal("");
    },
    onSuccess: () => {
      getObservationListData();
      setClearSelectedRows(!clearSelectedRows);
      setSelectedRows([]);
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
      setMultiBtnActive(false);
    },
  });
  const { isPending: WalkthroughPending, mutate: WalkthroughMutate } = useMutation({
    mutationFn: WalkthroughObservationApi,
    onError: (error: any) => {
      toast.error(error.data.message);
      isShowConfirmModal("");
    },
    onSuccess: () => {
      getObservationListData();
      setClearSelectedRows(!clearSelectedRows);
      setSelectedRows([]);
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
      setMultiBtnActive(false);
    },
  });

  const handleApprove = () => {
    setCurConfirmModal("approve");
    isShowConfirmModal("approve");
  };

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  const handleActionClick = (
    action: string,
    status?: any,
    id?: number,
    archive?: string
  ) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "approve":
        return handleApprove();
      case "edit":
        return navigate(`/observation-edit/${id}`);
    }
  };

  const handleObservationStatus = (key: number) => {
    switch (key) {
      case 1:
        return "Pending";
      case 2:
        return "Approved";
      case 3:
        return "Reject";
      case 4:
        return "Sent";
    }
  };

  const handleMultiApprove = () => {
    if(selectedRows?.length > 0){
      setMultiBtnActive(true);
      setCurConfirmModal("approve");
      isShowConfirmModal("approve");
    }
  };

  const handleWalkthrough = () => {
    if(selectedRows?.length > 0){
      setMultiBtnActive(true);
      setCurConfirmModal("walkthrough");
      isShowConfirmModal("walkthrough");
    }
  };

  const handleSendIssue = (id: number) => {
    setCurrentRow(id);
    SendObservationMutate({ id: [id] });
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: '80px'
    },
    {
      id: 3,
      name: "Valet Name",
      selector: (row: any) => row?.user_name && row?.user_name?.length>25 ? row?.user_name.slice(0, 25)+'...' : row?.user_name,
      sortField: "user_name",
      sortable: true,
    },
    {
      id: 4,
      name: "Date",
      selector: (row: any) => moment(row?.date).format("DD-MM-YYYY"),
    },
    {
      id: 5,
      name: "Observation Title",
      selector: (row: any) => row?.observation_title && row?.observation_title?.length>25 ? row?.observation_title.slice(0, 25)+'...' : row?.observation_title,
      sortField: "observation_title",
      sortable: true,
    },
    {
      id: 6,
      name: "Jobsite",
      selector: (row: any) => row?.jobsite_title,
      sortField: "jobsite_title",
      sortable: true,
    },
    {
      id: 7,
      name: "Area",
      selector: (row: any) => row?.area_name ?? '-',
      sortField: "area_name",
      sortable: true,
    },
    {
      id: 8,
      name: "Unit",
      selector: (row: any) => row?.unit_no ?? '-',
      width: '80px'
    },
    {
      id: 9,
      name: "Status",
      cell: (row: any) => (
        <div
          className={
            handleObservationStatus(
              row?.observation_status
            )?.toLocaleLowerCase() + " status-tooltip"
          }
        >
          {handleObservationStatus(row?.observation_status)}
        </div>
      ),
      width: "120px",
    },
    {
      id: 10,
      name: "Send issue",
      cell: (row: any) => (
        <button
          className="btn btn-table btn-primary"
          disabled={row?.send_status !== "0"}
          onClick={() => handleSendIssue(row?.id)}
        >
          {(currentRow === row?.id && sendObservationLoading) && <SubmitLoader />}{" "}
          Send
        </button>
      ),
      width: "130px",
    },
    {
      id: 11,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            status={row?.is_active === "1" ? true : false}
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id, row.is_archived)
            }
            isVisibles={["edit", row?.observation_status!==2 ? "approve" : '']}
            onToggleChange={(e: boolean) => isDropdownOpen(e)}
          />
        </>
      ),
      width: '7%'
    },
    {
      id: 12,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link as={NavLink} to={`/observation-detail/${row?.id}`}>
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  // const handleFilterPopup = (data:any) => {
  //   setCurrentPage(1);
  //   setFilter({ ...filter, reason: data?.reason, jobsite: data?.jobsite, area: data?.area });
  //   setSearch(data?.unit)
  //   data?.date && setFromDate(data?.date)
  //   setShowFilter(false);
  // }

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  }

  const handleResetFilter = () => {
    setClearSelectedRows(!clearSelectedRows);
    setSelectedRows([]);
    setCurrentPage(1)
    setSearch('')
    setFilter({ ...filter, reason: null, area: null, jobsite: null })
    setFromDate(null)
  }

  return (
    <>
      <div className="main-title">
        <h2 className="mb-3">Observations{!!location.pathname.split('-')?.[1] && ' - ' + location.pathname.split('-')?.[1]?.charAt(0).toUpperCase() + location.pathname.split('-')?.[1]?.slice(1)}</h2>
        <div className="d-flex align-items-center flex-wrap gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            value={filter?.reason}
            isDisabled={isPendingFormData || isPending}
            getOptionLabel={(item: any) => item.observation_title}
            getOptionValue={(item: any) => item.id}
            options={formData?.observationTitle}
            isClearable
            isSearchable
            onChange={(selectedOption) => {
              setCurrentPage(1)
              setFilter({ ...filter, reason: selectedOption });
            }}
            placeholder={"Reason"}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Select
            value={filter?.jobsite}
            isDisabled={isPendingFormData || isPending}
            getOptionLabel={(item: any) => item.jobsite_title}
            getOptionValue={(item: any) => item.id}
            options={formData?.jobSites}
            isClearable
            isSearchable
            onChange={(selectedOption) => {
              setCurrentPage(1)
              setFilter({ ...filter, jobsite: selectedOption, area: null });
              if (selectedOption) {
                GetAreaMutate({
                  jobsite_id: [selectedOption?.id],
                });
              }
            }}
            placeholder={"Jobsite"}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Select
            value={filter?.area}
            isDisabled={isPendingFormData || isPending}
            getOptionLabel={(item: any) => item.area_name}
            getOptionValue={(item: any) => item.id}
            options={formData?.areaData}
            isClearable
            isSearchable
            onChange={(selectedOption) => {
              setCurrentPage(1)
              setFilter({ ...filter, area: selectedOption });
            }}
            placeholder={"Area"}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="number"
              name="unit"
              id="unit"
              value={search}
              placeholder="Unit"
              maxLength={20}
              autoComplete="off"
              onPaste={(e) => e.preventDefault()}
              onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
              onChange={(e: any) => {
                setCurrentPage(1)
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
          <div className="form-group">
            <ReactDatePicker
              disabled={isPending}
              dateFormat={"dd MMM yyyy"}
              isClearable
              selected={fromDate}
              placeholderText={"Date"}
              onChange={(date: any) => {
                setCurrentPage(1)
                setFromDate(date)
              }}
              className="form-control"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id)
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Button variant="secondary" className="btn-outline-primary" onClick={handleResetFilter}>
            Reset
          </Button>
          {/* <Button variant="primary" onClick={() => setShowFilter(true)}>
            Filter
          </Button> */}
          {!location.pathname.split('/').includes('observation-walkthrough') &&
          <Button variant="primary" onClick={handleWalkthrough} disabled={selectedRows?.length < 1}>
            Walkthrough
          </Button>
          }
          <Button variant="primary" onClick={handleMultiApprove} disabled={selectedRows?.length < 1}>
            Approve
          </Button>
        </div>
      </div>
      <ReactDataTable
        dynamicClass={dropdownOpen ? "dropdown-open" : ""}
        columns={columns}
        tableData={observationData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={observationData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setSelectedRows([]);
          setClearSelectedRows(!clearSelectedRows);
          setCurrentPage(page + 1)
          getObservationListData(page + 1)
        }}
        inProgress={isPending}
        handleSort={handleSort}
        handleRowSelected={handleRowSelected}
        isSelectableRow={true}
        clearSelectedRows={clearSelectedRows}
      />
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {showConfirmModal === "approve" && (
        <ConfirmModal
          show={showConfirmModal === "approve"}
          onConfirm={() =>
            ApproveMutate({
              id: multiBtnActive ? selectedRows : [currentRow],
            })
          }
          isLoading={ApprovePending}
          handleClose={handleCloseConfirmModal}
          message={"Do you really want to approve this record?"}
        />
      )}
      {showConfirmModal === "walkthrough" && (
        <ConfirmModal
          show={showConfirmModal === "walkthrough"}
          onConfirm={() =>
            WalkthroughMutate({id: selectedRows})
          }
          isLoading={WalkthroughPending}
          handleClose={handleCloseConfirmModal}
          message={"Do you really want to move record to Walkthrough?"}
        />
      )}
      {/* {showFilter && (
        <FilterPopup
          data={{...filter, search: search, fromDate: fromDate}}
          show={showFilter}
          handleClose={() => setShowFilter(false)}
          formData={formData}
          callbackAreaApi={GetAreaMutate}
          handleFilterPopup={handleFilterPopup}
        />
      )} */}
    </>
  );
};

export default Observations;
