import Logo from "assets/images/logo.svg";
import LogoSm from "assets/images/logo_sm.svg";
import DashboardIcon from "assets/images/dashboard.svg";
import ProfileIcon from "assets/images/profile.svg";
import JobsiteIcon from "assets/images/jobsite.svg";
import ObservationIcon from "assets/images/observation.svg";
import DocumentIcon from "assets/images/document.svg";
import ReportIcon from "assets/images/report.svg";
import QrcodeIcon from "assets/images/qr_code.svg";
import ConcernIcon from "assets/images/concern.svg";
import SupplyrequestIcon from "assets/images/supply_request.svg";
import MessageIcon from "assets/images/message.svg";
import AnnouncementIcon from "assets/images/announcement.svg";
import NotificationIcon from "assets/images/notification.svg";
import KeyIcon from "assets/images/key.svg";
import LogoutIcon from "assets/images/logout.svg";
import ArrowIcon from "assets/images/arrow.svg";
import UserIcon from "assets/images/user.svg";
import CloseIcon from "assets/images/cross_btn.png";
import SuccessIcon from "assets/images/success_icon.svg";
import ErrorIcon from "assets/images/error_icon.svg";
import EllipsIcon from "assets/images/ellipse_icon.svg";
import RightDetailIcon from "assets/images/right_detail_arrow.svg";
import paginationarrowIcon from "assets/images/pagination_arrow.svg";
import EditIcon from "assets/images/edit.svg";
import ArchiveIcon from "assets/images/archive.svg";
import DeleteIcon from "assets/images/delete.svg";
import UserimageIcon from "assets/images/user_image.svg";
import MailIcon from "assets/images/mail.svg";
import LockIcon from "assets/images/lock.svg";
import CalendarIcon from "assets/images/calendar_icon.svg";
import SortIcon from "assets/images/sort_icon.svg";
import EyeIcon from "assets/images/eye_icon.svg";
import QrimgIcon from "assets/images/qr_img.png";
import Eye from "assets/images/eye.svg";
import EyeSlash from "assets/images/slash_eye.svg";
import CopyIcon from "assets/images/copy_icon.svg";
import LoginLogo from "assets/images/login_logo.svg";
import UploadIcon from "assets/images/upload_icon.svg";
import RightSignIcon from "assets/images/right_sign.svg";
import TaskDetailIcon from "assets/images/task-detail-img.svg";
import ConcernDetailIcon from "assets/images/concern-detail-img.svg";
import DocumentDetailIcon from "assets/images/document-detail-img.svg";
import DeleteRedIcon from "assets/images/delete_red.svg";
import PrinterIcon from "assets/images/printer-img.svg";
import HolidayIcon from "assets/images/holiday.svg";
import ManageValetIcon from "assets/images/manage_valet.svg";

import ToggleIcon from "assets/images/toggle.svg";

import DashboardIcon01 from "assets/images/dashboard_icon01.svg";
import DashboardIcon02 from "assets/images/dashboard_icon02.svg";
import DashboardIcon03 from "assets/images/dashboard_icon03.svg";
import DashboardIcon04 from "assets/images/dashboard_icon04.svg";


import ProfileImage from "assets/images/profile-img.svg";
import UserImage from "assets/images/user_image.svg";
import CloseImage from "assets/images/close.svg";
import arrowImage from "assets/images/submenu_dropdown.svg";
import leftImage from "assets/images/breadcrumb_arrow.svg";
import observationImage from "assets/images/observation_image.png";
import notfoundImage from "assets/images/404.svg";
import clockImage from "assets/images/clock_icon.svg";
import avatarImage from "assets/images/avatar_icon.svg";
import morebtnIcon from "assets/images/more_btn.svg";
import noAnnouncementImage from "assets/images/no_announcement.svg";
import AnnouncementnewtImage from "assets/images/AnnouncementIconnew.svg";
import linkIcon from "assets/images/link_icon.svg";
import addIcon from "assets/images/add_icon.svg";
import detailImage from "assets/images/detail_img.png";

const appImages = {
  Logo,
  DashboardIcon,
  ProfileIcon,
  JobsiteIcon,
  ObservationIcon,
  DocumentIcon,
  ReportIcon,
  QrcodeIcon,
  ConcernIcon,
  SupplyrequestIcon,
  MessageIcon,
  AnnouncementIcon,
  NotificationIcon,
  KeyIcon,
  LogoutIcon,
  ArrowIcon,
  UserIcon,
  UserimageIcon,
  SuccessIcon,
  ProfileImage,
  ErrorIcon,
  EllipsIcon,
  RightDetailIcon,
  MailIcon,
  LockIcon,
  CalendarIcon,
  paginationarrowIcon,
  EditIcon,
  DeleteIcon,
  ArchiveIcon,
  SortIcon,
  UserImage,
  ToggleIcon,
  EyeIcon,
  DashboardIcon01,
  DashboardIcon02,
  DashboardIcon03,
  DashboardIcon04,
  LogoSm,
  QrimgIcon,
  CloseImage,
  Eye,
  EyeSlash,
  CopyIcon,
  LoginLogo,
  UploadIcon,
  arrowImage,
  leftImage,
  RightSignIcon,
  observationImage,
  TaskDetailIcon,
  ConcernDetailIcon,
  DeleteRedIcon,
  notfoundImage,
  DocumentDetailIcon,
  clockImage,
  PrinterIcon,
  avatarImage,
  morebtnIcon,
  noAnnouncementImage,
  AnnouncementnewtImage,
  linkIcon,
  addIcon,
  detailImage,
  HolidayIcon,
  ManageValetIcon
};

export default appImages;
