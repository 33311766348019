import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Form, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import { NavLink, useLocation } from "react-router-dom";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import CreateEmployee from "components/employeeProfile/createEmployee";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import {
  ArchiveEmployeeAPI,
  DeleteEmployeeAPI,
  GetEmployeeAPI,
  StatusUpdateEmpAPI,
} from "services/employee";
import SuccessModal from "common/modal/successModal/successModal";
import moment from "moment";
import { toast } from "react-toastify";
import ConfirmModal from "common/confirmModal/confirmModal";
import { useDebouncedSearch } from "utils/helpers/debounceSearch";
import { GetPositionTitleAPI } from "services/common";
import { GlobalState } from "context/globalContext";

const Employee = () => {
  const { state } = useLocation();
  const { setBreadCrumb } = GlobalState();
  const [isCreateEmployee, setIsCreateEmployee] = useState(false);
  const [isEditEmployee, setIsEditEmployee] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [archiveStatus, setArchiveStatus] = useState<string>();
  const [sortColumn, setSortColumn] = useState<any>({});
  const [positionFilter, setPositionFilter] = useState<any>(null);
  const [status, setStatus] = useState<boolean | null>(null);
  const [positionList, setPositionList] = useState<any[]>([]);

  const debouncedSearch = useDebouncedSearch(search, 600);

  const breadcrumbList = [
    {
      title: "Employee Profiles",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "archive":
        data = {
          title: "Employee Archived successfully",
          description: "Employee has been successfully archived",
        };
        return data;
      case "status":
        data = {
          title: "Employee Status updated successfully",
          description: "Employee Status has been successfully updated",
        };
        return data;
      case "delete":
        data = {
          title: "Employee Deleted successfully",
          description: "Employee has been successfully deleted",
        };
        return data;
      case "create":
        data = {
          title: "Employee Create successfully",
          description: "Employee has been successfully created",
        };
        return data;
      case "update":
        data = {
          title: "Employee Update successfully",
          description: "Employee has been successfully updated",
        };
        return data;
    }
  };

  const getEmployeeListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      search_term: debouncedSearch,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
      position_id: positionFilter?.id,
    };
    GetEmployeeMutate(data);
  };

  useEffect(() => {
      getEmployeeListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    debouncedSearch,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
    positionFilter,
  ]);

  useEffect(() => {
    GetPositionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isPending, mutate: GetEmployeeMutate } = useMutation({
    mutationFn: GetEmployeeAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setEmployeeData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? sortDirection : undefined,
    });
  };

  const { isPending: positionListPending, mutate: GetPositionList } =
    useMutation({
      mutationFn: GetPositionTitleAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setPositionList(data.data.result);
        if(state?.role_id){
          setPositionFilter(positionList.find((el) => el.role_id === state?.role_id))
        }
      },
    });

  const { isPending: ArchivePending, mutate: ArchiveMutate } = useMutation({
    mutationFn: ArchiveEmployeeAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getEmployeeListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const { isPending: DeletePending, mutate: DeleteMutate } = useMutation({
    mutationFn: DeleteEmployeeAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getEmployeeListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const { isPending: StatusPending, mutate: StatusMutate } = useMutation({
    mutationFn: StatusUpdateEmpAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getEmployeeListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const handleStatusChange = (status: boolean) => {
    setStatus(status);
    setCurConfirmModal("status");
    isShowConfirmModal("status");
  };

  const handleArchive = (archive?: string) => {
    setArchiveStatus(archive);
    setCurConfirmModal("archive");
    isShowConfirmModal("archive");
  };

  const handleDelete = () => {
    setCurConfirmModal("delete");
    isShowConfirmModal("delete");
  };

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  const handleActionClick = (
    action: string,
    status?: any,
    id?: number,
    archive?: string
  ) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "delete":
        return handleDelete();
      case "archive":
        return handleArchive(archive);
      case "edit":
        return setIsEditEmployee(true);
      case "active":
        return handleStatusChange(status);
    }
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
      width: '80px'
    },
    {
      id: 2,
      name: "Profile",
      cell: (row: any) => (
        <Image
          className="profile-img rounded-circle"
          src={row?.profile_image_url}
        />
      ),
      width: '90px'
    },
    {
      id: 3,
      name: "Name",
      selector: (row: any) =>
        row?.full_name && row?.full_name?.length > 25
          ? row?.full_name.slice(0, 25) + "..."
          : row?.full_name,
      sortField: "first_name",
      sortable: true,
    },
    {
      id: 4,
      name: "Payroll Integration",
      selector: (row: any) => row?.employee_salary,
      sortField: "employee_salary",
      sortable: true,
    },
    {
      id: 5,
      name: "Region",
      selector: (row: any) => row?.region_title,
      sortField: "region_title",
      sortable: true,
    },
    {
      id: 6,
      name: "Current Position",
      selector: (row: any) => row?.position_title,
      sortField: "position_title",
      sortable: true,
    },
    {
      id: 7,
      name: "Date of Hire",
      selector: (row: any) => moment(row?.join_date).format("DD MMM, YYYY"),
      sortField: "join_date",
      sortable: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            status={row?.is_active === "1" ? true : false}
            archiveText={row?.is_archived === '1' ? 'UnArchive' : 'Archive'}
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id, row.is_archived)
            }
            isVisibles={["edit", "archive", "delete", "active"]}
            onToggleChange={(e: boolean) => isDropdownOpen(e)}
          />
        </>
      ),
      width: "100px",
    },
    {
      id: 9,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link as={NavLink} to={`/employee-detail/${row?.id}`}>
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  const handleCloseCreateModal = () => {
    setIsCreateEmployee(false);
    setIsEditEmployee(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  }

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Employee List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id)
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Form.Group className={`form-group`}>
            <Form.Control
              type="text"
              name="sarch"
              id="search"
              value={search}
              placeholder="Search"
              // maxLength={20}
              autoComplete="off"
              onChange={(e: any) => {
                setCurrentPage(1)
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
          <Select
            isDisabled={positionListPending || isPending}
            getOptionLabel={(item: any) => item.position_title}
            getOptionValue={(item: any) => item.id}
            options={positionList}
            isClearable
            isSearchable
            value={positionFilter}
            onChange={(selectedOption) => {
              setCurrentPage(1);
              setPositionFilter(selectedOption);
            }}
            placeholder={"All"}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Button variant="primary" onClick={() => setIsCreateEmployee(true)}>
            Create Users
          </Button>
        </div>
      </div>
      <ReactDataTable
        dynamicClass={dropdownOpen ? "dropdown-open" : ""}
        columns={columns}
        tableData={employeeData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={employeeData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getEmployeeListData(page+1)
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {(isCreateEmployee || isEditEmployee) && (
        <CreateEmployee
          title={
            isCreateEmployee
              ? "Create Employee Profile"
              : "Edit Employee Profile"
          }
          setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateEmployee}
          callback={getEmployeeListData}
          show={isCreateEmployee || isEditEmployee}
          handleClose={handleCloseCreateModal}
          currentRow={currentRow}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {showConfirmModal === "delete" && (
        <ConfirmModal
          show={showConfirmModal === "delete"}
          isLoading={DeletePending}
          onConfirm={() => DeleteMutate({ id: currentRow })}
          handleClose={handleCloseConfirmModal}
          type="delete"
          message={
            "Do you really want to delete this record? This process can not be undone."
          }
        />
      )}
      {showConfirmModal === "archive" && (
        <ConfirmModal
          show={showConfirmModal === "archive"}
          onConfirm={() =>
            ArchiveMutate({
              id: currentRow,
              employee_archive: archiveStatus == "1" ? "0" : "1",
            })
          }
          isLoading={ArchivePending}
          handleClose={handleCloseConfirmModal}
          message={"Do you really want to archive this record?"}
        />
      )}
      {showConfirmModal === "status" && (
        <ConfirmModal
          show={showConfirmModal === "status"}
          isLoading={StatusPending}
          onConfirm={() =>
            StatusMutate({
              id: currentRow,
              employee_status: status ? "1" : "0",
            })
          }
          handleClose={handleCloseConfirmModal}
          message={"Do you really want to change status of this record?"}
        />
      )}
    </>
  );
};

export default Employee;
