import React, { useRef, useState } from "react";
import FileInput from "common/form/fileInput";
import Textbox from "common/form/textBox";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Image, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import appImages from "utils/appImages";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetAreaListAPI, GetCityListAPI } from "services/common";
import { CreateClientSchema } from "utils/schema/client";
import { CreateClientProps } from "types/client";
import { CreateClientAPI, GetClientDetailAPI, GetClientFormDataAPI, UpdateClientAPI } from "services/client";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";

const CreateClient: React.FC<FormModalProps> = ({
  title,
  show,
  isCreateModal,
  handleClose,
  setShowSuccessModal,
  currentRow,
  callback,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profileData, setProfileData] = useState<any>();
  const [imageURL, setImageURL] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isCustomError, setIsCustomError] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [cityData, setCityData] = useState<any[]>([]);
  const [areaData, setAreaData] = useState<any[]>([]);
  const [errorMsg, setErrMsg] = useState<string>("Please choose profile image");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch
  } = useForm<CreateClientProps>({
    resolver: yupResolver(CreateClientSchema),
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file:any = event.target.files && event.target.files[0];
    const allowedExtensions = ['png', 'jpeg', 'jpg', 'svg'];

    const extension:any = file && file?.name?.split('.')?.pop()?.toLowerCase();

    if (!allowedExtensions.includes(extension)) {
      // Invalid file type
      if (fileInputRef.current !== null) {
        fileInputRef.current.value = "";
      }
      setImageURL("");
      setValue("profile_image", "");
      setErrMsg("Unsupported file format. Please select a PNG, JPEG, JPG, or SVG file.");
      setIsCustomError(true);
      return;
    }

    file && setSelectedFile(file);
    setIsCustomError(false);
    setErrMsg('');
    setValue("profile_image", "");
    // Check if a file is selected
    if (file) {
      // Create an image element to get dimensions
      const img = new window.Image();
      img.onload = () => {
        // Check image width and height
        // if (img.width >= 5000 || img.height >= 5000) {
        //   if (fileInputRef.current !== null) {
        //     fileInputRef.current.value = "";
        //   }
        //   setImageURL("");
        //   setValue("profile_image", "");
        //   setIsCustomError(true);
        //   return;
        // } else {
        //   // Set the selected image in state or perform other actions
        //   let imageURL = URL.createObjectURL(file);
        //   setImageURL(imageURL);
        // }
        let imageURL = URL.createObjectURL(file);
        setImageURL(imageURL);
      };
      // Set the source of the image element to the selected file
      img.src = URL.createObjectURL(file);
    }
  };

  const { isPending: isPendingFormData } = useQuery({
    queryKey: ["clientFormData"],
    queryFn: async () => {
      try {
        const data = await GetClientFormDataAPI();
        setFormData(data.data.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: getCityPending, mutate: GetCityMutate } = useMutation({
    mutationFn: GetCityListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setCityData(data?.data?.result);
    },
  });

  const { isPending: getAreaPending, mutate: GetAreaMutate } = useMutation({
    mutationFn: GetAreaListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setAreaData(data?.data?.result);
      setValue('area', watch('area')?.filter((el:any) => data?.data?.result.some((item:any) => item?.id === el?.id)));
    },
  });

  const { fetchStatus } = useQuery({
    queryKey: ["clientDetail"],
    enabled: !isCreateModal,
    queryFn: async () => {
      try {
        const data =
          currentRow && (await GetClientDetailAPI(currentRow.toString()));
        const result = data && data?.data?.result;
        if (result) {
          GetCityMutate(result?.state_info?.id);
          GetAreaMutate({jobsite_id: result?.client_info?.job_site_info?.map((el:any) => el.id)});
          setProfileData(result?.profile_image_url);
          setValue("company_title_id", result?.client_info?.company_title_info);
          setValue("title", result?.title);
          setValue("first_name", result?.first_name);
          setValue("last_name", result?.last_name);
          setValue("email", result?.email);
          setValue("phone", result?.phone);
          setValue(
            "regional_manager_id",
            result?.client_info?.regional_manager_info
          );
          setValue("secondary_phone", result?.client_info?.secondary_phone);
          setValue("state", result?.state_info);
          setValue("city", result?.city_info);
          setValue("address", result?.address);
          setValue("zipcode", result?.zipcode);
          setValue("position", result?.client_info?.client_position_info);
          setValue("jobsite", result?.client_info?.job_site_info);
          setValue("area", result?.client_info?.area_info);
          setValue("unit", result?.client_info?.unit);
          setValue("total_unit", result?.client_info?.total_unit);
          setValue("time_zone", result?.time_zone_info);
          // setValue("total_unit", result?.time_zone_info);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending, mutate: CreatClientMutate } = useMutation({
    mutationFn: CreateClientAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });

  const { isPending: isPendingUpdate, mutate: UpdateClientMutate } =
    useMutation({
      mutationFn: UpdateClientAPI,
      onError: (error: any) => {
        if (typeof error?.data?.message === "string") {
          toast.error(error?.data?.message);
        } else {
          error?.data?.message?.forEach((item: any) => {
            Object.keys(item).forEach((fieldName: any) => {
              setError(fieldName, {
                type: "custom",
                message: item[fieldName][0],
              });
            });
          });
        }
      },
      onSuccess: (data) => {
        callback && callback();
        setShowSuccessModal && setShowSuccessModal(true, "update");
        handleClose();
      },
    });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    if (selectedFile && !isCustomError) {
      formData.append("profile_image", selectedFile);
    }
    if(submitFormData?.title){
      formData.append("title", submitFormData?.title);
    }
    formData.append("company_title_id", submitFormData?.company_title_id?.id);
    formData.append("first_name", submitFormData?.first_name);
    formData.append("last_name", submitFormData?.last_name);
    formData.append("email", submitFormData?.email);
    formData.append("phone", submitFormData?.phone);
    if (submitFormData?.regional_manager_id) {
      formData.append(
        "regional_manager_id",
        submitFormData?.regional_manager_id?.id
      );
    }
    if (submitFormData?.secondary_phone) {
      formData.append("secondary_phone", submitFormData?.secondary_phone);
    }
    formData.append("state_id", submitFormData?.state?.id);
    formData.append("city_id", submitFormData?.city?.id);
    formData.append("address", submitFormData?.address);
    formData.append("zipcode", submitFormData?.zipcode);
    formData.append("client_position_id", submitFormData?.position?.id);
    if (submitFormData && Array.isArray(submitFormData.jobsite)) {
      const jobsiteIds = submitFormData.jobsite.map((el: any) => el.id);
      jobsiteIds.forEach((id: any) => {
        formData.append("jobsites_id[]", id);
      });
    }
    if (submitFormData && Array.isArray(submitFormData.area)) {
      const areaIds = submitFormData.area.map((el: any) => el.id);
      areaIds.forEach((id: any) => {
        formData.append("areas_id[]", id);
      });
    }
    formData.append("time_zone_id", submitFormData?.time_zone?.id);
    submitFormData?.unit && formData.append("unit", submitFormData?.unit);
    submitFormData?.total_unit && formData.append("total_unit", submitFormData?.total_unit);

    currentRow && formData.append("id", currentRow.toString());

    isCreateModal
      ? CreatClientMutate(formData)
      : currentRow && UpdateClientMutate(formData);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
      >
        {!isCreateModal && fetchStatus === "fetching" ? (
          <SpinerLoader />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FileInput
              control={control}
              type={"file"}
              accept="image/png, image/jpeg, image/jpg, image/svg"
              name={"profile_image"}
              id={"profile_image"}
              className="d-none"
              onChange={handleInputChange}
              fileInputRef={fileInputRef}
              value={selectedFile}
            />
            <div className="user-profile-image text-center">
              <label
                htmlFor={"profile_image"}
                className="d-flex align-items-center justify-content-center m-auto overflow-hidden"
              >
                {!imageURL ? (
                  <>
                    {profileData ? (
                      <>
                        <Image src={profileData} alt="" className="w-100" />
                      </>
                    ) : (
                      <>
                        <Image src={appImages.ProfileImage} alt="profile" />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Image src={imageURL} alt="" className="w-100" />
                  </>
                )}
              </label>
              <span>Upload Profile</span>
              {isCustomError ? (
                <div className="invalid-feedback d-block">{errorMsg}</div>
              ) : (
                ""
              )}
            </div>
            <Row>
              <Col md={12}>
                <Controller
                  name="company_title_id"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.company_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.companyTitleList ?? []}
                        isSearchable
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Company Name"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.company_title_id && (
                        <div className="invalid-feedback d-block">
                          {errors.company_title_id.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="text"
                  id="title"
                  name="title"
                  value=""
                  placeholder={"Title"}
                  autoComplete="off"
                  max={50}
                  // errorMessage={errors.title && errors.title?.message}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="text"
                  id="first_name"
                  name="first_name"
                  value=""
                  placeholder={"First Name"}
                  autoComplete="off"
                  max={50}
                  errorMessage={errors.first_name && errors.first_name?.message}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="text"
                  id="last_name"
                  name="last_name"
                  value=""
                  placeholder={"Last Name"}
                  autoComplete="off"
                  max={50}
                  errorMessage={errors.last_name && errors.last_name?.message}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="text"
                  id="email"
                  name="email"
                  value=""
                  placeholder={"Email"}
                  autoComplete="off"
                  max={50}
                  errorMessage={errors.email && errors.email?.message}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="number"
                  id="phone"
                  name="phone"
                  value=""
                  max={20}
                  onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                  placeholder="Primary Contact"
                  autoComplete="off"
                  errorMessage={errors.phone && errors.phone.message}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="regional_manager_id"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isDisabled={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.full_name}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.regionalManager ?? []}
                        isSearchable
                        isClearable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Regional Manager"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.regional_manager_id && (
                        <div className="invalid-feedback d-block">
                          {errors.regional_manager_id.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="number"
                  id="secondary_phone"
                  name="secondary_phone"
                  value=""
                  max={20}
                  onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                  placeholder="Secondary Contact"
                  autoComplete="off"
                  errorMessage={
                    errors.secondary_phone && errors.secondary_phone.message
                  }
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="text"
                  id="address"
                  name="address"
                  value=""
                  placeholder={"Address"}
                  autoComplete="off"
                  max={300}
                  errorMessage={errors.address && errors.address?.message}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isDisabled={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.state_name}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.state ?? []}
                        isSearchable
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                          if (selectedOption) {
                            GetCityMutate(selectedOption?.id);
                            setValue("city", {});
                          }
                        }}
                        placeholder={"State"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.state && (
                        <div className="invalid-feedback d-block">
                          {errors.state.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={getCityPending}
                        {...field}
                        getOptionLabel={(item: any) => item.city_name}
                        getOptionValue={(item: any) => item.id}
                        options={cityData}
                        isSearchable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"City"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.city && (
                        <div className="invalid-feedback d-block">
                          {errors.city.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={4}>
                <Textbox
                  control={control}
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  value=""
                  placeholder={"Zip code"}
                  autoComplete="off"
                  max={20}
                  errorMessage={errors.zipcode && errors.zipcode?.message}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="position"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isDisabled={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.position_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.clientPosition ?? []}
                        isSearchable
                        isClearable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Position"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.position && (
                        <div className="invalid-feedback d-block">
                          {errors.position.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <Controller
                  name="jobsite"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isDisabled={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.jobsite_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.jobSites ?? []}
                        isSearchable
                        isMulti
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                          if (selectedOption?.length) {
                            GetAreaMutate({
                              jobsite_id: selectedOption?.map(
                                (item: any) => item.id
                              ),
                            });
                          } else {
                            setValue('area', [])
                          }
                        }}
                        placeholder={"Jobsite"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.jobsite && (
                        <div className="invalid-feedback d-block">
                          {errors.jobsite.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={6}>
                <Controller
                  name="area"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={getAreaPending}
                        {...field}
                        getOptionLabel={(item: any) => item.area_name}
                        getOptionValue={(item: any) => item.id}
                        options={areaData}
                        isSearchable
                        isMulti
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Area (Building)"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.area && (
                        <div className="invalid-feedback d-block">
                          {errors.area.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={6}>
                <Textbox
                  control={control}
                  type="number"
                  id="unit"
                  name="unit"
                  value=""
                  onPaste={(e) => e.preventDefault()}
                  onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                  placeholder={"Unit"}
                  autoComplete="off"
                  max={10}
                  // errorMessage={errors.unit && errors.unit?.message}
                />
              </Col>
              <Col md={6}>
                <Textbox
                  control={control}
                  type="number"
                  id="total_unit"
                  name="total_unit"
                  value=""
                  onPaste={(e) => e.preventDefault()}
                  onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                  placeholder={"Total Unit"}
                  autoComplete="off"
                  max={10}
                  // errorMessage={errors.total_unit && errors.total_unit?.message}
                />
              </Col>
              <Col md={6}>
                <Controller
                  name="time_zone"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isDisabled={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.label}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.timezone ?? []}
                        isSearchable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Time Zone"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="top"
                      />
                      {errors.time_zone && (
                        <div className="invalid-feedback d-block">
                          {errors.time_zone.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-center gap-4">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isPending || isPendingUpdate}>
                  {(isPending || isPendingUpdate) && <SubmitLoader />}
                  {isCreateModal ? "Create" : "Update"}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </CommonModal>
    </>
  );
};

export default CreateClient;
