import FourZeroFour from "common/404/404";
import ForgetPassword from "components/resetPassword/forgotPassword";
import ResetPassword from "components/resetPassword/resetPassword";
import Signin from "components/signIn/signIn";
import PublicLayout from "layout/publicLayout";

// const Home = lazy(() => import("components/home/home"));

const PublicrouteList = {
  path: "/",
  element: <PublicLayout />,
  children: [
    {
      path: "/",
      element: <Signin />,
    },
    {
      path: "/signin",
      element: <Signin />,
    },
    {
      path: "/forgot-password",
      element: <ForgetPassword />,
    },
    {
      path: "/reset-password/:email/:token",
      element: <ResetPassword />,
    },
    {
      path: "/404",
      element: <FourZeroFour />,
    },
    {
      path: "/*",
      element: <FourZeroFour />,
    },
  ],
};

export default PublicrouteList;
