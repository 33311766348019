import Textbox from "common/form/textBox";
import { Image, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import appImages from "utils/appImages";
import "components/signIn/signIn.scss";
import { useEffect, useState } from "react";
import { SigninSchemaData } from "types/auth";
import { getAccesToken, getLoginData, saveProfileDataStorage, saveTokenToLocalStorage, setUserRoleToStorage } from "utils/helpers/localStorage";
import Password from "common/form/password";
import { useMutation } from "@tanstack/react-query";
import { SignInAPI } from "services/auth";
import { SigninSchema } from "utils/schema/auth";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";

const Signin = () => {
  const token = getAccesToken();
  const { setProfileData } = GlobalState();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [loginData, setLoginData] = useState<SigninSchemaData | undefined>(
    getLoginData()
  );

  useEffect(() => {
    if(token) {
      navigate('/dashboard')
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(SigninSchema),
  });

  useEffect(() => {
    if (loginData) {
      setValue("email", loginData?.email);
      setValue("password", loginData?.password);
      // setValue("remember_me", loginData?.remember_me);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData, reset]);


  const { isPending, mutate: SignInMutate } = useMutation({
    mutationFn: SignInAPI,
    onError: (error: any, variables, context) => {
      toast.error(error.data.message)
    },
    onSuccess: (data) => {
      saveTokenToLocalStorage(data?.data?.result?.token)
      saveProfileDataStorage(data?.data?.result)
      setUserRoleToStorage(data?.data?.result?.role_id)
      setProfileData({profile_image_url: data?.data?.result?.profile_image_url, full_name: data?.data?.result?.full_name})
      navigate("/dashboard")
    },
    // onSettled: (data, error, variables, context) => {},
  })


  const handleTogglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const onSubmit = async (data: SigninSchemaData) => {
    setLoginData(data);
    const payloadData = { ...data, device_type: "web" }
    await SignInMutate(payloadData);
  };

  return (
    <>
      <div className="signin-page w-100 d-flex align-items-center justify-content-center">
        <div className="signin-logo">
          <Image src={appImages.LoginLogo} />
        </div>
        <div className="signin-form bg-white">
          <h1 className="fw-600">Login</h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Textbox
              control={control}
              type="text"
              id="email"
              name="email"
              value=""
              label="Email Address"
              // onKeyDown={handleKeyDown}
              placeholder={"Enter email address"}
              autoComplete="on"
              max={100}
              errorMessage={errors.email && errors.email?.message}
            />
            <Password
              control={control}
              type="password"
              id="password"
              name="password"
              value=""
              autoComplete="on"
              className="form control"
              placeholder="Enter Password"
              label="Password"
              errorMessage={errors.password && errors.password?.message}
              enableShowPassword={true}
              isRequired={true}
              passwordType={passwordType}
              max={64}
              handleTogglePassword={handleTogglePassword}
            />

            <Link to={"/forgot-password"} className="link forgot-password">
              Forgot Password ?
            </Link>
            <Button
              variant="primary"
              type="submit"
              className="text-center d-block"
              disabled={isPending}
            >
              {isPending && <SubmitLoader />}Submit
            </Button>
            {/* <p>Don't have an account? <Link to={"/"} className="link">Register Now</Link></p> */}
          </Form>
        </div>
      </div>
    </>
  );
};

export default Signin;
