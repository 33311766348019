import * as Yup from "yup";

export const CreateJobsiteSchema = Yup.object().shape({
    jobsite_name: Yup.string().required("Jobsite name is required"),
    region: Yup.object().required("Region is reqired"),
    client: Yup.object().required("Client is reqired"),
    address: Yup.string().required("Address is required"),
    total_unit: Yup.number().typeError("Total Unit is required").required("Total Unit is required"),
});

export const CreateAreaSchema = Yup.object().shape({
    area_name: Yup.string().required("Area name is required"),
    total_unit: Yup.number().typeError("Total Unit is required").required("Total Unit is required"),
});