import React, { useEffect, useState } from "react";
import Textbox from "common/form/textBox";
import { SpinerLoader, SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import Textareabox from "common/form/textAreaBox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import DragAndDropFileUpload from "common/dragFileUpload/dragFileUpload";
import ReactDatePicker from "react-datepicker";
import { FileError } from "react-dropzone";
import { GetAreaListAPI } from "services/common";
import appImages from "utils/appImages";
import {
  CreateConcernAPI,
  EditConcernListAPI,
  GetConcernDetailAPI,
  GetConcernFormDataAPI,
} from "services/concern";
import { CreateConcernSchema } from "utils/schema/concern";
import { CreateConcernProps } from "types/concern";
import { inputNumberHandleKeyDown } from "utils/helpers/handleInputNumberEvent";

const CreateConcern: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  isCreateModal,
  setShowSuccessModal,
  callback,
  currentRow,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [uploadedImgs, setUploadedImg] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [imgUploadErr, setImgUploadErr] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
  } = useForm<CreateConcernProps>({
    resolver: yupResolver(CreateConcernSchema),
  });

  const parseTimeString = (timeString: string): Date => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (hours === 12) {
      hours = 0;
    }
    if (modifier === "PM" && hours < 12) {
      hours += 12;
    }

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  };

  const { fetchStatus } = useQuery({
    queryKey: ["concernDetail"],
    enabled: !isCreateModal,
    queryFn: async () => {
      try {
        const data =
          currentRow && (await GetConcernDetailAPI(currentRow.toString()));
        const result = data && data?.data?.result;
        if (result) {
          GetAreaList({ jobsite_id: [result?.job_site_info?.id] });
          setUploadedImg(
            result?.concern_images?.map((item: any) => item?.concern_image_url)
          );

          setValue("task_name", result?.task_title_info);
          setValue("concern_name", result?.concern_title_info);
          setValue("jobsite", result?.job_site_info);
          setValue("area", result?.area_info);
          setValue("unit", result?.unit);
          setValue("date", new Date(result?.date_submitted));

          const parsedTime = parseTimeString(result?.time_submitted);
          setValue("time", parsedTime);
          // setValue("time", result?.time_submitted);
          setValue("description", result?.description);
        }
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: isPendingFormData, mutate: GetConcernFormData } =
    useMutation({
      mutationFn: GetConcernFormDataAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        setFormData(data?.data?.result);
      },
    });

  const { isPending: isPendingAreaList, mutate: GetAreaList } = useMutation({
    mutationFn: GetAreaListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, areaList: data.data.result });
    },
  });

  useEffect(() => {
    GetConcernFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isPending, mutate: CreateConcernMutate } = useMutation({
    mutationFn: CreateConcernAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });

  const { isPending: isPengingEdit, mutate: EditConcernMutate } = useMutation({
    mutationFn: EditConcernListAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "edit");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("concern_title_id", submitFormData?.concern_name?.id);
    formData.append("task_title_id", submitFormData?.task_name?.id);
    formData.append("jobsite_id", submitFormData?.jobsite?.id);
    if (submitFormData?.area?.id) {
      formData.append("area_id", submitFormData?.area?.id);
    }
    formData.append(
      "date_submitted",
      moment(submitFormData?.date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append(
      "time_submitted",
      moment(submitFormData?.time).format("hh:mm A")
    );
    if (submitFormData?.unit) {
      formData.append("unit", submitFormData?.unit);
    }
    if (submitFormData?.description) {
      formData.append("description", submitFormData?.description);
    }
    selectedFiles.length > 0 &&
      selectedFiles.forEach((image) => {
        formData.append("images[]", image);
      });
    if (currentRow && !isCreateModal) {
      formData.append("id", currentRow.toString());
    }

    isCreateModal ? CreateConcernMutate(formData) : EditConcernMutate(formData);
  };

  const handleUploadFiles = (files: any[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleUploadValidate = (file: File): FileError | FileError[] | null => {
    if (!file.type.startsWith("image/")) {
      setImgUploadErr("Only Support image Files only");
      return null;
    }
    setImgUploadErr("");
    return null;
  };

  const removeSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        {!isCreateModal && fetchStatus === "fetching" ? (
          <SpinerLoader />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Row>
              <Col md={12}>
                <Controller
                  name="task_name"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.task_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.taskTitles ?? []}
                        isSearchable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Task Name"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.task_name && (
                        <div className="invalid-feedback d-block">
                          {errors.task_name.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <Controller
                  name="concern_name"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isPendingFormData}
                        {...field}
                        getOptionLabel={(item: any) => item.concern_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.concernTitles ?? []}
                        isSearchable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Concern Name"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.concern_name && (
                        <div className="invalid-feedback d-block">
                          {errors.concern_name.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <Controller
                  name="jobsite"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isPendingFormData}
                        {...field}
                        isDisabled={!isCreateModal}
                        getOptionLabel={(item: any) => item.jobsite_title}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.jobSites ?? []}
                        isSearchable
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption);
                          setValue("area", null);
                          if (selectedOption) {
                            GetAreaList({ jobsite_id: [selectedOption?.id] });
                          }
                        }}
                        placeholder={"Jobsite"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                      {errors.jobsite && (
                        <div className="invalid-feedback d-block">
                          {errors.jobsite.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <Controller
                  name="area"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <Select
                        isLoading={isPendingAreaList}
                        {...field}
                        getOptionLabel={(item: any) => item.area_name}
                        getOptionValue={(item: any) => item.id}
                        options={formData?.areaList ?? []}
                        isSearchable
                        isClearable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Area"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="bottom"
                      />
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <Textbox
                  control={control}
                  type="number"
                  id="unit"
                  name="unit"
                  value=""
                  placeholder={"Unit"}
                  onPaste={(e) => e.preventDefault()}
                  onKeyDown={(e:any) => inputNumberHandleKeyDown(e)}
                  autoComplete="off"
                  max={10}
                />
              </Col>
              <Col md={12}>
                <DatePickerReact
                  startDate={watch("date")}
                  disabled={!isCreateModal}
                  control={control}
                  id="date"
                  minDate={new Date()}
                  name="date"
                  value=""
                  className="form control"
                  placeholder="Date"
                  autoComplete="off"
                  errorMessage={errors.date && errors.date.message}
                  onChange={(date) => {
                    if (date) {
                      setValue("date", date);
                      trigger("date");
                    }
                  }}
                />
              </Col>
              <Col md={12}>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group react-timepicker">
                      <ReactDatePicker
                        selected={watch("time")}
                        onChange={(time: any) => {
                          field.onChange(time);
                        }}
                        disabled={!isCreateModal}
                        placeholderText="Time"
                        className="form-control"
                        autoComplete="off"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      {errors.time && (
                        <div className="invalid-feedback d-block">
                          {errors.time.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col md={12}>
                <Textareabox
                  control={control}
                  type="textarea"
                  id="description"
                  name="description"
                  value=""
                  placeholder={"Description"}
                />
              </Col>
              <Col md={12}>
                <div className="d-flex flex-column custom-select-wrapper mb-5">
                  <div className="d-block">
                    {`* You can upload Maximum ${
                      6 - selectedFiles.length - uploadedImgs.length
                    } image *`}
                  </div>
                  <div className="d-flex selected-image-wrapper horizontal-scrollbar gap-2">
                    {uploadedImgs.length > 0 &&
                      uploadedImgs.map((img: any, index: number) => {
                        return (
                          <div key={index} className="d-flex flex-column">
                            <div
                              key={index}
                              className="selected-image d-flex align-items-center justify-content-center"
                              style={{ backgroundImage: `url(${img})` }}
                            ></div>
                          </div>
                        );
                      })}
                    {selectedFiles?.map((file: any, index) => {
                      let imageURL = URL.createObjectURL(file);
                      return (
                        <div className="d-flex flex-column">
                          <div
                            key={index}
                            className="selected-image d-flex align-items-center justify-content-center"
                            style={{ backgroundImage: `url(${imageURL})` }}
                          >
                            <div
                              className="remove-image d-flex align-items-center justify-content-center cursor-pointer"
                              onClick={() => removeSelectedImage(index)}
                            >
                              <Image
                                src={appImages.CloseImage}
                                alt="More"
                                width={10}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <DragAndDropFileUpload
                    handleValidate={handleUploadValidate}
                    uploadFiles={handleUploadFiles}
                    totalItems={selectedFiles?.length + uploadedImgs.length}
                    maxAllows={6 - selectedFiles.length - uploadedImgs.length}
                  />
                  <div className="invalid-feedback d-block">{imgUploadErr}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-center gap-4">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isPending || isPengingEdit}>
                  {(isPending || isPengingEdit) && <SubmitLoader />}
                  {isCreateModal ? 'Create': 'Update'}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </CommonModal>
    </>
  );
};

export default CreateConcern;
