import { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";

const libraries = ["places", "drawing"];
const containerStyle = {
  width: "100%",
  height: "400px",
};

const polygonNewOptions = {
  fillOpacity: 0.2,
  fillColor: "#7349D3",
  strokeColor: "#7349D3",
  strokeWeight: 3,
};

const LiveTrackingMap = ({
  polygonData,
  markerData,
}) => {
    
    const [polygons, setPolygons] = useState(polygonData ?? []);

  // const [state, setState] = useState({ activePolygonData });
  // const { paths } = state;
  // const point = (states) => {
  //   setState({ states });
  // };
  const [isLoadedMap, setIsLoadedMap] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
});

  useEffect(() => {
    setIsLoadedMap(false);

    const timeout = setTimeout(() => {
      setIsLoadedMap(true);
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, loadError]);

  const mapRef = useRef();

  const defaultCenter = {
    lat: 37.09024,
    lng: -95.712891,
  };
  const [center, setCenter] = useState(defaultCenter);

  // const onLoadMap = (map) => {
  //   mapRef.current = map;
  //   let bounds = new window.google.maps.LatLngBounds(polygons);
  //   map.fitBounds(bounds);
  // };

  const onLoadMap = (map) => {
    mapRef.current = map;
    if (markerData) {
      const points = new window.google.maps.LatLng(markerData);
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(points);
      mapRef.current.fitBounds(bounds);
      const listener = window.google.maps.event.addListener(mapRef.current, "idle", () => {
        const currentZoom = mapRef.current.getZoom();
        if (currentZoom > 16) {
          mapRef.current.setZoom(16);
        }
        window.google.maps.event.removeListener(listener);
      });
  }
};

useEffect(() => {
    if (mapRef.current && markerData) {
        const points = new window.google.maps.LatLng(markerData);
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(points);
        mapRef.current.fitBounds(bounds);
        const listener = window.google.maps.event.addListener(mapRef.current, "idle", () => {
          const currentZoom = mapRef.current.getZoom();
          if (currentZoom > 16) {
            mapRef.current.setZoom(16);
          }
          window.google.maps.event.removeListener(listener);
        });
    }
  }, [markerData]);


  return (isLoaded&&isLoadedMap) ? (
    <>
      <div className="map-container">
        <GoogleMap
          zoom={16}
          center={center}
          onLoad={onLoadMap}
          mapContainerStyle={containerStyle}
          onTilesLoaded={() => setCenter(null)}
          options={{
            mapTypeControl: false,
            streetViewControl: false
          }}
        >

          {polygons.map((iterator, index) => (
            <>
              <Polygon
                key={index + "polygon"}
                options={polygonNewOptions}
                paths={iterator}
              />
            </>
          ))}

          {markerData && (
            <Marker position={markerData} onClick={() => {}} />
          )}
          
        </GoogleMap>
      </div>
    </>
  ) : null;
};

export default LiveTrackingMap;
