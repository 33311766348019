  import React, { useEffect, useState } from "react";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import Select from "react-select";
import { DatePickerReact } from "common/form/datePicker/datePicker";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { AssignWorkSchema } from "utils/schema/task";
import ReactDatePicker from "react-datepicker";
import { AssignWorkAPI, GetValetListAPI } from "services/common";

const AssignWork: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  currentRow
}) => {
  const [formData, setFormData] = useState<any>({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(AssignWorkSchema),
  });

  const { isPending: pendingEmpList, mutate: GetValetList } = useMutation({
    mutationFn: GetValetListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setFormData({ ...formData, employeeList: data.data.result });
    },
  });

  useEffect(() => {
    const body = {
      task_id: currentRow,
    };
    GetValetList(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isPending, mutate: AssignWorkMutate } = useMutation({
    mutationFn: AssignWorkAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "assignWork");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("employee_id", submitFormData?.employee?.id);
    currentRow && formData.append("task_id", currentRow.toString());
    formData.append(
      "assign_start_date",
      moment(submitFormData?.start_date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append(
      "assign_end_date",
      moment(submitFormData?.end_date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD"
      )
    );
    formData.append("assign_start_time", moment(submitFormData?.start_time).format("hh:mm A"));
    formData.append("assign_end_time", moment(submitFormData?.end_time).format("hh:mm A"));

    AssignWorkMutate(formData);
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col md={12}>
              <Controller
                name="employee"
                control={control}
                render={({ field }) => (
                  <div className="form-group">
                    <Select
                      isLoading={pendingEmpList}
                      {...field}
                      getOptionLabel={(item: any) => item.full_name}
                      getOptionValue={(item: any) => item.id}
                      options={formData?.employeeList ?? []}
                      isSearchable
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder={"Select Employee"}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      menuPlacement="bottom"
                    />
                    {errors.employee && (
                      <div className="invalid-feedback d-block">
                        {errors.employee.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <DatePickerReact
                startDate={watch("start_date")}
                control={control}
                id="start_date"
                name="start_date"
                value=""
                minDate={new Date()}
                className="form control"
                placeholder="Start Date"
                autoComplete="off"
                errorMessage={errors.start_date && errors.start_date.message}
                onChange={(start_date) => {
                  setValue("end_date", null as unknown as Date);
                  if (start_date) {
                    setValue("start_date", start_date);
                    trigger("start_date");
                  }
                }}
              />
            </Col>
            <Col md={12}>
              <DatePickerReact
                startDate={watch("end_date")}
                control={control}
                id="end_date"
                name="end_date"
                minDate={watch("start_date")}
                value=""
                className="form control"
                placeholder="End Date"
                autoComplete="off"
                errorMessage={errors.end_date && errors.end_date.message}
                onChange={(end_date) => {
                    setValue("end_time", null as unknown as Date);
                    if (end_date) {
                    setValue("end_date", end_date);
                    trigger("end_date");
                  }
                }}
              />
            </Col>
            <Col md={12}>
              <Controller
                name="start_time"
                control={control}
                render={({ field }) => (
                  <div className="form-group react-timepicker">
                    <ReactDatePicker
                      selected={watch("start_time")}
                      onChange={(start_time: any) => {
                        setValue("end_time", null as unknown as Date);
                        field.onChange(start_time);
                      }}
                      placeholderText="Start Time"
                      className="form-control"
                      autoComplete="off"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    {errors.start_time && (
                      <div className="invalid-feedback d-block">
                        {errors.start_time.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <Controller
                name="end_time"
                control={control}
                render={({ field }) => (
                  <div className="form-group react-timepicker">
                    <ReactDatePicker
                      selected={watch("end_time")}
                      onChange={(end_time: any) => {
                        field.onChange(end_time);
                      }}
                      placeholderText="End Time"
                      minTime={(moment(watch('start_date')).format("YYYY-MM-DD")===moment(watch('end_date')).format("YYYY-MM-DD")) ? new Date(watch("start_time")) : moment().set({ hour: 0, minute: 5 }).toDate()}
                      maxTime={moment().set({ hour: 23, minute: 59 }).toDate()}
                      className="form-control"
                      autoComplete="off"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    {errors.end_time && (
                      <div className="invalid-feedback d-block">
                        {errors.end_time.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending}>
                {isPending && <SubmitLoader />}
                Assign
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default AssignWork;
