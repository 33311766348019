import React, { useEffect, useState } from "react";
import { SubmitLoader } from "common/loader/loader";
import CommonModal from "common/modal/commonModal";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModalProps } from "types/modal";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Textbox from "common/form/textBox";
import { CreatePositionSchema } from "utils/schema/titles";
import { CreatePositionAPI, EditPositionAPI, PositionRoleListAPI } from "services/title";
import ReactSelect from "react-select";

const CreatePositionTitle: React.FC<FormModalProps> = ({
  title,
  show,
  handleClose,
  setShowSuccessModal,
  callback,
  isCreateModal,
  data
}) => {
  const [roleData, setRoleData] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(CreatePositionSchema),
  });

  useEffect(() => {
    if(!isCreateModal){
      setValue('position_name', data?.position_title)
      setValue('role_id', roleData.find((el:any) => el?.id === data?.role_id) ?? {})
    }
  }, [reset, setValue, roleData])

  const { isPending: getRolePending, mutate: GetRoleMutate } = useMutation({
    mutationFn: PositionRoleListAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      setRoleData(data?.data?.result);
    },
  });

  useEffect(() => {
    GetRoleMutate();
  }, [])

  const { isPending, mutate: CreatePositionMutate } = useMutation({
    mutationFn: CreatePositionAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "create");
      handleClose();
    },
  });

  const { isPending:isPendingEdit, mutate: EditPositionMutate } = useMutation({
    mutationFn: EditPositionAPI,
    onError: (error: any) => {
      if (typeof error?.data?.message === "string") {
        toast.error(error?.data?.message);
      } else {
        error?.data?.message?.forEach((item: any) => {
          Object.keys(item).forEach((fieldName: any) => {
            setError(fieldName, {
              type: "custom",
              message: item[fieldName][0],
            });
          });
        });
      }
    },
    onSuccess: (data) => {
      callback && callback();
      setShowSuccessModal && setShowSuccessModal(true, "edit");
      handleClose();
    },
  });

  const onSubmit = (submitFormData: any) => {
    const formData = new FormData();
    formData.append("position_title", submitFormData?.position_name);
    formData.append("role_id", submitFormData?.role_id?.id);
    if(!isCreateModal && data?.id){
      formData.append("id", data?.id);
    }
    
    isCreateModal ? CreatePositionMutate(formData) :
    EditPositionMutate(formData)
  };

  return (
    <>
      <CommonModal
        title={title}
        show={show}
        handleClose={handleClose}
        dynamicCLass={""}
        modalSize={"modal-md"}
      >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col md={12}>
              <Textbox
                control={control}
                type="text"
                id="position_name"
                name="position_name"
                placeholder={"Position Name"}
                autoComplete="off"
                max={100}
                errorMessage={errors.position_name && errors.position_name?.message}
              />
            </Col>
            <Col md={12}>
            <Controller
                  name="role_id"
                  control={control}
                  render={({ field }) => (
                    <div className="form-group">
                      <ReactSelect
                        isLoading={getRolePending}
                        {...field}
                        getOptionLabel={(item: any) => item.name}
                        getOptionValue={(item: any) => item.id}
                        options={roleData ?? []}
                        isSearchable
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        placeholder={"Role"}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        menuPlacement="top"
                      />
                      {errors.role_id && (
                        <div className="invalid-feedback d-block">
                          {errors.role_id.message}
                        </div>
                      )}
                    </div>
                  )}
                />
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending || isPendingEdit}>
                {(isPending || isPendingEdit) && <SubmitLoader />}
                {isCreateModal ? 'Create' : 'Save'}
              </Button>
            </div>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};

export default CreatePositionTitle;
