import { Suspense, useEffect, useState } from "react";
import { Image, Navbar } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom";
import { NavItems } from "utils/constants/constant";
import "layout/authLayout.scss";
import appImages from "utils/appImages";
import { GlobalState } from "context/globalContext";
import { SpinerLoader } from "common/loader/loader";
import { getProfileData, getUserRole, handleLogout } from "utils/helpers/localStorage";

const AuthLayout = () => {
  const [open, setIsOpen] = useState(false);
  const [profileData, setProfileData] = useState<any>({});
  const location = useLocation();
  const [activeSubmenu, setActiveSubmenu] = useState(0);
  const { pathname } = location;
  const { breadCrumb, profileData: globalProfile } = GlobalState();
  const curUserRole = getUserRole();
  console.log('curUserRole--', curUserRole)

  useEffect(() => {
    const userData = getProfileData();
    setProfileData(userData)
  }, [globalProfile])

  const validNavItems = NavItems.filter((val) => val?.role.includes(getUserRole()));

  const handleClick = (index: any) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  return (
    <>
      <div id="wrapper" className={`${open ? "toggled" : ""}`}>
        <header>
          <aside id="sidebar-wrapper">
            <>
              <div className="sidebar-brand d-lg-block d-none bg-light-purple">
                <Image
                  src={appImages.Logo}
                  width={236}
                  height={34}
                  alt="EnviroClean"
                />
              </div>

              <div className="sidebar-brand d-lg-none d-flex align-items-center justify-content-between bg-light-purple">
                <Image src={appImages.Logo} alt="EnviroClean" />
                <a href="#" id="sidebar-toggle-sm">
                  <Image
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    src={appImages.CloseImage}
                    alt={""}
                  />
                </a>
              </div>
            </>
            {/* <ul className="sidebar-nav vertical-scrollbar bg-dark">
              {NavItems.map((item, i) => {
                const isActive = pathname.split("-").includes(item.path);
                return (
                  <li key={i} className={`${isActive ? "active" : ""}`} onClick={() => {
                    setIsOpen(false);
                  }}>
                    <Link
                      to={item.path}
                      className="d-flex align-items-center"
                    >
                      <Image src={item.icon} alt="Icon"></Image>
                      {<span>{item.name}</span>}
                    </Link>
                  </li>
                );
              })}
              <li key={"logout"}>
                <Link to={"/signin"} className="d-flex align-items-center">
                  <Image src={appImages.LogoutIcon} alt="Icon"></Image>
                  {<span>Logout</span>}
                </Link>
              </li>
            </ul> */}
            <ul className="sidebar-nav vertical-scrollbar bg-dark">
              {validNavItems.map((item, i) => {
                const isActive = pathname.split("-").map((el) => el?.replace('/', '')).includes(item.path?.replace('/', ''));
                return (
                  <li
                    key={i}
                    className={`${isActive ? "active" : ""} ${item?.submenu ? "submenu-dropdown" : ""}`}
                    onClick={() => {
                      if (!item.submenu) setIsOpen(false);
                    }}
                  >
                    <Link
                      to={item.path}
                      className="d-flex align-items-center"
                      onClick={() => handleClick(item.id)}
                    >
                      <Image src={item.icon} alt="Icon"></Image>
                      {<span>{item.name}</span>}
                    </Link>
                    {activeSubmenu === item.id && item?.submenu && (
                      <ul className="submenu">
                        {item.submenu.map((subitem, j) => {
                          const isActiveSubmenu = pathname === subitem.path
                          return (
                            <li
                              className={`${isActiveSubmenu ? "active" : ""}`}
                              onClick={() => setIsOpen(false)}
                              key={j}
                            >
                              <Link to={subitem.path} className="d-flex align-items-center">
                                <Image src={item.icon} alt="Icon"></Image>
                                <span>{subitem.name}</span>
                              </Link>
                            </li>
                          )
                        }
                        )}
                      </ul>
                    )}
                  </li>
                );
              })}
              <li key={"logout"}>
                <Link to={"/signin"} onClick={handleLogout} className="d-flex align-items-center">
                  <Image src={appImages.LogoutIcon} alt="Icon"></Image>
                  {<span>Logout</span>}
                </Link>
              </li>
            </ul>
          </aside>
          <Navbar
            className="d-flex align-items-center justify-content-between bg-extra-light-grey"
            id="navbar-wrapper"
          >
            <div className="d-flex align-items-center navbar-left">
              <a href="#" className="me-4 d-lg-none d-block" id="sidebar-toggle">
                <Image
                  onClick={() => {
                    setIsOpen(!open);
                    if (!open) {
                      document
                        .querySelector(".dashboard-main-layout")
                        ?.classList.add("toggled");
                    } else {
                      document
                        .querySelector(".dashboard-main-layout")
                        ?.classList.remove("toggled");
                    }
                  }}
                  src={appImages.ToggleIcon}
                  alt={""}
                />
              </a>
              <ol className="breadcrumb mb-0 d-sm-flex d-none">
                {breadCrumb?.map((val, index) => (
                  <li
                    key={`breadcrumb-${index}`}
                    className={`breadcrumb-item ${index === breadCrumb.length - 1 ? "active" : ""
                      }`}
                  >
                    {val.slug === "#" ? <span>{val.title}</span> :
                      <Link to={val.slug}>{val.title}</Link>
                    }
                  </li>
                ))}
              </ol>
            </div>
            <div className="user-profile d-flex align-items-center">
              <div
                className="profile-img bg-white"
                style={{
                  // backgroundImage: `url(${userData?.profile_image_url})`,
                  backgroundImage: profileData?.profile_image_url ? `url(${profileData?.profile_image_url}` : `url(${appImages.UserIcon}`,
                }}
              ></div>
              <span>
                {/* {userData?.role_id === AdminRole
                        ? t("common:admin")
                        : userData?.name ?? ""} */}
                {profileData?.full_name}
              </span>
            </div>
          </Navbar>
        </header>
        <div className="wrapper-body">
          <Suspense fallback={<SpinerLoader />} >
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
