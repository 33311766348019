import { useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { PerPageOptions } from "utils/constants/constant";
import { Button, Image, Nav } from "react-bootstrap";
import appImages from "utils/appImages";
import ActionDropdown from "common/actionDropdown/actionDropdown";
import ReactDataTable from "common/reactDataTable/reactDataTable";
import Select, { components } from "react-select";
import { useMutation } from "@tanstack/react-query";
import SuccessModal from "common/modal/successModal/successModal";
import { toast } from "react-toastify";
import ConfirmModal from "common/confirmModal/confirmModal";
import { GlobalState } from "context/globalContext";
import moment from "moment";
import { DeleteDocumentAPI, GetDocumentListAPI } from "services/document";
import { NavLink } from "react-router-dom";
import CreateDocument from "components/documentUpload/createDocument";
import ImagePreviewModal from "./imagePreviewModal";

const DocumentList = () => {
  const { setBreadCrumb } = GlobalState();
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isEditDocument, setIsEditDocument] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dropdownOpen, isDropdownOpen] = useState<boolean>(false);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [documentData, setDocumentData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<number>();
  const [showConfirmModal, isShowConfirmModal] = useState<string>("");
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<any>({});
  const [imgPreviewData, setImgPreviewData] = useState<any>({});

  const breadcrumbList = [
    {
      title: "Document",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "delete":
        data = {
          title: "Document Deleted successfully",
          description: "Document has been successfully deleted",
        };
        return data;
      case "create":
        data = {
          title: "Document Create successfully",
          description: "Document has been successfully created",
        };
        return data;
      case "update":
        data = {
          title: "Document Update successfully",
          description: "Document has been successfully updated",
        };
        return data;
      default:
        return null;
    }
  };

  const getDocumentListData = (
    page?: number | null,
    column?: string,
    sortOrder?: string
  ) => {
    const data = {
      per_page: rowsPerPage,
      page: page ? page : currentPage,
      order_by_column: sortColumn?.column_name ?? column,
      order_by: sortColumn?.sort_dir ?? sortOrder,
    };
    GetDocumentListMutate(data);
  };

  useEffect(() => {
    getDocumentListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    // currentPage,
    sortColumn?.column_name,
    sortColumn?.sort_dir,
  ]);

  const { isPending, mutate: GetDocumentListMutate } = useMutation({
    mutationFn: GetDocumentListAPI,
    onError: (error: any) => {
      toast.error(error?.data?.message);
      throw error;
    },
    onSuccess: (data: any) => {
      setDocumentData(data.data.result);
      return data;
    },
  });

  const handleSort = (selectedColumn: any, sortDirection: SortOrder) => {
    const directionOfSort = sortDirection === "desc" ? "asc" : "desc";
    setSortColumn({
      column_name: selectedColumn?.sortField,
      sort_dir: selectedColumn?.sortField ? directionOfSort : undefined,
    });
  };

  const { isPending: DeletePending, mutate: DeleteMutate } = useMutation({
    mutationFn: DeleteDocumentAPI,
    onError: (error: any) => {
      toast.error(error.data.message);
    },
    onSuccess: (data: any) => {
      getDocumentListData();
      isShowConfirmModal("");
      setIsShowSuccessModal(true);
    },
  });

  const handleCloseConfirmModal = () => {
    isShowConfirmModal("");
  };

  const handleDelete = () => {
    setCurConfirmModal("delete");
    isShowConfirmModal("delete");
  };

  const handleActionClick = (action: string, status?: any, id?: number) => {
    if (id) {
      setCurrentRow(id);
    }
    switch (action) {
      case "delete":
        return handleDelete();
      case "edit":
        return setIsEditDocument(true);
    }
  };

  const handleShowImg = (imgs: any[]) => {
    setImgPreviewData({img_url: imgs?.[0]?.document_image_url});
    setShowImgPreview(true);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row: any) => row?.id,
      sortField: "id",
      sortable: true,
    },
    {
      id: 2,
      name: "Date",
      selector: (row: any) => moment(row?.date).format("DD MMM YYYY"),
      sortField: "date",
      sortable: true,
    },
    {
      id: 3,
      name: "Document Name",
      selector: (row: any) =>
        row?.document_title && row?.document_title?.length > 25
          ? row?.document_title.slice(0, 25) + "..."
          : row?.document_title,
      sortField: "document_title",
      sortable: true,
    },
    {
      id: 4,
      name: "Document Preview",
      cell: (row: any) => (
        <Image
          className="valet-location cursor-pointer"
          src={appImages.EyeIcon}
          onClick={() => handleShowImg(row?.document_images)}
        />
      ),
    },
    {
      id: 5,
      name: "Action",
      cell: (row: any) => (
        <>
          <ActionDropdown
            status={row?.is_active === "1" ? true : false}
            actionClick={(action: string, status?: any) =>
              handleActionClick(action, status, row?.id)
            }
            isVisibles={["edit", "delete"]}
            onToggleChange={(e: boolean) => isDropdownOpen(e)}
          />
        </>
      ),
      width: "150px",
    },
    {
      id: 6,
      name: "More",
      cell: (row: any) => (
        <div className="action-btn">
          <Nav.Link as={NavLink} to={`/document-detail/${row?.id}`}>
            <Image
              src={appImages.RightDetailIcon}
              alt="edit"
              width={14}
              height={14}
            />
          </Nav.Link>
        </div>
      ),
      width: "80px",
    },
  ];

  const handleCloseCreateModal = () => {
    setIsCreateDocument(false);
    setIsEditDocument(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  const handleChangePerPage = (id: number) => {
    id && setRowsPerPage(id);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Document List</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Select
            isDisabled={isPending}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            options={PerPageOptions}
            defaultValue={PerPageOptions[1]}
            components={{
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {"Records per page: " + children}
                  </components.SingleValue>
                );
              },
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Records per page: " + children}
                  </components.Placeholder>
                );
              },
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              selectedOption && handleChangePerPage(selectedOption.id);
            }}
            placeholder={""}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPlacement="bottom"
          />
          <Button variant="primary" onClick={() => setIsCreateDocument(true)}>
            Create Document
          </Button>
        </div>
      </div>
      <ReactDataTable
        dynamicClass={dropdownOpen ? "dropdown-open" : ""}
        columns={columns}
        tableData={documentData?.data}
        rowsPerPage={rowsPerPage}
        totalRecord={documentData?.total}
        showPage={currentPage - 1}
        onPageChange={(page) => {
          setCurrentPage(page + 1);
          getDocumentListData(page + 1);
        }}
        inProgress={isPending}
        handleSort={handleSort}
      />
      {(isCreateDocument || isEditDocument) && (
        <CreateDocument
          title={isCreateDocument ? "Create Document" : "Edit Document"}
          setShowSuccessModal={handleShowSuccessModal}
          isCreateModal={isCreateDocument}
          callback={getDocumentListData}
          show={isCreateDocument || isEditDocument}
          handleClose={handleCloseCreateModal}
          currentRow={currentRow}
        />
      )}
      {showImgPreview && (
        <ImagePreviewModal
          data={imgPreviewData}
          show={showImgPreview}
          handleClose={() => setShowImgPreview(false)}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
      {showConfirmModal === "delete" && (
        <ConfirmModal
          show={showConfirmModal === "delete"}
          isLoading={DeletePending}
          onConfirm={() => DeleteMutate({ id: currentRow })}
          handleClose={handleCloseConfirmModal}
          type="delete"
          message={
            "Do you really want to delete this record? This process can not be undone."
          }
        />
      )}
    </>
  );
};

export default DocumentList;
