import { request } from "services/index";
import { AxiosResponse } from "axios";

export const CreateClientAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-client",
    method: "POST",
    body,
  });
  return response;
};

export const GetClientAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/client-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetClientFormDataAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: `/get-client-form-data`,
    method: "GET",
  });
  return response;
};

export const GetClientDetailAPI = async (id: string) => {
  const response: AxiosResponse<any> = await request({
    url: `/client-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const UpdateClientAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: `/update-client`,
    method: "POST",
    body,
  });
  return response;
};

export const ArchiveClientAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/client-archive",
    method: "POST",
    body,
  });
  return response;
};

export const ClientWorkDetailListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/client-detail-work-report",
    method: "POST",
    body,
  });
  return response;
};

export const ClientObservationReportAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/client-detail-observation-report",
    method: "POST",
    body,
  });
  return response;
};