import { useQuery } from "@tanstack/react-query";
import Textbox from "common/form/textBox";
import { ScalingLoader } from "common/loader/loader";
import copy from "copy-to-clipboard";
import { Button, Card, CardHeader, Form, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import appImages from "utils/appImages";
import { useEffect, useState } from "react";
import EditSysAdminProfile from "./editSysAdminProfile";
import SuccessModal from "common/modal/successModal/successModal";
import { GetProfileSysAdminAPI } from "services/profile";
import UpdatePasswordModal from "../updatePassword";
import { GlobalState } from "context/globalContext";
import { AllowRoles } from "utils/helpers/manageRole";
import { EMP_RM_ROLE } from "utils/constants/constant";
import moment from "moment";

const SysAdminProfileDetail = () => {
  const { setBreadCrumb } = GlobalState();
  const [isEditProfile, setEditProfile] = useState(false);
  const [showSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [isChangePass, setChangePassModal] = useState<boolean>(false);
  const [curConfirmModal, setCurConfirmModal] = useState<string>("");
  const [profileData, setProfileData] = useState<any>(null);
  const [copied, setCopied] = useState(false);

  const breadcrumbList = [
    {
      title: "My Profile",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, setValue, watch } = useForm({});

  const { fetchStatus, refetch: refetchProfile } = useQuery({
    queryKey: ["profileData"],
    queryFn: async () => {
      try {
        const data = await GetProfileSysAdminAPI();
        const result = data && data?.data?.result;
        setProfileData(result);
        if (result) {
          setValue("profile_image", result?.profile_image_url);
          setValue("id", result?.id);
          setValue("name", result?.full_name);
          setValue("title", result?.title);
          setValue("email", result?.email);
          setValue("phone", result?.phone);
          setValue("address", result?.address);
          setValue("zipcode", result?.zipcode);
          setValue("state", result?.state_info?.state_name);
          setValue("city", result?.city_info?.city_name);
          setValue("time_zone", result?.time_zone_info?.label);
          setValue("uniform_size", result?.employee_info?.uniform_size_info?.uniform_size_title);
          setValue("birth_date", moment.utc(result?.employee_info?.birthdate).format("DD MMM YYYY"));
          setValue("date_of_hire", moment.utc(result?.employee_info?.join_date).format("DD MMM YYYY"));
          return data;
        }
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const ConfirmTitle = (key: string) => {
    let data: any = {};
    switch (key) {
      case "update":
        data = {
          title: "Profile Update successfully",
          description: "Profile has been successfully updated",
        };
        return data;
      case "updatePassword":
        data = {
          title: "Password Update successfully",
          description: "Password has been successfully updated",
        };
        return data;
    }
  };

  const handleCloseCreateModal = () => {
    setEditProfile(false);
  };

  const handleShowSuccessModal = (status: boolean, slug: string) => {
    setIsShowSuccessModal(status);
    setCurConfirmModal(slug);
  };

  return (
    <>
      <Card className="mb-30">
        <CardHeader className="border-0 bg-purple text-white fw-600">
          My Profile Details
        </CardHeader>
        <Card.Body>
          {fetchStatus === "fetching" ? (
            <div className="d-flex justify-content-center">
              <ScalingLoader />
            </div>
          ) : (
            <div className="detail-form d-sm-flex d-block">
              <div className="detail-form-user mb-sm-0 mb-4">
                <Image src={watch("profile_image")} alt="" />
              </div>
              <Form>
              <div className="position-relative form-group-icon">
                  <Textbox
                    control={control}
                    type="text"
                    id="id"
                    name="id"
                    value=""
                    label={"ID"}
                    placeholder={"ID"}
                    autoComplete="off"
                    disabled
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{copied ? "Copied!" : "Copy"}</Tooltip>}
                  >
                    <Image
                      src={appImages.CopyIcon}
                      alt="copy"
                      className="cursor-pointer"
                      onClick={() => {
                        copy(watch("id"));
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000); 
                      }}
                    />
                  </OverlayTrigger>
                </div>
                <Textbox
                  control={control}
                  type="text"
                  id="name"
                  name="name"
                  value=""
                  label={"Name"}
                  placeholder={"Name"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="time_zone"
                  name="time_zone"
                  value=""
                  label="Time Zone"
                  placeholder="Time Zone"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="title"
                  name="title"
                  value=""
                  label={"Title"}
                  placeholder={"Title"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="email"
                  name="email"
                  value=""
                  label={"Email"}
                  placeholder={"Email"}
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="phone"
                  name="phone"
                  value=""
                  label={"Mobile Number"}
                  placeholder="Mobile Number"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="address"
                  name="address"
                  value=""
                  label="Address"
                  placeholder="Address"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="number"
                  id="zipcode"
                  name="zipcode"
                  value=""
                  label="Zipcode"
                  placeholder="Zipcode"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="state"
                  name="state"
                  value=""
                  label="State"
                  placeholder="State"
                  autoComplete="off"
                  disabled
                />
                <Textbox
                  control={control}
                  type="text"
                  id="city"
                  name="city"
                  value=""
                  label="City"
                  placeholder="City"
                  autoComplete="off"
                  disabled
                />
                {AllowRoles([EMP_RM_ROLE]) && 
                  <Textbox
                  control={control}
                  type="text"
                  id="uniform_size"
                  name="uniform_size"
                  value=""
                  label="Uniform Size"
                  placeholder="Uniform Size"
                  autoComplete="off"
                  disabled
                />
                }
                {AllowRoles([EMP_RM_ROLE]) && 
                  <Textbox
                  control={control}
                  type="text"
                  id="birth_date"
                  name="birth_date"
                  value=""
                  label="Birth Date"
                  placeholder="Birth Date"
                  autoComplete="off"
                  disabled
                />
                }
                {AllowRoles([EMP_RM_ROLE]) && 
                  <Textbox
                  control={control}
                  type="text"
                  id="date_of_hire"
                  name="date_of_hire"
                  value=""
                  label="Date of Hire"
                  placeholder="Date of Hire"
                  autoComplete="off"
                  disabled
                />
                }
              </Form>
            </div>
          )}
        </Card.Body>
      </Card>
      <div className="d-flex align-items-center justify-content-center gap-3 mt-md-0 mt-2 main-title-options">
        <Button
          variant="primary"
          disabled={fetchStatus === "fetching"}
          onClick={() => setEditProfile(true)}
        >
          Edit
        </Button>
        <Button variant="primary" onClick={() => setChangePassModal(true)}>
          Change Password
        </Button>
      </div>
      {isEditProfile && (
        <EditSysAdminProfile
          title={"Edit Profile"}
          setShowSuccessModal={handleShowSuccessModal}
          callback={refetchProfile}
          show={isEditProfile}
          handleClose={handleCloseCreateModal}
          data={profileData}
        />
      )}
      {isChangePass && (
        <UpdatePasswordModal
          title={"Change Password"}
          setShowSuccessModal={handleShowSuccessModal}
          show={isChangePass}
          handleClose={() => setChangePassModal(false)}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          handleClose={() => setIsShowSuccessModal(false)}
          title={ConfirmTitle(curConfirmModal)?.title}
          description={ConfirmTitle(curConfirmModal)?.description}
        />
      )}
    </>
  );
};

export default SysAdminProfileDetail;
