import { request } from "services/index";
import { AxiosResponse } from "axios";

export const GetJobsiteEmpClientListAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/jobsite-employee-client-list",
    method: "POST",
    body,
  });
  return response;
};

export const GetAnnounceGroupListAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/group-list",
    method: "GET",
  });
  return response;
};

export const GetEmpClientListAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/announcement-emp-client-list",
    method: "GET",
  });
  return response;
};

export const CreateAnnounceGroupAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/create-group",
    method: "POST",
    body
  });
  return response;
};

export const AddGroupMemberAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/add-group-member",
    method: "POST",
    body
  });
  return response;
};

export const GroupListAPI = async (search:string) => {
  const response: AxiosResponse<any> = await request({
    url: `/announcement-group-list?search=${search}`,
    method: "GET",
  });
  return response;
};

export const GroupNameUpdateAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: `/update-group-name`,
    method: "POST",
    body
  });
  return response;
};

export const AnnouncementDetailListAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/announcement-list-detail",
    method: "POST",
    body
  });
  return response;
};

export const AnnouncementDetailAPI = async (id:string) => {
  const response: AxiosResponse<any> = await request({
    url: `announcement-detail/${id}`,
    method: "GET",
  });
  return response;
};

export const AnnouncementDeleteAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: `/delete-announcement`,
    method: "POST",
    body
  });
  return response;
};

export const AnnouncementCreateAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: `/add-announcement`,
    method: "POST",
    body
  });
  return response;
};

export const GetGroupMemberListAPI = async (body:any) => {
  const response: AxiosResponse<any> = await request({
    url: "/group-member-list",
    method: "POST",
    body
  });
  return response;
};