import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Image } from "react-bootstrap";
import {
  AnnouncementDeleteAPI,
  AnnouncementDetailAPI,
} from "services/announcement";
import appImages from "utils/appImages";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ScalingLoader, SubmitLoader } from "common/loader/loader";
import { GlobalState } from "context/globalContext";
import ConfirmModal from "common/confirmModal/confirmModal";

const AnnouncementDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const { setBreadCrumb } = GlobalState();
  const breadcrumbList = [
    {
      title: "Announcement",
      slug: "/announcement",
    },
    {
      title: "Announcement Detail",
      slug: "#",
    },
  ];

  useEffect(() => {
    setBreadCrumb(breadcrumbList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fetchStatus } = useQuery({
    queryKey: ["announcementDetail"],
    enabled: !!id,
    queryFn: async () => {
      try {
        const data: any = id && (await AnnouncementDetailAPI(id?.toString()));
        setData(data?.data?.result);
        return data;
      } catch (error: any) {
        toast.error(error?.data?.message);
        throw error;
      }
    },
  });

  const { isPending: isDeletePending, mutate: AnnouncementDelete } =
    useMutation({
      mutationFn: AnnouncementDeleteAPI,
      onError: (error: any) => {
        toast.error(error.data.message);
      },
      onSuccess: (data: any) => {
        navigate("/announcement");
      },
    });

  return (
    <>
      <div className="main-title d-lg-flex d-block align-items-center justify-content-between">
        <h2>Announcement</h2>
        <div className="d-flex align-items-center gap-3 mt-md-0 mt-2 main-title-options">
          <Button
            onClick={() => setShowDelete(true)}
            variant="danger"
          >Delete Annoucement</Button>
        </div>
      </div>
      {fetchStatus === "fetching" ? (
        <span className="d-flex justify-content-center">
          <ScalingLoader />
        </span>
      ) : (
        <div className="announcement-listing-wrapper ">
          <div className="media d-flex">
            <div className="media-left">
              <Image
                src={appImages.AnnouncementnewtImage}
                alt=""
                width={30}
                height={30}
              />
            </div>
            <div className="media-right">
              <div className="d-flex align-items-center justify-content-between media-header">
                <h2 className="mb-0">{data?.announcement_title}</h2>
              </div>
              <p className="mb-0">{data?.announcement_description}</p>
              {data?.image_url && (
                <Image style={{width: '300px', objectFit: 'contain'}} className="detail-image" src={data?.image_url} alt="" />
              )}
            </div>
          </div>
        </div>
      )}
      {showDelete && (
        <ConfirmModal
          show={showDelete}
          isLoading={isDeletePending}
          onConfirm={() => AnnouncementDelete({ announcement_id: id })}
          handleClose={() => setShowDelete(false)}
          type="delete"
          message={
            "Do you really want to delete this announcement? This process can not be undone."
          }
        />
      )}
    </>
  );
};

export default AnnouncementDetail;
